export interface ICase
{
  id: number;

  clinicId: number;
  customerId: number;
  customerAddress: string;

  patientId: number;
  patientName: string;
  patientLastName: string;
  patientFullName: string;
  patientEmail: string;
  patientPhone: string;

  caseProductId: number;
  productId?: number;
  orderDetailId?: number;

  statusId: number;

  expirationDate?: Date;
  isPartialSave: boolean;
  purchasedAligners: number;
  totalAligners: number;
  usedAligners: number;
  remainingAligners: number;
  upperAligners?: number;
  lowerAligners?: number;
  hasDentalMonitoring: boolean;
  isComplexCase: boolean;
  isDeleted: boolean;
  setupCount: number;
  stageCount: number;
}

export class Case
{
  public id: number;

  public clinicId: number;
  public customerId: number;
  public customerAddress: string;

  public patientId: number;
  public patientName: string;
  public patientLastName: string;
  public patientFullName: string;
  public patientEmail: string;
  public patientPhone: string;

  public caseProductId: number;
  public productId?: number;
  public orderDetailId?: number;
  public productAlignersQuantity?: number; 

  public statusId: number;

  public expirationDate?: Date;
  public isPartialSave: boolean;
  public purchasedAligners: number;
  public totalAligners: number;
  public usedUpperAligners:number;
  public usedLowerAligners:number;
  public usedAligners: number;
  public remainingAligners: number;
  public upperAligners?: number;
  public lowerAligners?: number;
  public hasDentalMonitoring: boolean;
  public isComplexCase: boolean;
  public isDeleted: boolean;
  public setupCount: number;
  public stageCount: number;

  constructor() { }
}
