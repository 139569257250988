import { Component, Input, OnInit } from '@angular/core';
import { slideDown } from 'src/app/_appCommon/animations/animations';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.css'],
  animations: [slideDown]
})
export class PanelComponent implements OnInit
{
  @Input() item;
  public isOpen = false;

  ngOnInit(): void { }

}
