<header>
  <div class="header-container container d-flex align-items-center h-100">
		<div class="header-content w-100">
			<h2>Preguntas <span>frecuentes</span></h2>
		</div>
	</div>
</header>
<main class="py-4 position-relative overflow-hidden">
  <app-faq-background></app-faq-background>
  <div class="controller-faq p-2 position-relative">
    <button (click)="isSpecialist = false" [ngClass]="{'isActive': !isSpecialist}">Soy Paciente</button>
    <button (click)="isSpecialist = true" [ngClass]="{'isActive': isSpecialist }">Soy Ortodoncista</button>
  </div>
  <section class="faq-container container-lg">
    <app-accordion *ngIf="!isSpecialist" [faqs]="FaqPatient"></app-accordion>
    <app-accordion *ngIf="isSpecialist" [faqs]="FaqSpecialist"></app-accordion>
  </section>
</main>
