import { Directive, ElementRef, HostListener, OnInit, EventEmitter, HostBinding, Output } from '@angular/core';

@Directive({
  selector: '[appDragAndDrop]'
})
export class DragAndDropFilesDirective implements OnInit
{
  @HostBinding('class.fileover') fileOver: boolean;
  @Output() filesDropped = new EventEmitter<any>();

  @HostListener('dragover', ['$event'])
  public onHandleDragOver(evt): void
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = true;
    const p = this.elementRef.nativeElement.querySelector('p');
    p.innerText="Suelta para subir los archivos";
  }

  @HostListener('dragleave', ['$event'])
  public onHandleDragLeave(evt): void
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const p = this.elementRef.nativeElement.querySelector('p');
    p.innerText="Arrastra y suelta las imagenes";
  }

  @HostListener('drop', ['$event'])
  public onHandleDrop(evt): void
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.fileOver = false;
    const p = this.elementRef.nativeElement.querySelector('p');
    p.innerText="Arrastra y suelta las imagenes";

    const files = evt.dataTransfer.files;
    if (files.length > 0)
      this.filesDropped.emit(files);
  }



  ngOnInit(): void { }

  constructor(private elementRef: ElementRef) { }
}
