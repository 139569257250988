import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-slider-compare',
  templateUrl: './slider-compare.component.html',
  styleUrls: ['./slider-compare.component.css']
})
export class SliderCompareComponent implements OnInit
{
  @ViewChild('sliderWrapper') sliderWrapper: ElementRef;

  @Input() imgBefore;
  @Input() imgAfter;

  public positionDivider = 0.5;
  public imageRevealFrag = this.positionDivider;
  public isMouseDown = false;

  ngOnInit(): void { }

  public onHandleSlide(position: number): void
  {
    const element = this.sliderWrapper.nativeElement.getBoundingClientRect();
    if(this.sliderWrapper)
    {
      if(position > element.right)
        this.positionDivider = 0;
      else if(position < element.left)
        this.positionDivider = 1;
      else
      {
        this.positionDivider = (element.right - position) / element.width;
      }
    }
  }

  public getClipPath(): string
  {
    return `inset(0 ${this.positionDivider*100}% 0 0)`;
  }

  public getPosition(): string
  {
    return `${this.positionDivider*100}%`;
  }

  public onHandleMouseMove(event): void
  {
    if(this.isMouseDown)
      this.onHandleSlide(event.clientX);
  }

  public onHandleTouchMove(event): void
  {
    this.onHandleSlide(event.touches[0].clientX);
  }

  public onHandleMouseDown()
  {
    this.isMouseDown = true;
    window.onmouseup = this.onHandleMouseUp;
  }

  public onHandleMouseUp()
  {
    this.isMouseDown = false;
    window.onmousemove = undefined;
    window.onmouseup = undefined;
  }

}
