import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { PATIENTTESTIMONIALSTEXT } from '../../base/Constants';

// import Swiper core and required modules
import SwiperCore, { Autoplay, Navigation } from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';

// install Swiper modules
SwiperCore.use([Autoplay, Navigation]);

@Component({
  selector: 'app-before-after-slider',
  templateUrl: './before-after-slider.component.html',
  styleUrls: ['./before-after-slider.component.css'],
  encapsulation: ViewEncapsulation.None,
})

export class BeforeAfterSliderComponent implements OnInit
{
  public PatientTestimonialsText = PATIENTTESTIMONIALSTEXT;

  ngOnInit() {}
}
