export const SITEMAPURLS = [
  {name: 'Inicio', url: ''},
  {name: 'Encuentra un Ortodoncista', url: 'encuentra-ortodoncista'},
  {name: 'Soy Ortodoncista', url: 'soy-ortodoncista'},
  {name: 'FAQ', url: 'faq'}
];

export const CONTACTDATA = [
  {content: "Av. Vasconcelos 124 Col. Tampiquito, San Pedro Garza Garcia, Mexico.", iconClass: "fa fa-map-marker"},
  {content: "(81) 8683 - 4840", iconClass: "fa fa-phone"},
  {content: "(81) 8683 - 4840", iconClass: "fa fa-whatsapp"},
  {content: "hola@aliwell.mx", iconClass: "fa fa-envelope"},
];
