import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '../services/translate.service';

@Pipe({
  name: 'translate',
  pure: false,
})

export class TranslatePipe implements PipeTransform
{
  constructor(private translate: TranslateService) { }

  transform(_key: string = ''): string
  {
    return this.translate.getElement(_key);
  }
}
