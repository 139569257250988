import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-integer-spinner',
  templateUrl: './integer-spinner.component.html',
  styleUrls: ['./integer-spinner.component.css']
})
export class IntegerSpinnerComponent implements OnInit
{
  public counter: number = 1;
  public minValue: number = 1;
  public maxValue: number = 9999;
  @Output() public valueChanged = new EventEmitter<number>();

  constructor()
  {

  }

  ngOnInit(): void
  {

  }

  public plusClicked()
  {
    if (this.counter === this.maxValue)
    {

    }
    else
    {
      this.counter += 1;
      this.valueChanged.emit(this.counter);
    }
  }

  public minusClicked()
  {
    if (this.counter === this.minValue)
    {

    }
    else
    {
      this.counter -= 1;
      this.valueChanged.emit(this.counter);
    }
  }
}
