
export interface ISetup
{
  id: number;
  prescriptionId: number;
  caseFileId: number;
  currentStep: number;
}

export class Setup
{
  public id: number;
  public prescriptionId: number;
  public caseFileId: number;
  public currentStep: number;
  constructor() { }
}
