<div class="before-after-container" style="height: 500px; max-width: 100%;">
  <swiper
      [centeredSlides]="true"
      [initialSlide]="4"
      [autoplay]="{
        delay: 50000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true
      }"
      [pagination]="{
        clickable: true
      }"
      [navigation]="true"
      [breakpoints]="{
        '320': {
          slidesPerView: 1,
          spaceBetween: 0
        },
        '450': {
          slidesPerView: 3,
          slidesPerGroup: 1,
          spaceBetween: 15
        },
        '800': {
          slidesPerView: 3,
          spaceBetween: 20
        }
      }"
      class="mySwiper"
    >
      <ng-template class="position-relative" swiperSlide let-data *ngFor="let item of PatientTestimonialsText">
        <div class="d-flex justify-content-center align-items-center swiper-content" [ngClass]="{notActive: !data.isActive}">
          <p class="position-relative">{{item.content}}</p>
        </div>
      </ng-template>
  </swiper>
</div>
