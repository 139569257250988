import { SafeUrl } from "@angular/platform-browser";
import "../util/ArrayExtensions";

// ********************************************************************************
// #region Classes
// ********************************************************************************

export interface IEntity
{
  id: number;
}

export interface FileInfo {
  name: string,
  url: SafeUrl
}


export class LocalStorage implements Storage
{
  [name: string]: any;
  readonly length: number;
  clear(): void { }
  getItem(key: string): string | null { return undefined; }
  key(index: number): string | null { return undefined; }
  removeItem(key: string): void { }
  setItem(key: string, value: string): void { }
}

export class UserDto
{

}

export class LoginDto
{
  id: string = "";
  password: string = "";
  cartId: string = "";
  language: string = "";
}

export class UpdateFileDto
{
  id: number = 0;
  fileType: FileType = 0;
  fileBlobId: number = 0;
}

export class RefreshTokenDto
{
  language: string = "";
  refreshToken: string = "";
}

export class ValidateSessionDto
{
  language: string = "";
  token: string = "";
}

export class Product
{
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  productType: ProductType;
  alignersQuantity: number;
  quantityStock?: number;
  isCase: boolean;
  hasDentalMonitoring: boolean;
  hasUnlimitedAligners: boolean;
  category: Category;
  additionalServices?: ProductAdditionalServices;
  itemPrices?: ItemPrices;
  specList: ProductSpec[];
  principalImage?: string;
  images: ProductImage[];
  priceList: ProductPriceList[];
  customerTypeList: any;
  baseTax: number;
  basePriceProduct: number;
  basePriceExtraAligner: number;
  basePriceDentalMonitoring: number;
  basePriceComplexCase: number;
  expirationTime: number;
  setupIncluded: boolean;
  sort: number;
}

export class Category
{
  id: number;
  parentId: number;
  name: string;
  description: string;
  subcategories: Category[];
}

export class ProductImage
{
  id: number;
  order: number;
  productId: number;
  url: string;
}

export class ServiceImage
{
  order?: number;
  url: string;
}

export class ProductSpec
{
  id: number;
  name: string;
  value: string;
  productId: number;
}

export class ProductPriceList
{
  id: number;
  priceProduct: number;
  priceExtraAligner: number;
  priceExtraDentalMonitoring: number;
  priceComplexCase: number;
  tax: number;
  isDeleted: boolean;
  productId: number;
  customerTypeId: number;
}

export class ItemPrices
{
  priceExtraAligner?: number;
  subtotal: number;
  price: number;
  priceExtraDentalMonitoring?: number;
  priceComplexCase?: number;
  tax?: number;
  totalTax?: number;
  shoppingDiscount?: number;
  priceWithDiscount?: number;
}


export class ProductAdditionalServices
{
  addDentalMonitoring: boolean;
  addIsComplexCase: boolean;
  hasUnlimitedAligners?: boolean;
  extraAlignersQuantity: number;
}

export class Service
{
  id: number;
  name: string;
  itemPrices: ItemPrices;
  additionalServices?: ProductAdditionalServices;
  images: ServiceImage;
  idSetup: number;
  serviceProductUpgrade: any;
  serviceType: ServiceType;
}

export class ShoppingCartItem
{
  public item: Product | Service;
  public quantity: number;
}

export class ProductDetail
{
  productId: number;
  quantity: number;
  quantityExtraAligners?: number;
  addDentalMonitoring: boolean;
  addIsComplexCase: boolean;
  caseId?: number;
  isService: boolean;
  serviceType?: number;
  newProductUpgradeId?: number;
}

export class CaseStage
{
  StageNumber: number;
  UpperAligners: number;
  LowerAligners: number;
  Comments: string;
  FrecuencyDays: number;
  StartDate?: Date;
  EndDate?: Date;
  IsManufactured: boolean;
  IsPendingToReviewAlignersQuantity?: boolean;
  totalAlignersQuantity: number;
  CaseId: number;
  SetupId: number;
  StatusId?: number;
  IsDeleted: boolean;
}

export class Status
{
  id: number;
  name: string;
  backofficeDescription: string;
  ecommerceDescription: string;
  caseDescription: string;
  maxDays: number;
  colorOnTime: string;
  colorOffTime: string;
  order: number;
  isDeleted: boolean;
  isFinal: boolean;
  isCancelable: boolean;
  requiresSetupPayment: boolean;
  requiresSufficientAlignersAvailable: boolean;
}

export class OpenPayCardObject
{
  public holderName: string;
  public holderEmail?: string;
  public cardNumber: string;
  public cvv2: number;
  public expirationMonth: number;
  public expirationYear: number;
  public deviceSessionId?: string;
}

export class DynamicPipeType
{
  //NOTE: Must have same methods name that is inside "dynamic.pipe.ts"
  public static OrderStatus = "orderStatus";
  public static DateTime = "dateTime";
  public static UTCDateTime = "UTCDateTime";
  public static Currency = "currency";
  public static ToUpperCase = "toUpper";
}

// #endregion Classes

// ********************************************************************************
// #region Interfaces
// ********************************************************************************

export interface IEntity
{
  id: number;
}

export interface IBaseRequest
{
  token: string;
}

export interface IPatient
{
  id?: number;
  fullName: string;
  lastName: string;
  birthday: Date;
  email: string;
  phone: string;
  customerId: number;
}

export interface ICaseStage
{
  id?: number;
  stageNumber: number;
  upperAligners: number;
  lowerAligners: number;
  comments: string;
  frecuencyDays: number;
  startDate?: Date;
  endDate?: Date;
  setupId: number;
  statusId: number;
  isManufactured: boolean;
  caseId: number;
  updateStageReceivedDateRequired?:boolean;
  createdFromCaseDetail?: boolean;
  preSetupUsed: boolean;
  generalComments?: string;
  importantChangesComments?: string;
}

export interface ICreateBlankCommandSetup
{
  customerId: number;
}

export interface ICreateBlankCommandCaseStage
{
  caseId: number;
  stageNumber: number;
  frecuencyDays: number;
  upperAligners: number;
  lowerAligners: number;
  setupId: number;
  statusId: number;
  comments: string;
}

//Add base interfaces



// #endregion Interfaces
//Currency Type
export enum CurrencyTypeInt
{
  MXN = 0,
  USD = 1,
  CAD = 2,
  EUR = 3,
  GBP = 4,
  JPY = 5,
}

//check ISO 4217 before add new one
export const CurrencyTypeCode = new Map<number, string>([
  [CurrencyTypeInt.MXN, 'MXN'],
  [CurrencyTypeInt.USD, 'USD$'],
  [CurrencyTypeInt.CAD, 'CAD'],
  [CurrencyTypeInt.EUR, 'EUR'],
  [CurrencyTypeInt.GBP, 'GBP'],
  [CurrencyTypeInt.JPY, 'JPY'],
]);

export enum FileApplicationType
{
  PDF = 'application/pdf',
  STL = 'application/octet-stream',
  TXT = 'application/plain',
  ZIP7 = 'application/x-7z-compressed',
  ZIP = 'application/zip',
  RAR = 'application/x-rar-compressed',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export enum ImageType
{
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  TIF = 'image/tiff',
  BMP = 'image/bmp'
}

export enum VideoType
{
  MP4 = 'video/mp4',
  QUICKTIME = 'video/quicktime',
  MOV = 'video/mov',
  MPG = 'video/mpg',
  MPEG = 'video/mpeg',
  MPEG4 = 'video/mpeg4',
}


export enum FileExtension//Agregar nuevos formatos de archivo
{
  PDF = '.pdf',
  TXT = '.txt',
  ZIP = '.zip',
  ZIP7 = '.7z',
  RAR = '.rar',
  WORD = '.docx',//
  POWERPOINT = '.pptx',//
  EXCEL = '.xlsx',//
}

export enum FileWeirExtension
{
  STL = '.stl',
}

export enum ImageExtension
{
  JPG = '.jpg',
  JPEG = '.jpg',
  PNG = '.png',
  GIF = '.gif',
  TIF = '.tif',
  BMP = '.bmp'
}

export const ValidImageExtensions: string[] =
[
  ImageExtension.JPG.toString(),
  ImageExtension.JPEG.toString(),
  ImageExtension.PNG.toString(),
  ImageExtension.GIF.toString(),
  ImageExtension.TIF.toString(),
  ImageExtension.BMP.toString()
]

export enum ProductType
{
  Plan = 1,
  Setup = 2,
  Service = 3,
  Accessory = 4,
}

export enum OrderPaymentStatus {
  InProcess = 0,
  Completed = 1,
}

export enum PaymentTypeStatus {
  OpenPay = 1,
  PayNet = 2,
  SPEI = 3,
  Paypal = 4,
  InPerson = 5,
  PayLink = 6,
  Courtesy = 7,
  CardPay=8
}

export enum FileType
{
  LateralExtraoralId = 0,
  FrontalExtraoralId = 1,
  SmileExtraoralId = 2,
  UpperOcclusalId = 3,
  LowerOcclusalId = 4,
  LeftLateralIntraoralId = 5,
  FrontalIntraoralId = 6,
  RightLateralIntraoralId = 7,
  CompositeId = 8,
  PanoramicRadiographyId = 9,
  LateralRadiographyId = 10,
  AditionalRadiographyId = 11,
  BiteSTLId = 12,
  UpperSTLId = 13,
  LowerSTLId = 14,
  AdditionalCaseFileId = 15,
  TaxInformation = 16,
  RightsAgreement = 17,
  PromiseLetter = 18,
  FiscalDocument = 19
}

export enum ServiceType
{
  ComplexCase = 0,
  DentalMonitoring = 1,
  AditionalAligners = 2,
  Upgrade = 3
}

export enum SetupType
{
  Both = 0,
  Upper = 1,
  Lower = 2
}

export enum SetupAlignmentType
{
  AllTeth = 0,
  Alignment3 = 1,
  Alignment5 = 2
}

export enum ThirdMolarsType
{
  NoErupted = 0,
  AlignAndIncorporate = 1,
  NoMoveAndKeep = 2,
  DigitalExtraction = 3,
  NotPresentOrAbsent=4
}

export enum InterproximalPolishingType
{
  AsNecesary = 0,
  PreviouslyOnly = 1,
  IndicateAmount = 2
}

export enum MidLineType
{
  Keep = 0,
  MoveRight = 1,
  MoveLeft = 2
}

export enum PositionType {
  Keep = 0,
  Protrude = 1,
  Retract = 2,
}

export enum PositionTypeExtractions {
  Keep = 0,
  MoveToMesial = 1,
  Retract = 2,
}

export enum TorqueLowerType
{
  Keep = 0,
  ProIncline = 1,
  RetroIncline = 2,
}

export enum TorqueUpperType
{
  Keep = 0,
  Move = 1,
  Overcorrect = 2,
}

export enum HorizontalOverbiteType
{
  Contacts = 0,
  NoContacts = 1,
  SpaceBetween = 2
}

export enum ClassType
{
  ClassOne = 0,
  ClassTwo = 1,
  ClassThree = 2,
}

export enum Class2TypeOptions
{
  SuperiorCanineTrimming = 0,
  SuperiorPreMolarTrimming = 1,
  InferiorMolarTrimming = 2,
}

export enum Class3TypeOptions
{
  SuperiorMolarTrimming = 0,
  CanineTrimming = 1,
  PreMolarTrimming = 2,
}

export enum AditionalMechanicsType
{
  Elastics = 0,
  CrossedElastics = 1,
  UpperDistalization = 2,
  LowerDistalization = 3,
  UpperMesialization = 4,
  LowerMesialization = 5,
  SurgicalTreatment = 6,
  SkeletalAnchor = 7,
  ElasticsType2 = 8,
  ElasticsType3 = 9
}

export enum AditionalMechanicsToUse
{
  SkeletalAnchor = 0,
  BiteStops = 1,
  BuildUps = 2,
  PowerArms = 3,
  LingualSpikes = 4,
  PalatineSpikes = 5
}

export enum CrossedElasticsType
{
  BothSides = 0,
  RightSideOnly = 1,
  LeftSideOnly = 2,
}

export enum CrossedElasticsTypeNew
{
  MolarTrimming = 0,
}

export enum ManufacturingType
{
  Group = 0,
  Sequential = 1
}

export enum VerticalOverbiteType
{
  Keep = 0,
  Ideal = 1,
  ConcludeIn = 2
}

export enum VerticalMovementType
{
  Keep = 0,
  Extrude = 1,
  Intrude = 2
}

export enum LevelingType
{
  GingivalMargins = 0,
  IncisalEdges = 1
}

export enum CoordinateNumberAlignersType
{
  Independent = 0,
  Coordinated = 1
}

export enum ProductType
{
  Nano = 1,
  Social = 2,
  Simple = 3,
}
// #endregion Enums

// ********************************************************************************
// #region Constants
// ********************************************************************************
export const OrderPaymentStatusLabel = new Map<number, string>([
  [OrderPaymentStatus.InProcess, "En Proceso"],
  [OrderPaymentStatus.Completed, "Completado"],
]);

export const OrderPaymentTypeStatusLabel = new Map<number, string>([
  [PaymentTypeStatus.OpenPay, "OpenPay"],
  [PaymentTypeStatus.PayNet, "Paynet"],
  [PaymentTypeStatus.SPEI, "SPEI"],
  [PaymentTypeStatus.Paypal, "Paypal"],
  [PaymentTypeStatus.PayLink,"Link de pago"],
  [PaymentTypeStatus.CardPay,"Pago con terminal"]
]);

export const FileTypeLabel = new Map<number, string>([
  [FileType.LateralExtraoralId, "Extraoral Lateral"],
  [FileType.FrontalExtraoralId, "Extraoral Frontal"],
  [FileType.SmileExtraoralId, "Extraoral Sonrisa"],
  [FileType.UpperOcclusalId, "Oclusal Superior"],
  [FileType.LowerOcclusalId, "Oclusal Inferior"],
  [FileType.LeftLateralIntraoralId, "Intraoral Lateral Izquierda"],
  [FileType.FrontalIntraoralId, "Intraoral Frontal"],
  [FileType.RightLateralIntraoralId, "Intraoral Lateral Derecha"],
  [FileType.CompositeId, "Composite"],
  [FileType.PanoramicRadiographyId, "Radiografía Panorámica"],
  [FileType.LateralRadiographyId, "Radiografía Lateral"],
  [FileType.AditionalRadiographyId, "Radiografía Adicional"],
  [FileType.BiteSTLId, "Mordida STL"],
  [FileType.UpperSTLId, "Superior STL"],
  [FileType.LowerSTLId, "Inferior STL"],
  [FileType.AdditionalCaseFileId, "Nuevo archivo adicional"],
  [FileType.TaxInformation, "Constancia Fiscal"],
  [FileType.RightsAgreement, "Cesión de derechos"],
  [FileType.PromiseLetter, "Carta compromiso"],
  [FileType.FiscalDocument, "Constancia de situación fiscal"]
]);

export const FileTypeName = new Map<number, string>([
  [FileType.LateralExtraoralId, "LateralExtraoral"],
  [FileType.FrontalExtraoralId, "FrontalExtraoral"],
  [FileType.SmileExtraoralId, "SmileExtraoral"],
  [FileType.UpperOcclusalId, "UpperOcclusal"],
  [FileType.LowerOcclusalId, "LowerOcclusal"],
  [FileType.LeftLateralIntraoralId, "LeftLateralIntraoral"],
  [FileType.FrontalIntraoralId, "FrontalIntraoral"],
  [FileType.RightLateralIntraoralId, "RightLateralIntraoral"],
  [FileType.CompositeId, "Composite"],
  [FileType.PanoramicRadiographyId, "PanoramicRadiography"],
  [FileType.LateralRadiographyId, "LateralRadiography"],
  [FileType.AditionalRadiographyId, "AditionalRadiography"],
  [FileType.BiteSTLId, "BiteSTL"],
  [FileType.UpperSTLId, "UpperSTL"],
  [FileType.LowerSTLId, "LowerSTL"],
  [FileType.AdditionalCaseFileId, "AdditionalFile0"],
  [FileType.TaxInformation, "TaxInformation"],
  [FileType.RightsAgreement, "RightsAgreement"],
  [FileType.PromiseLetter, "PromiseLetter"],
  [FileType.FiscalDocument, "FiscalDocument"]
]);

export const FileTypeNamePath = new Map<number, string>([
  [FileType.LateralExtraoralId, "lateralExtraoralPath"],
  [FileType.FrontalExtraoralId, "frontalExtraoralPath"],
  [FileType.SmileExtraoralId, "smileExtraoralPath"],
  [FileType.UpperOcclusalId, "upperOcclusalPath"],
  [FileType.LowerOcclusalId, "lowerOcclusalPath"],
  [FileType.LeftLateralIntraoralId, "leftLateralIntraoralPath"],
  [FileType.FrontalIntraoralId, "frontalIntraoralPath"],
  [FileType.RightLateralIntraoralId, "rightLateralIntraoralPath"],
  [FileType.CompositeId, "compositePath"],
  [FileType.PanoramicRadiographyId, "panoramicRadiographyPath"],
  [FileType.LateralRadiographyId, "lateralRadiographyPath"],
  [FileType.AditionalRadiographyId, "additionalRadiographyPath"],
  [FileType.BiteSTLId, "biteSTLPath"],
  [FileType.UpperSTLId, "upperSTLPath"],
  [FileType.LowerSTLId, "lowerSTLPath"],
]);

export const ProductTypeTooltipText = new Map<number, string>([
  [ProductType.Nano, "Este producto es ideal para casos como:\n"
    + "Clase I - Buena oclusión\n"
    + "Recidivas ligeras\n"
    + "Apiñamiento 2-3mm\n"
    + "Ideal 1 arcada\n"
    + "No movimiento posterior (4-7)\n"
    + "Movimiento solo 2-2\n"
    + "IPR - Espacio"],
  [ProductType.Social, "Este producto es ideal para casos como:\n"
    + "Clase I - Buena oclusión\n"
    + "Recidivas ligeras/moderadas\n"
    + "Apiñamiento 4mm\n"
    + "Ideal 1 arcada\n"
    + "No movimiento posterior (4-7)\n"
    + "Movimiento solo 2-2\n"
    + "Movimiento 3s (solo rotación ligera)\n"
    + "IPR - Espacios\n"],
  [ProductType.Simple, "Este producto es ideal para casos como:\n"
    + "Clase I - Buena oclusión\n"
    + "Mejorar formas de arco\n"
    + "Ampliar para espacios\n"
    + "Bueno para 2 arcadas\n"
    + "CII/CIII menor a 2mm - ligeros elásticos\n"
    + "Apiñamientos moderados 6mm\n"
    + "No cambios esqueléticos"],
]);

export const FileCompositeArray: any[] = [
  {
    type: FileType.CompositeId,
    label: FileTypeLabel.get(FileType.CompositeId),
    name: FileTypeName.get(FileType.CompositeId),
    fileInfo: null
  },
];

export const FileTypeArray: any[] = [
  {
    type: FileType.LateralExtraoralId,
    label: FileTypeLabel.get(FileType.LateralExtraoralId),
    name: FileTypeName.get(FileType.LateralExtraoralId),
    fileInfo: null,
    isRequired: false,
  },
  {
    type: FileType.FrontalExtraoralId,
    label: FileTypeLabel.get(FileType.FrontalExtraoralId),
    name: FileTypeName.get(FileType.FrontalExtraoralId),
    fileInfo: null,
    isRequired: false,
  },
  {
    type: FileType.SmileExtraoralId,
    label: FileTypeLabel.get(FileType.SmileExtraoralId),
    name: FileTypeName.get(FileType.SmileExtraoralId),
    fileInfo: null,
    isRequired: false,
  },
  {
    type: FileType.UpperOcclusalId,
    label: FileTypeLabel.get(FileType.UpperOcclusalId),
    name: FileTypeName.get(FileType.UpperOcclusalId),
    fileInfo: null,
    isRequired: true,
  },
  {},
  {
    type: FileType.LowerOcclusalId,
    label: FileTypeLabel.get(FileType.LowerOcclusalId),
    name: FileTypeName.get(FileType.LowerOcclusalId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.LeftLateralIntraoralId,
    label: FileTypeLabel.get(FileType.LeftLateralIntraoralId),
    name: FileTypeName.get(FileType.LeftLateralIntraoralId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.FrontalIntraoralId,
    label: FileTypeLabel.get(FileType.FrontalIntraoralId),
    name: FileTypeName.get(FileType.FrontalIntraoralId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.RightLateralIntraoralId,
    label: FileTypeLabel.get(FileType.RightLateralIntraoralId),
    name: FileTypeName.get(FileType.RightLateralIntraoralId),
    fileInfo: null,
    isRequired: true,
  },
];


export const FileTypeArrayWOSpace: any[] = [
  {
    type: FileType.LateralExtraoralId,
    label: FileTypeLabel.get(FileType.LateralExtraoralId),
    name: FileTypeName.get(FileType.LateralExtraoralId),
    fileInfo: null,
    isRequired: false,
  },
  {
    type: FileType.FrontalExtraoralId,
    label: FileTypeLabel.get(FileType.FrontalExtraoralId),
    name: FileTypeName.get(FileType.FrontalExtraoralId),
    fileInfo: null,
    isRequired: false,
  },
  {
    type: FileType.SmileExtraoralId,
    label: FileTypeLabel.get(FileType.SmileExtraoralId),
    name: FileTypeName.get(FileType.SmileExtraoralId),
    fileInfo: null,
    isRequired: false,
  },
  {
    type: FileType.UpperOcclusalId,
    label: FileTypeLabel.get(FileType.UpperOcclusalId),
    name: FileTypeName.get(FileType.UpperOcclusalId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.LowerOcclusalId,
    label: FileTypeLabel.get(FileType.LowerOcclusalId),
    name: FileTypeName.get(FileType.LowerOcclusalId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.LeftLateralIntraoralId,
    label: FileTypeLabel.get(FileType.LeftLateralIntraoralId),
    name: FileTypeName.get(FileType.LeftLateralIntraoralId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.FrontalIntraoralId,
    label: FileTypeLabel.get(FileType.FrontalIntraoralId),
    name: FileTypeName.get(FileType.FrontalIntraoralId),
    fileInfo: null,
    isRequired: true,
  },
  {
    type: FileType.RightLateralIntraoralId,
    label: FileTypeLabel.get(FileType.RightLateralIntraoralId),
    name: FileTypeName.get(FileType.RightLateralIntraoralId),
    fileInfo: null,
    isRequired: true,
  },
];


export const FileRadiographyArray: any[] = [
  {
    type: FileType.LateralRadiographyId,
    label: FileTypeLabel.get(FileType.LateralRadiographyId),
    name: FileTypeName.get(FileType.LateralRadiographyId),
    fileInfo: null
  },
  {
    type: FileType.PanoramicRadiographyId,
    label: FileTypeLabel.get(FileType.PanoramicRadiographyId),
    name: FileTypeName.get(FileType.PanoramicRadiographyId),
    fileInfo: null
  },
  {
    type: FileType.AditionalRadiographyId,
    label: FileTypeLabel.get(FileType.AditionalRadiographyId),
    name: FileTypeName.get(FileType.AditionalRadiographyId),
    fileInfo: null
  },
];

export const FileAdditionalArray =
{
  Id:0,
  type: FileType.AdditionalCaseFileId,
  label: FileTypeLabel.get(FileType.AdditionalCaseFileId),
  name: FileTypeName.get(FileType.AdditionalCaseFileId),
  fileInfo: null
};

export const AdditionalCustomerFilesArray: any[] = [
  {
    type: FileType.RightsAgreement,
    label: FileTypeLabel.get(FileType.RightsAgreement),
    name: FileTypeName.get(FileType.RightsAgreement),
    fileInfo: null
  },
  {
    type: FileType.PromiseLetter,
    label: FileTypeLabel.get(FileType.PromiseLetter),
    name: FileTypeName.get(FileType.PromiseLetter),
    fileInfo: null
  },
  {
    type: FileType.FiscalDocument,
    label: FileTypeLabel.get(FileType.FiscalDocument),
    name: FileTypeName.get(FileType.FiscalDocument),
    fileInfo: null
  }
];

export const TaxInformationFIle =
{
  type: FileType.TaxInformation,
  label: FileTypeLabel.get(FileType.TaxInformation),
  name: FileTypeName.get(FileType.TaxInformation),
  fileInfo: null
};

export const FileSTLArray: any[] = [
  {
    type: FileType.LowerSTLId,
    label: FileTypeLabel.get(FileType.LowerSTLId),
    name: FileTypeName.get(FileType.LowerSTLId),
    fileInfo: null},
  {
    type: FileType.UpperSTLId,
    label: FileTypeLabel.get(FileType.UpperSTLId),
    name: FileTypeName.get(FileType.UpperSTLId),
    fileInfo: null},
  {
    type: FileType.BiteSTLId,
    label: FileTypeLabel.get(FileType.BiteSTLId),
    name: FileTypeName.get(FileType.BiteSTLId),
    fileInfo: null},
];

export const ServiceTypeLabel = new Map<number, string>([
  [ServiceType.ComplexCase, "Cambiar a Caso Complejo"],
  [ServiceType.DentalMonitoring, "DentalMonitoring"],
  [ServiceType.AditionalAligners, "Alineadores Adicionales"],
  [ServiceType.Upgrade, "Upgrade de producto"]
]);

export const SetupTypeLabel = new Map<number, string>([
  [SetupType.Both, "Ambas Arcadas"],
  [SetupType.Upper, "Superior Únicamente"],
  [SetupType.Lower, "Inferior Únicamente"],
]);

export const SetupAlignmentTypeLabel = new Map<number, string>([
  [SetupAlignmentType.AllTeth, "Alineación de todos los dientes"],
  [SetupAlignmentType.Alignment3, "Alineación de 3-3"],
  [SetupAlignmentType.Alignment5, "Alineación de 5-5"],
]);

export const DescriptionBySetupAlignmentType = new Map<number, string>([
  [SetupAlignmentType.AllTeth, "Mover sin restricciones todas las piezas dentales incluyendo anteriores, premolares y molares"],
  [SetupAlignmentType.Alignment3, "Solo mover piezas anteirores (de canino a canino) y mantener oclusión de premolares y molares como se encuentra actualmente"],
  [SetupAlignmentType.Alignment5, "Solo mover anteriores y premolares y mantener oclusion de molares como se encuentra actualmente"],
]);

export const ThirdMolarsTypeLabel = new Map<number, string>([
  [ThirdMolarsType.NoErupted, "No se encuentran erupcionados"],
  [ThirdMolarsType.AlignAndIncorporate, "Alinear e incorporarlos al tratamiento"],
  [ThirdMolarsType.NoMoveAndKeep, "No mover y mantenerlos en tratamiento"],
  [ThirdMolarsType.DigitalExtraction, "Realizar la extracción digital de las piezas seleccionadas"],
  [ThirdMolarsType.NotPresentOrAbsent, "No están presentes / Están ausentes"]
]);

export const InterproximalPolishingTypeLabel = new Map<number, string>([
  [InterproximalPolishingType.AsNecesary, "Como sea necesario (arcada completa en premolares y anteriores)"],
  [InterproximalPolishingType.PreviouslyOnly, "Anterior únicamente (canino a canino solamente)"],
  [InterproximalPolishingType.IndicateAmount, "Indicar cantidad máxima de Pulido Interproximal por contacto en mm"],
]);

export const MidLineTypeLabel = new Map<number, string>([
  [MidLineType.Keep, "Mantener"],
  [MidLineType.MoveRight, "Mover a la derecha"],
  [MidLineType.MoveLeft, "Mover a la izquierda"],
]);

export const PositionTypeLabel = new Map<number, string>([
  [PositionType.Keep, "Mantener"],
  [PositionType.Protrude, "Protruir"],
  [PositionType.Retract, "Retraer"],
]);

export const PositionTypeExtractionsLabel = new Map<number, string>([
  [PositionTypeExtractions.Keep, "Mantener"],
  [PositionTypeExtractions.MoveToMesial, "Mover a mesial"],
  [PositionTypeExtractions.Retract, "Retraer"],
]);

export const TorqueLowerTypeLabel = new Map<number, string>([
  [TorqueLowerType.Keep, "Mantener"],
  [TorqueLowerType.ProIncline, "Proinclinar"],
  [TorqueLowerType.RetroIncline, "Retroinclinar"],
]);

export const TorqueUpperTypeLabel = new Map<number, string>([
  [TorqueUpperType.Keep, "Mantener"],
  [TorqueUpperType.Move, "Mover cara labial a ideal en +7"],
  [TorqueUpperType.Overcorrect, "Sobrecorregir Torque"],
]);

export const HorizontalOverbiteTypeLabel = new Map<number, string>([
  [HorizontalOverbiteType.Contacts, "Con contactos ligeros en anteriores"],
  [HorizontalOverbiteType.NoContacts, "Sin contactos en anteriores (menos de 0.2mm de espacio)"],
  [HorizontalOverbiteType.SpaceBetween, "Dejar espacio entre bordes incisales"],
]);

export const ClassTypeLabel = new Map<number, string>([
  [ClassType.ClassOne, "Clase I"],
  [ClassType.ClassTwo, "Clase II"],
  [ClassType.ClassThree, "Clase III"],
]);

export const Class2TypeOptionLabel = new Map<number, string>([
  [Class2TypeOptions.SuperiorCanineTrimming, "Recortes en caninos"],
  [Class2TypeOptions.SuperiorPreMolarTrimming, "Recortes en premolares"],
  [Class2TypeOptions.InferiorMolarTrimming, "Recortes para botón en molares"],
]);

export const Class3TypeOptionLabel = new Map<number, string>([
  [Class3TypeOptions.SuperiorMolarTrimming, "Recortes para botón en molares"],
  [Class3TypeOptions.CanineTrimming, "Recortes en caninos"],
  [Class3TypeOptions.PreMolarTrimming, "Recortes en premolares"],
]);

export const AditionalMechanicsTypeLabel = new Map<number, string>([
  [AditionalMechanicsType.Elastics, "Elásticos AP"],
  [AditionalMechanicsType.ElasticsType2, "Elásticos clase II"],
  [AditionalMechanicsType.ElasticsType3, "Elásticos clase III"],
  [AditionalMechanicsType.CrossedElastics, "Elásticos Cruzados"],
  [AditionalMechanicsType.SkeletalAnchor, "Anclaje Esquelético"],
  [AditionalMechanicsType.UpperDistalization, "Distalización Superior"],
  [AditionalMechanicsType.LowerDistalization, "Distalización Inferior"],
  [AditionalMechanicsType.UpperMesialization, "Mesialización Superior"],
  [AditionalMechanicsType.LowerMesialization, "Mesialización Inferior"],
  [AditionalMechanicsType.SurgicalTreatment, "Tratamiento Quirúrgico"],
]);

export const AditionalMechanicsToUseLabel = new Map<number, string>([
  [AditionalMechanicsToUse.SkeletalAnchor, "Anclaje esquelético (mini implantes, mini palcas de titanio)"],
  [AditionalMechanicsToUse.BiteStops, "Bite Stops (Rampas de mordida en piezas anteriores para abrir mordida)"],
  [AditionalMechanicsToUse.BuildUps, "Build ups (Agregar en oclusal de molares para abrir mordida)"],
  [AditionalMechanicsToUse.PowerArms, "Power arms (asistencia en movimientos radiculares y cierre de espacios)"],
  [AditionalMechanicsToUse.LingualSpikes, "Spikes en lingual de 2-2 Inferior"],
  [AditionalMechanicsToUse.PalatineSpikes, "Spikes en palatino de 2-2 Superior"],

]);

export const CrossedElasticsTypeLabel = new Map<number, string>([
  [CrossedElasticsType.BothSides, "Ambos lados"],
  [CrossedElasticsType.RightSideOnly, "Solo lado derecho"],
  [CrossedElasticsType.LeftSideOnly, "Solo lado izquierdo"],
]);

export const CrossedElasticsTypeNewLabel = new Map<number, string>([
  [CrossedElasticsTypeNew.MolarTrimming, "Recortes para botón en molares"],
]);

export const ManufacturingTypeLabel = new Map<number, string>([
  [ManufacturingType.Group, "En grupo"],
  [ManufacturingType.Sequential, "Secuencial"],
]);

export const VerticalOverbiteTypeLabel = new Map<number, string>([
  [VerticalOverbiteType.Keep, "Mantener"],
  [VerticalOverbiteType.Ideal, "Ideal"],
  [VerticalOverbiteType.ConcludeIn, "Finalizar en"],
]);

export const VerticalMovementTypeLabel = new Map<number, string>([
  [VerticalMovementType.Keep, "Mantener"],
  [VerticalMovementType.Extrude, "Extruir"],
  [VerticalMovementType.Intrude, "Intruir"],
]);

export const LevelingTypeLabel = new Map<number, string>([
  [LevelingType.GingivalMargins, "Márgenes gingivales"],
  [LevelingType.IncisalEdges, "Bordes incisales"],
]);

export const CoordinateNumberAlignersTypeLabel = new Map<number, string>([
  [CoordinateNumberAlignersType.Independent, "Alineadores independientes por arcada"],
  [CoordinateNumberAlignersType.Coordinated, "Mismo número de alineadores en arcada superior e inferior"],
]);

export const DescriptionByCoordinateNumberAlignersType = new Map<number, string>([
  [CoordinateNumberAlignersType.Independent, "No hay alineadores pasivos, solo se usan alineadores activos a la necesidad de cada arcada, dando cantidades diferentes de alineadores en superior e inferior"],
  [CoordinateNumberAlignersType.Coordinated, "Se iguala la cantidad de alineadores en las arcadas a la arcada de mayor cantidad, para terminar en el mismo número de alineador"],
]);

export enum StatusesID{
  InProcessOfDischarge = 1,
  StageReceived = 2,
  Digitization = 3,
  SetUp1 = 4,
  SetUp2 = 5,
  SetUp3 = 6,
  ToBeApprovedByDr = 7,
  ApprovedForManufacturingWithAvailableAligners = 8,
  Printing3D = 9,
  AcetateFabrication = 10,
  ApprovalForPolishingAndTrimming = 11,
  Packaging = 12,
  PackedAndToBeShipped = 13,
  Shipped = 14,
  StageClosed = 15,
  StageCancelled = 16,
  SetupChanges = 17,
  RejectedFiles = 18
}
