import { AppMaterialModule } from "src/app/layouts/material/app.material.module";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";

import { CustomInputNumberComponent } from './components/custom-input-number/custom-input-number.component';
import { DebounceClickDirective } from "./directives/debounce-click.directive";
import { DialogComponent } from "./components/dialog/dialog.component";
import { DragAndDropComponent } from './components/drag-and-drop/drag-and-drop.component';
import { DragAndDropFilesDirective } from "./directives/drag-and-drop-files.directive";
import { EqualValidatorDirective } from './directives/equal-validator.directive';
import { FooterComponent } from "./components/footer/footer.component";
import { InnerLinksDirective } from './directives/inner-links.directive';
import { IntegerSpinnerComponent } from "./components/integer-spinner/integer-spinner.component";
import { LoadingComponent } from "./components/loading/loading.component";
import { PieceCheckboxDirective } from './directives/piece-checkbox.directive';
import { PiecesContainerComponent } from './components/pieces-container/pieces-container.component';
import { LoadingGeneralComponent } from "./components/loading-general/loading-general.component";
import { LoadingStaticComponent } from './components/loading-static/loading-static.component';

@NgModule({
  declarations: [
    CustomInputNumberComponent,
    DebounceClickDirective,
    DialogComponent,
    DragAndDropComponent,
    DragAndDropFilesDirective,
    EqualValidatorDirective,
    FooterComponent,
    InnerLinksDirective,
    IntegerSpinnerComponent,
    LoadingComponent,
    PieceCheckboxDirective,
    PiecesContainerComponent,
  ],
  imports: [
    AppMaterialModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: [
    CustomInputNumberComponent,
    DebounceClickDirective,
    DialogComponent,
    DragAndDropComponent,
    DragAndDropFilesDirective,
    EqualValidatorDirective,
    FooterComponent,
    InnerLinksDirective,
    IntegerSpinnerComponent,
    LoadingComponent,
    PieceCheckboxDirective,
    PiecesContainerComponent,
  ],
  providers: [

  ]
})
export class AppShareModule { }
