import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LoadingService } from "../services/loading.service";
import { Observable, throwError as observableThrowError, throwError } from 'rxjs';
import { finalize } from "rxjs/operators";

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  
  private requests: HttpRequest<any>[] = [];

  constructor(private loadingService: LoadingService) {  }

  public removeRequest(req: HttpRequest<any>) {
      const index = this.requests.indexOf(req);
      if (index >= 0) {
          this.requests.splice(index, 1);
      }
      this.loadingService.isLoading$.next(this.requests.length > 0)
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      this.requests.push(request);
      this.loadingService.isLoading$.next(true);

      return new Observable(observer => {
          const subscription = next.handle(request).subscribe(event => {
              if (event instanceof HttpResponse) {
                  this.removeRequest(request);
                  observer.next(event);
              }
          }, err => {
              this.removeRequest(request);
              observer.error(err);
          })
      });
  }
}
