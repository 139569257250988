import { NgModule } from "@angular/core";
import { FormsModule} from "@angular/forms";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponent } from "../app.component";
import { HomeComponent } from "./home.component";

@NgModule({
  declarations: [
    HomeComponent,
  ],
  exports: [
    HomeComponent,
  ],
  imports: [
    FormsModule,
    AppRoutingModule,
  ],
  bootstrap: [AppComponent]
})

export class HomeModule { }
