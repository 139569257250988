import { SafeUrl } from "@angular/platform-browser";
import "./../util/ArrayExtensions";

// ********************************************************************************
// #region Classes
// ********************************************************************************

export interface IEntity
{
  id: number;
}


export class LocalStorage implements Storage
{
  [name: string]: any;
  readonly length: number;
  clear(): void { }
  getItem(key: string): string | null { return undefined; }
  key(index: number): string | null { return undefined; }
  removeItem(key: string): void { }
  setItem(key: string, value: string): void { }
}

export class UserDto
{

}

export class LoginDto
{
  id: string = "";
  password: string = "";
  cartId: string = "";
  language: string = "";
}

export interface FileInfo
{
  name: string,
  url: SafeUrl
}

export class UpdateFileDto
{
  id: number = 0;
  fileType: FileType = 0;
  fileBlobId: number = 0;
}

export class RefreshTokenDto
{
  language: string = "";
  refreshToken: string = "";
}

export class ValidateSessionDto
{
  language: string = "";
  token: string = "";
}

export class DynamicPipeType
{
  //NOTE: Must have same methods name that is inside "dynamic.pipe.ts"
  public static OrderStatus = "orderStatus";
  public static DateTime = "dateTime";
  public static Date = "date";
  public static UTCDateTime = "UTCDateTime";
  public static Currency = "currency";
  public static ToUpperCase = "toUpper";
  public static OrderPayment = "orderPayment";
}

export class Product
{
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  isDeleted: boolean;
  productType: ProductType;
  alignersQuantity: number;
  quantityStock?: number;
  isCase: boolean;
  hasDentalMonitoring: boolean;
  hasUnlimitedAligners: boolean;
  category: Category;
  additionalServices?: ProductAdditionalServices;
  specList: ProductSpec[];
  principalImage?: string;
  images: ProductImage[];
  priceList: ProductPriceList[];
  itemPrices: ItemPrices;
  baseTax: number;
  basePriceProduct: number;
  basePriceExtraAligner: number;
  basePriceDentalMonitoring: number;
  basePriceComplexCase:number;
  specialProductPrice?:number;
  specialProductPriceComments?:string;
  expirationTime: number;
  setupIncluded: boolean;
  sort: number;
}
export class ItemPrices
{
  priceExtraAligner?: number;
  subtotal: number;
  price: number;
  priceExtraDentalMonitoring?: number;
  priceComplexCase?: number;
  tax?: number;
  totalTax?: number;
  shoppingDiscount?: number;
  priceWithDiscount?: number;
}
export class Category
{
  id: number;
  parentId: number;
  name: string;
  description: string;
  subcategories: Category[];
}

export class ProductImage
{
  id: number;
  order: number;
  productId: number;
  url: string;
}

export class ServiceImage
{
  order?: number;
  url: string;
}

export class ProductSpec
{
  id: number;
  name: string;
  value: string;
  productId: number;
}

export class ProductPriceList
{
  id: number;
  priceProduct: number;
  priceExtraAligner: number;
  priceExtraDentalMonitoring: number;
  priceComplexCase: number;
  tax: number;
  isDeleted: boolean;
  productId: number;
  customerTypeId: number;
}

export class ProductAdditionalServices
{
  addDentalMonitoring: boolean;
  addIsComplexCase: boolean;
  hasUnlimitedAligners?: boolean;
  extraAlignersQuantity: number;
}

export class CaseStage
{
  stageNumber: number;
  upperAligners: number;
  lowerAligners: number;
  comments: string;
  frecuencyDays: number;
  startDate?: Date;
  endDate?: Date;
  isManufactured: boolean;
  isPendingToReviewAlignersQuantity?: boolean;
  totalAlignersQuantity: number;
  caseId: number;
  setupId: number;
  statusId?: number;
  isDeleted: boolean;
}

export class Status
{
  id: number;
  name: string;
  backofficeDescription: string;
  ecommerceDescription: string;
  caseDescription: string;
  maxDays: number;
  colorOnTime: string;
  colorOffTime: string;
  order: number;
  isDeleted: boolean;
  isFinal: boolean;
  isCancelable: boolean;
  requiresSetupPayment: boolean;
  requiresSufficientAlignersAvailable: boolean;
  isSpecial: boolean;
  escapeStatusId: number;
}

// #endregion Classes

// ********************************************************************************
// #region Interfaces
// ********************************************************************************

export interface IEntity
{
  id: number;
}

export interface IBaseRequest
{
  token: string;
}

export interface ICustomerLevel
{
  name: string;
  description: string;
  code: string;
  minNumberCases: number;
  maxNumberCases: number;
  shoppingDiscount: number;
  customerTypeId: number;
}

export interface IRolePermission
{
  id?: number;
  roleId: number;
  permissionId: number;
}

export interface IPermission
{
  code: string;
  name: string;
  description: string;
  order: number;
  isActive: boolean;
  isDeleted: boolean;
}
//Add base interfaces



// #endregion Interfaces

//Currency Type
export enum CurrencyTypeInt
{
  MXN = 0,
  USD = 1,
  CAD = 2,
  EUR = 3,
  GBP = 4,
  JPY = 5,
}

export enum ProductType
{
  Plan = 1,
  Setup = 2,
  Service = 3,
  Accessory = 4,
}

//check ISO 4217 before add new one
export const CurrencyTypeCode = new Map<number, string>([
  [CurrencyTypeInt.MXN, 'MXN'],
  [CurrencyTypeInt.USD, 'USD$'],
  [CurrencyTypeInt.CAD, 'CAD'],
  [CurrencyTypeInt.EUR, 'EUR'],
  [CurrencyTypeInt.GBP, 'GBP'],
  [CurrencyTypeInt.JPY, 'JPY'],
]);

export enum FileWeirExtension
{
  STL = '.stl',
}

export enum FileApplicationType
{
  PDF = 'application/pdf',
  STL = 'application/octet-stream',
  TXT = 'application/plain',
  ZIP7 = 'application/x-7z-compressed',
  ZIP = 'application/zip',
  RAR = 'application/x-rar-compressed',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export enum FileExtension//Agregar nuevos formatos de archivo
{
  PDF = '.pdf',
  TXT = '.txt',
  ZIP = '.zip',
  ZIP7 = '.7z',
  RAR = '.rar',
  WORD = '.docx',//
  POWERPOINT = '.pptx',//
  EXCEL = '.xlsx',//
}

export enum ImageExtension
{
  JPG = '.jpg',
  JPEG = '.jpg',
  PNG = '.png',
  GIF = '.gif',
  TIF = '.tif',
  BMP = '.bmp'
}

export enum ImageType
{
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  TIF = 'image/tiff',
  BMP = 'image/bmp'
}

export enum FileType
{
  LateralExtraoralId = 0,
  FrontalExtraoralId = 1,
  SmileExtraoralId = 2,
  UpperOcclusalId = 3,
  LowerOcclusalId = 4,
  LeftLateralIntraoralId = 5,
  FrontalIntraoralId = 6,
  RightLateralIntraoralId = 7,
  CompositeId = 8,
  PanoramicRadiographyId = 9,
  LateralRadiographyId = 10,
  AdditionalRadiographyId = 11,
  BiteSTLId = 12,
  UpperSTLId = 13,
  LowerSTLId = 14,
  AdditionalCaseFileId = 15,
  TaxInformation = 16,
  RightsAgreement = 17,
  PromiseLetter = 18,
  FiscalDocument = 19
}

export enum OrderStatus
{
  Pending = 0,
  Approved = 1,
  Canceled = 2,
}

export enum CustomerStatus
{
  Inactivo=0,
  Activo=1,
  Pending=2
}

export enum OrderPaymentStatus {
  InProcess = 0,
  Completed = 1,
}

export enum PaymentTypeStatus
{
  OpenPay = 1,
  PayNet = 2,
  SPEI = 3,
  Paypal = 4,
  InPerson = 5,
  PayLink = 6,
  Courtesy = 7,
  CardPay = 8
}

export enum ServiceType
{
  ComplexCase = 0,
  DentalMonitoring = 1,
  AditionalAligners = 2,
  Upgrade = 3
}

export const ProductTypeLabel = new Map<number, string>([
  [ProductType.Plan, "Plan"],
  [ProductType.Setup, "Setup"],
  [ProductType.Service, "Servicio"],
  [ProductType.Accessory, "Accesorio"],
]);

export const FileTypeLabel = new Map<number, string>([
  [FileType.LateralExtraoralId, "Extraoral Lateral"],
  [FileType.FrontalExtraoralId, "Extraoral Frontal"],
  [FileType.SmileExtraoralId, "Extraoral Sonrisa"],
  [FileType.UpperOcclusalId, "Oclusal Superior"],
  [FileType.LowerOcclusalId, "Oclusal Inferior"],
  [FileType.LeftLateralIntraoralId, "Intraoral Lateral Izquierda"],
  [FileType.FrontalIntraoralId, "Intraoral Frontal"],
  [FileType.RightLateralIntraoralId, "Intraoral Lateral Derecha"],
  [FileType.CompositeId, "Composite"],
  [FileType.PanoramicRadiographyId, "Radiografía Panorámica"],
  [FileType.LateralRadiographyId, "Radiografía Lateral"],
  [FileType.AdditionalRadiographyId, "Radiografía Adicional"],
  [FileType.BiteSTLId, "Mordida STL"],
  [FileType.UpperSTLId, "Superior STL"],
  [FileType.LowerSTLId, "Inferior STL"],
  [FileType.AdditionalCaseFileId, "Nuevo archivo adicional"],
  [FileType.TaxInformation, "Constancia Fiscal"],
  [FileType.RightsAgreement, "Cesión de derechos"],
  [FileType.PromiseLetter, "Carta compromiso"],
  [FileType.FiscalDocument, "Constancia de situación fiscal"]
]);

export const FileTypeName = new Map<number, string>([
  [FileType.LateralExtraoralId, "LateralExtraoral"],
  [FileType.FrontalExtraoralId, "FrontalExtraoral"],
  [FileType.SmileExtraoralId, "SmileExtraoral"],
  [FileType.UpperOcclusalId, "UpperOcclusal"],
  [FileType.LowerOcclusalId, "LowerOcclusal"],
  [FileType.LeftLateralIntraoralId, "LeftLateralIntraoral"],
  [FileType.FrontalIntraoralId, "FrontalIntraoral"],
  [FileType.RightLateralIntraoralId, "RightLateralIntraoral"],
  [FileType.CompositeId, "Composite"],
  [FileType.PanoramicRadiographyId, "PanoramicRadiography"],
  [FileType.LateralRadiographyId, "LateralRadiography"],
  [FileType.AdditionalRadiographyId, "AditionalRadiography"],
  [FileType.BiteSTLId, "BiteSTL"],
  [FileType.UpperSTLId, "UpperSTL"],
  [FileType.LowerSTLId, "LowerSTL"],
  [FileType.AdditionalCaseFileId, "AdditionalFile0"],
  [FileType.TaxInformation, "TaxInformation"],
  [FileType.RightsAgreement, "RightsAgreement"],
  [FileType.PromiseLetter, "PromiseLetter"],
  [FileType.FiscalDocument, "FiscalDocument"]
]);

export const FileTypeNamePath = new Map<number, string>([
  [FileType.LateralExtraoralId, "lateralExtraoralPath"],
  [FileType.FrontalExtraoralId, "frontalExtraoralPath"],
  [FileType.SmileExtraoralId, "smileExtraoralPath"],
  [FileType.UpperOcclusalId, "upperOcclusalPath"],
  [FileType.LowerOcclusalId, "lowerOcclusalPath"],
  [FileType.LeftLateralIntraoralId, "leftLateralIntraoralPath"],
  [FileType.FrontalIntraoralId, "frontalIntraoralPath"],
  [FileType.RightLateralIntraoralId, "rightLateralIntraoralPath"],
  [FileType.CompositeId, "compositePath"],
  [FileType.PanoramicRadiographyId, "panoramicRadiographyPath"],
  [FileType.LateralRadiographyId, "lateralRadiographyPath"],
  [FileType.AdditionalRadiographyId, "additionalRadiographyPath"],
  [FileType.BiteSTLId, "biteSTLPath"],
  [FileType.UpperSTLId, "upperSTLPath"],
  [FileType.LowerSTLId, "lowerSTLPath"],
]);

export const ProductTypeArray: any[] = [
  { key: ProductType.Plan, value: ProductTypeLabel.get(ProductType.Plan) },
  { key: ProductType.Setup, value: ProductTypeLabel.get(ProductType.Setup) },
  { key: ProductType.Accessory, value: ProductTypeLabel.get(ProductType.Accessory) },
];

export const FileCompositeArray: any[] = [
  {
    type: FileType.LateralExtraoralId,
    label: FileTypeLabel.get(FileType.LateralExtraoralId),
    name: FileTypeName.get(FileType.LateralExtraoralId),
    fileInfo: null
  },
  {
    type: FileType.FrontalExtraoralId,
    label: FileTypeLabel.get(FileType.FrontalExtraoralId),
    name: FileTypeName.get(FileType.FrontalExtraoralId),
    fileInfo: null
  },
  {
    type: FileType.SmileExtraoralId,
    label: FileTypeLabel.get(FileType.SmileExtraoralId),
    name: FileTypeName.get(FileType.SmileExtraoralId),
    fileInfo: null
  },
  {
    type: FileType.UpperOcclusalId,
    label: FileTypeLabel.get(FileType.UpperOcclusalId),
    name: FileTypeName.get(FileType.UpperOcclusalId),
    fileInfo: null,
    isRequired: true
  },
  {
    type: FileType.CompositeId,
    label: FileTypeLabel.get(FileType.CompositeId),
    name: FileTypeName.get(FileType.CompositeId),
    fileInfo: null
  },
  {
    type: FileType.LowerOcclusalId,
    label: FileTypeLabel.get(FileType.LowerOcclusalId),
    name: FileTypeName.get(FileType.LowerOcclusalId),
    fileInfo: null,
    isRequired: true
  },
  {
    type: FileType.LeftLateralIntraoralId,
    label: FileTypeLabel.get(FileType.LeftLateralIntraoralId),
    name: FileTypeName.get(FileType.LeftLateralIntraoralId),
    fileInfo: null,
    isRequired: true
  },
  {
    type: FileType.FrontalIntraoralId,
    label: FileTypeLabel.get(FileType.FrontalIntraoralId),
    name: FileTypeName.get(FileType.FrontalIntraoralId),
    fileInfo: null,
    isRequired: true
  },
  {
    type: FileType.RightLateralIntraoralId,
    label: FileTypeLabel.get(FileType.RightLateralIntraoralId),
    name: FileTypeName.get(FileType.RightLateralIntraoralId),
    fileInfo: null,
    isRequired: true
  },
];

export const FileSingleCompositeArray: any[] = [
  {
    type: FileType.CompositeId,
    label: FileTypeLabel.get(FileType.CompositeId),
    name: FileTypeName.get(FileType.CompositeId),
    fileInfo: null
  },
];

export const FileRadiographyArray: any[] = [
  {
    type: FileType.LateralRadiographyId,
    label: FileTypeLabel.get(FileType.LateralRadiographyId),
    name: FileTypeName.get(FileType.LateralRadiographyId),
    fileInfo: null
  },
  {
    type: FileType.PanoramicRadiographyId,
    label: FileTypeLabel.get(FileType.PanoramicRadiographyId),
    name: FileTypeName.get(FileType.PanoramicRadiographyId),
    fileInfo: null
  },
  {
    type: FileType.AdditionalRadiographyId,
    label: FileTypeLabel.get(FileType.AdditionalRadiographyId),
    name: FileTypeName.get(FileType.AdditionalRadiographyId),
    fileInfo: null
  },
];

export const FileSTLArray: any[] = [
  {
    type: FileType.LowerSTLId,
    label: FileTypeLabel.get(FileType.LowerSTLId),
    name: FileTypeName.get(FileType.LowerSTLId),
    fileInfo: null
  },
  {
    type: FileType.UpperSTLId,
    label: FileTypeLabel.get(FileType.UpperSTLId),
    name: FileTypeName.get(FileType.UpperSTLId),
    fileInfo: null
  },
  {
    type: FileType.BiteSTLId,
    label: FileTypeLabel.get(FileType.BiteSTLId),
    name: FileTypeName.get(FileType.BiteSTLId),
    fileInfo: null
  },
];

export const FileAdditionalArray =
{
  Id: 0,
  type: FileType.AdditionalCaseFileId,
  label: FileTypeLabel.get(FileType.AdditionalCaseFileId),
  name: FileTypeName.get(FileType.AdditionalCaseFileId),
  fileInfo: null
};

export const AdditionalCustomerFilesArray: any[] = [
  {
    type: FileType.RightsAgreement,
    label: FileTypeLabel.get(FileType.RightsAgreement),
    name: FileTypeName.get(FileType.RightsAgreement),
    fileInfo: null
  },
  {
    type: FileType.PromiseLetter,
    label: FileTypeLabel.get(FileType.PromiseLetter),
    name: FileTypeName.get(FileType.PromiseLetter),
    fileInfo: null
  },
  {
    type: FileType.FiscalDocument,
    label: FileTypeLabel.get(FileType.FiscalDocument),
    name: FileTypeName.get(FileType.FiscalDocument),
    fileInfo: null
  }
];

export const OrderStatusLabel = new Map<number, string>([
  [OrderStatus.Pending, "Recibido"],
  [OrderStatus.Approved, "Aceptado"],
  [OrderStatus.Canceled, "Cancelado"],
]);

export const CustomerStatusLabel = new Map<number, string>([
  [CustomerStatus.Inactivo, "Inactivo"],
  [CustomerStatus.Activo, "Activo"],
  [CustomerStatus.Pending, "Pendiente"],
]);

export const OrderPaymentStatusLabel = new Map<number, string>([
  [OrderPaymentStatus.InProcess, "En Proceso"],
  [OrderPaymentStatus.Completed, "Completado"],
]);

export const OrderPaymentTypeStatusLabel = new Map<number, string>([
  [PaymentTypeStatus.OpenPay, "OpenPay"],
  [PaymentTypeStatus.PayNet, "Paynet"],
  [PaymentTypeStatus.SPEI, "SPEI"],
  [PaymentTypeStatus.Paypal, "Paypal"],
  [PaymentTypeStatus.InPerson, "Pago presencial"],
  [PaymentTypeStatus.PayLink, "Link de pago"],
  [PaymentTypeStatus.Courtesy, "Cortesía"],
  [PaymentTypeStatus.CardPay, "Pago con terminal"]
]);

export const OrderStatusArray: any[] = [
  { value: "", name: "Todos los estatus" },
  { value: OrderStatus.Pending, name: OrderStatusLabel.get(OrderStatus.Pending) },
  { value: OrderStatus.Approved, name: OrderStatusLabel.get(OrderStatus.Approved) },
  { value: OrderStatus.Canceled, name: OrderStatusLabel.get(OrderStatus.Canceled) },
];

export const CustomerStatusArray: any[] = [
  { value: "", name: "Todos los estatus" },
  { value: CustomerStatus.Inactivo, name: CustomerStatusLabel.get(CustomerStatus.Inactivo) },
  { value: CustomerStatus.Activo, name: CustomerStatusLabel.get(CustomerStatus.Activo) },
  { value: CustomerStatus.Pending, name: CustomerStatusLabel.get(CustomerStatus.Pending) },
];

export const OrderPaymentStatusArray: any[] = [
  { value: "", name: "Todos las formas de pago" },
  { value: OrderPaymentStatus.InProcess, name: OrderPaymentStatusLabel.get(OrderPaymentStatus.InProcess) },
  { value: OrderPaymentStatus.Completed, name: OrderPaymentStatusLabel.get(OrderPaymentStatus.Completed) },
];

export const OrderPaymentTypeStatusArray: any[] = [
  { value: PaymentTypeStatus.OpenPay, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.OpenPay) },
  { value: PaymentTypeStatus.PayNet, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.PayNet) },
  { value: PaymentTypeStatus.SPEI, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.SPEI) },
  { value: PaymentTypeStatus.InPerson, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.InPerson) },
  { value: PaymentTypeStatus.PayLink, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.PayLink) },
  { value: PaymentTypeStatus.Courtesy, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.Courtesy) },
  { value: PaymentTypeStatus.CardPay, name: OrderPaymentTypeStatusLabel.get(PaymentTypeStatus.CardPay) }
];

//Product

export enum FileTypeProduct {
  principal = 0,
  secundaria = 1,
  terciaria = 2,
}
export const FileTypeProductLabel = new Map<number, string>([
  [FileTypeProduct.principal, "Fotografí­a Principal"],
  [FileTypeProduct.secundaria, "Fotografí­a Secundaria"],
  [FileTypeProduct.terciaria, "Fotografí­a Terciaria"],
]);
export const FileTypeProductName = new Map<number, string>([
  [FileTypeProduct.principal, "Principal"],
  [FileTypeProduct.secundaria, "Secundaria"],
  [FileTypeProduct.terciaria, "Terciaria"],
]);
export const FileTypeProductNamePath = new Map<number, string>([
  [FileTypeProduct.principal, "PrincipalPath"],
  [FileTypeProduct.secundaria, "SecundariaPath"],
  [FileTypeProduct.terciaria, "TerciariaPath"],
]);
export const FileProductArray: any[] = [
  {
    type: FileTypeProduct.principal,
    label: FileTypeProductLabel.get(FileTypeProduct.principal),
    name: FileTypeProductName.get(FileTypeProduct.principal),
    fileInfo: null,
    blobId: null,
    productImageId: null
  },
  {
    type: FileTypeProduct.secundaria,
    label: FileTypeProductLabel.get(FileTypeProduct.secundaria),
    name: FileTypeProductName.get(FileTypeProduct.secundaria),
    fileInfo: null,
    blobId: null,
    productImageId: null
  },
  {
    type: FileTypeProduct.terciaria,
    label: FileTypeProductLabel.get(FileTypeProduct.terciaria),
    name: FileTypeProductName.get(FileTypeProduct.terciaria),
    fileInfo: null,
    blobId: null,
    productImageId: null
  },
];
export class UpdateFileProductImage
{
  id: number = 0;
  order: FileType = 0;
  productId: number = 0;
  blobId: number = 0;
}

export class ShoppingCartItem
{
  public item: Product;
  public quantity: number;
  public customerId?:number;
  public userId?:number;
  public total?:number;
  public totalBeforeEdit?:number;
}

export enum SetupType
{
  Both = 0,
  Upper = 1,
  Lower = 2
}

