import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityConfig } from '../_appCommon/base/EntityConfig';
import { UrlQueryDef } from 'src/app/_appCommon/base/UrlQueryDef';
import { EntityDataServiceBase } from '../_appCommon/base/EntityDataServiceBase';

@Injectable()
export class CustomerDataService extends EntityDataServiceBase
{
  private getCustomerByUserId_WebApiMethod: string = "getCustomerByUserId";
  private createCustomer_WebApiMethod: string = "";
  private updateCustomer_WebApiMethod: string = "";

  constructor(protected http: HttpClient)
  {
    super(http, 'customer');
  }

  protected loadEntityConfig(): void
  {
    this.entityConfig = new EntityConfig();
    this.entityConfig.entityName = 'customer';
    this.entityConfig.apiUrl = 'api/Customer';
  }

  public getByUserId(userId: number): Observable<{}>
  {
    let queryDef = new UrlQueryDef();
    queryDef.filter = 'UserId eq ' + userId;
    let observable = this.http.get(this.apiUrl, queryDef.getRequestConfig()).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public getCustomerByUserId(userId: number): Observable<any>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.get(this.createUrl(this.getCustomerByUserId_WebApiMethod + "/" + userId), queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public createCustomer(body: any): Observable<any>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.post(this.createUrl(this.createCustomer_WebApiMethod), body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public updateCustomer(body: any): Observable<any>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.put(this.createUrl(this.updateCustomer_WebApiMethod), body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }
}

