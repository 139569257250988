import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { RecaptchaModule } from "ng-recaptcha";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponent } from "../app.component";
import { AppMaterialModule } from "../layouts/material/app.material.module";
import { UserAccessComponent } from "../user-access/user-access.component";
import { ShareModule } from "../_appCommon/share.module";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { AppShareModule } from '../../shared/app.module';
import { UserOnboardingComponent } from "./onboarding/user-onboarding.component";
import { IncompleteUsersDataService } from "../../app-client/_dataservices/IncompleteUsersDataService";
import { OnboardingCRUDComponent } from "./onboarding/dataCRUD/onboardingCRUD.component";
import { EmailPasswordResetComponent } from "./onboarding/emailPasswordReset/emailPasswordReset.component";
import { PasswordResetComponent } from "./onboarding/passwordReset/passwordReset.component";
import { LoadingGeneralModule } from "src/shared/components/loading-general/loading-general.module";


@NgModule({
  declarations: [
    RegisterComponent,
    LoginComponent,
    UserAccessComponent,
    OnboardingCRUDComponent,
    EmailPasswordResetComponent,
    PasswordResetComponent,
    UserOnboardingComponent
  ],
  exports: [
    RecaptchaModule,
    LoginComponent,
    RegisterComponent,
    UserAccessComponent,
    UserOnboardingComponent
  ],
  imports: [
    FormsModule,
    AppRoutingModule,
    ShareModule,
    AppShareModule,
    AppMaterialModule,
    CommonModule,
    BrowserModule,
    ReactiveFormsModule,
    LoadingGeneralModule
  ],
  providers: [
    IncompleteUsersDataService
  ],
  bootstrap: [AppComponent]
})

export class UserAccessModule { }
