import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})

export class LoadingComponent implements OnInit
{
  @Input() size: string = "36px";
  @Input() borderWidth: string = "4px";

  constructor() { }

  ngOnInit(): void { }

}
