import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileInfo, ValidImageExtensions } from 'src/app-client/_appCommon/base/AppInterfaceAndBaseClasses';
import { FormGroup } from '@angular/forms';
import { Alert, AlertType } from '../../../app/_appCommon/base/Alert';
import { Router } from '@angular/router';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.css']
})
export class DragAndDropComponent implements OnInit
{
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @Input() textWElipsis: boolean = false;
  @Input() fileTitle: string;
  @Input() downloadFilePreview: boolean = true;
  @Input() isGenericFileType: boolean = false;
  @Input() accept: string;
  @Input() text: string;
  @Input() isRequired: boolean = false;
  @Input() parentForm: FormGroup;
  @Input() nameControl;
  @Input() pdfOnly: boolean = false;
  @Input() isReadOnly: boolean = false;
  @Output()
  public onSelectFile: EventEmitter<File> = new EventEmitter<File>();
  @Output()
  public onDeleteFile: EventEmitter<boolean> = new EventEmitter<boolean>();

  private file: File;

  public isFileSelected = false;

  public isLoading = true;

  public form: FormGroup;

  ngOnInit(): void { }

  constructor(private router: Router) { }

  private _fileSelected: FileInfo;

  @Input() set fileSelected(fileSelected: FileInfo | null)
  {
    this._fileSelected = fileSelected;
    if (fileSelected != null)
      this.parentForm.controls[this.nameControl].setValue(fileSelected.name);

    if (fileSelected)
    {
      if (!fileSelected.name && !fileSelected.url)
      {
        this.isFileSelected = false;
        this._fileSelected = null;
        this.fileInput.nativeElement.value = null;
      }
    }
  }

  get fileSelected(): FileInfo
  {
    return this._fileSelected;
  }

  public deleteFile()
  {
    this.isFileSelected = false;
    this.parentForm.controls[this.nameControl].reset();
    this.fileInput.nativeElement.value = null;
    this.onDeleteFile.emit(true);
  }

  public changeFileListener(event): void
  {
    if (event.srcElement.files[0].size > 524288000)
    {
      this.deleteFile();
      new Alert(AlertType.Warning, "No se aceptan archivos mayores a 500MB");
    }
    else if (this.pdfOnly && event.srcElement.files[0].type != "application/pdf")
    {
      new Alert(AlertType.Warning, "Solo se aceptan archivos PDF");
    }
    else
    {
      this.uploadFiles(event.srcElement.files[0]);
    }
  }

  public uploadFiles(file: File): void
  {
    this.fileInput.nativeElement.value = '';

    this.file = file;
    this.isFileSelected = true;
    this.onSelectFile.emit(this.file);
  }

  public openFileBrowser(): void
  {
    this.fileInput.nativeElement.click();
  }

  public downloadFile(): void
  {
    var url = this.fileSelected.url.toString();
    var s = url.split('.');
    var ext = '.' + s[s.length - 1];

    if (ValidImageExtensions.includes(ext))
    {
      url = this.router.serializeUrl(
        this.router.createUrlTree(['/img'], { queryParams: { path: this.fileSelected.url.toString() } })
      );
    }

    window.open(url, '_blank', 'noreferrer');
  }
}
