import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-notices',
  templateUrl: './privacy-notices.component.html',
  styleUrls: ['./privacy-notices.component.css']
})
export class PrivacyNoticesComponent implements OnInit
{

  ngOnInit(): void { }


}
