import { Directive, ElementRef, HostBinding, HostListener, Input, OnInit } from '@angular/core';

@Directive({
  selector: '[appScrollAnimation]'
})
export class ScrollAnimationDirective implements OnInit
{
  @Input() appScrollAnimation;
  @HostBinding('@slideRightToLeft') atSlideRightToLeft = "";
  @HostBinding('@slideLeftToRight') atSlideLeftToRight = "";
  @HostBinding('@slideTopToBottom') atSlideTopToBottom = "";
  @HostBinding('@scaleContent') atScaleContent = "";

  @HostListener('window:scroll', [])
  scroll(): void
  {
    const rect = this.elementRef.nativeElement.getBoundingClientRect();
    if (rect.top < innerHeight/2 )
    {
      switch (this.appScrollAnimation)
      {
        case 'slideRightToLeft':
          this.atSlideRightToLeft = 'final';
          break;
        case 'slideLeftToRight':
          this.atSlideLeftToRight = 'final';
          break;
        case 'slideTopToBottom':
          this.atSlideTopToBottom = 'final';
          break;
        case 'scaleContent':
          this.atScaleContent = 'final';
          break;
        default:
          break;
      }
    }
    else
    {
      switch (this.appScrollAnimation)
      {
        case 'slideRightToLeft':
          this.atSlideRightToLeft = 'initial';
          break;
        case 'slideLeftToRight':
          this.atSlideLeftToRight = 'initial';
          break;
        case 'slideTopToBottom':
          this.atSlideTopToBottom = 'initial';
          break;
        case 'scaleContent':
          this.atScaleContent = 'initial';
          break;
        default:
          break;
      }
    }
  }

  ngOnInit(): void { }

  constructor(private elementRef: ElementRef) { }
}
