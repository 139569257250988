<div class="h-100 d-flex align-items-center justify-content-center background-login">
  <div class="px-5 py-4 w-50 mx-auto d-flex flex-column justify-content-center container-wrapper">
    <div class="mx-auto my-4 fs-xl">
      <img src="../../../assets/logo.png" alt="Logo Aliwell" height="50px">
    </div>
    <div class="text-center mt-2">
      <p class="text-title">Recuperar contraseña</p>
      <h2 class="mt-2">{{showChangePasswordMessage ? 'La contraseña ha sido actualizada correctamente' : 'Escribe tu nueva contraseña'}} </h2>
    </div>
  
    <!-- RESET PASSWORD -->
    <div class="flex-grow-1">
      <form #resetPasswordForm="ngForm" class="d-flex flex-column justify-content-around align-items-center">
        <div class="w-100">
          <div *ngIf="!showChangePasswordMessage">
            <mat-form-field class="w-100">
              <span matPrefix class="fa fa-lock mr-2">&nbsp;</span>
              <input matInput [type]="isNewPasswordHide ? 'password' : 'text'" placeholder="Nueva contraseña" name="newPassword" id="newPassword" required [(ngModel)]="userAccessResetPassword.newPassword" (input)="validatePassword($event.target.value)">
              <button mat-icon-button matSuffix (click)="isNewPasswordHide = !isNewPasswordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="isNewPasswordHide">
                <mat-icon>{{isNewPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="resetPasswordForm.form.hasError('required', ['password'])">Ingresa tu contraseña</mat-error>
              <mat-error>{{newPasswordError}}</mat-error>
            </mat-form-field>
  
            <mat-form-field class="w-100">
              <span matPrefix class="fa fa-lock mr-2">&nbsp;</span>
              <input matInput [type]="isConfirmPasswordHide ? 'password' : 'text'" placeholder="Vuelve a escribir la contraseña" name="confirmPassword" id="confirmPassword" required [(ngModel)]="userAccessResetPassword.confirmPassword">
              <button mat-icon-button matSuffix (click)="isConfirmPasswordHide = !isConfirmPasswordHide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="isConfirmPasswordHide">
                <mat-icon>{{isConfirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
              </button>
              <mat-error *ngIf="userAccessResetPassword.confirmPassword!=userAccessResetPassword.newPassword">La contrase&ntilde;a debe coincidir.</mat-error>
            </mat-form-field>
  
  
            <div class="w-100 text-center mt-3">
              <button (click)="resetPasswordForm.form.valid && changePassword()" *ngIf="!loading" class="button primary-button w-75 mx-auto">Cambiar contraseña</button>
              <div class="mx-auto" *ngIf="loading" style="width: fit-content;">
                <app-loading></app-loading>
              </div>
            </div>
          </div>
          <div class="w-100 text-center" *ngIf="showChangePasswordMessage">
            <button (click)="reset()" class="button primary-button mx-auto">Aceptar</button>
          </div>
        </div>
      </form>
    </div>
  </div>
  
</div>
