<main class="container-lg mt-5">
  <h2 class="text-center">Avisos de Privacidad</h2>
  <section>
      <p>
          ALIWELL SA DE CV <br>
          Calle Dr. Alfredo Piñeyro L&oacute;pez No 1012 (Anteriormente denominada Guadalajara), colonia Mitras Sur, Monterrey, Nuevo Le&oacute;n M&eacute;xico C.P. 64710.
      </p>

      <p>
          Una de las prioridades de ALIWELL SA DE CV es respetar la privacidad de sus usuarios y mantener segura la informaci&oacute;n. Asimismo, ALIWELL SA DE CV informar&aacute; al usuario qu&eacute; tipo de datos recolecta, c&oacute;mo los almacena, la finalidad del archivo, c&oacute;mo los protege, el alcance de su compromiso de confidencialidad y los derechos que &eacute;ste posee como titular de la informaci&oacute;n.
      </p>

      <p>
          Entre la informaci&oacute;n solicitada al usuario se encuentra:
      </p>
      <ul>
          <li>Nombre completo</li>
          <li>Direcci&oacute;n</li>
          <li>Email</li>
          <li>Tel&eacute;fono</li>
          <li>Ciudad / Estado / Pa&iacute;s</li>
          <li>Datos de facturaci&oacute;n</li>
          <li>Datos del negocio</li>
      </ul>
  </section>
  <section>
      <h3>Uso de la informaci&oacute;n</h3>
      <p>ALIWELL SA DE CV recopilar&aacute; sus datos personales y datos de contacto de su empresa, por lo que solicitamos su consentimiento a trav&eacute;s del presente Aviso de Privacidad con el objeto de utilizarlos para los siguientes fines:</p>
      <ul>
          <li>Para que la compra-venta se realice efectivamente</li>
          <li>Para dar cumplimiento a obligaciones contra&iacute;das con nuestros clientes</li>
          <li>Para dar cumplimiento a obligaciones contra&iacute;das con nuestros proveedores en relaci&oacute;n al cumplimiento de sus pedidos</li>
          <li>Identificarlo como cliente y prestarle los servicios contratados con nosotros</li>
          <li>Para verificar su identidad</li>
          <li>Para contactarlo y enviarle informaci&oacute;n relevante respecto a temas de nuestros servicios, as&iacute; como informarle sobre cambios</li>
          <li>Para procurar un servicio eficiente y una mejor atenci&oacute;n al usuario</li>
          <li>Para efectos de cobranza</li>
          <li>En la secci&oacute;n de “Encuentra un Ortodoncista” dentro de nuestro sitio web, pueden aparecer los datos de contacto de su empresa (¨Datos del consultorio”). Esto con la finalidad de conectarlo con usuarios o pacientes interesados en encontrar un distribuidor autorizado para aplicar el tratamiento Aliwell®</li>
      </ul>
  </section>
  <section>
      <h3>Transferencia de informaci&oacute;n con terceros</h3>

      <p>
          ALIWELL SA DE CV cuidadosamente realiza transferencias de informaci&oacute;n con las empresas de web hosting con las que mantiene una relaci&oacute;n jur&iacute;dica vigente para poder mantener, actualizar y administrar sus sitios web.
      </p>
      <p>
          ALIWELL SA DE CV puede transferir algunos de sus datos (direcci&oacute;n de empresa y datos de contacto) a empresas de paqueter&iacute;a o log&iacute;sticas con la finalidad de realizar los env&iacute;os de producto que usted solicite.
      </p>
      <p>
          Si usted no manifiesta su oposici&oacute;n para que sus datos personales sean transferidos, se entender&aacute; que ha otorgado su consentimiento para ello.
      </p>
  </section>
  <section>
      <h3>Medios para el ejercicio de sus derechos como titular de los datos personales</h3>
      <p>
          Como titular de los datos personales objeto del presente Aviso de Privacidad usted podr&aacute; ejercitar sus derechos de acceso, rectificaci&oacute;n, cancelaci&oacute;n u oposici&oacute;n.
      </p>
      <p>
          As&iacute; mismo podr&aacute; revocar el consentimiento otorgado para el uso de sus datos personales en cualquier momento. En caso que lo desee contactarnos v&iacute;a correo electr&oacute;nico a <a href="mailto:jgaytan@aliwell.mx">jgaytan@aliwell.mx</a>
      </p>
  </section>
  <section>
      <h3>Cambios en el aviso de privacidad</h3>
      <p>
          El presente Aviso de Privacidad podr&aacute; ser modificado en el futuro. En todo caso, cualquier modificaci&oacute;n al mismo se har&aacute; de su conocimiento mediante la publicaci&oacute;n del mismo en nuestra p&aacute;gina de internet y/o el env&iacute;o de un correo electr&oacute;nico a la cuenta que usted nos proporcion&oacute; inicialmente.
      </p>
      <p>
          No seremos responsables en caso de que usted no reciba la referida notificaci&oacute;n de cambio en el Aviso de Privacidad por causa de alg&uacute;n problema con su cuenta de correo electr&oacute;nico o de transmisi&oacute;n de datos por internet.
      </p>
  </section>
  <section>
      <h3>Aceptaci&oacute;n de los t&eacute;rminos</h3>
      <p>
          Esta declaraci&oacute;n de Confidencialidad / Privacidad est&aacute; sujeta a los t&eacute;rminos y condiciones de todos los sitios web de ALIWELL SA DE CV, lo cual constituye un acuerdo legal entre el usuario y ALIWELL SA DE CV.
      </p>
      <p>
          Si el usuario utiliza los servicios en cualquiera de los sitios de ALIWELL SA DE CV, significa que ha le&iacute;do, entendido y acordado los t&eacute;rminos antes expuestos. Si no est&aacute; de acuerdo con ellos, el usuario no deber&aacute; proporcionar ninguna informaci&oacute;n personal, ni utilizar los servicios de los sitios de ALIWELL SA DE CV
      </p>
  </section>
</main>
