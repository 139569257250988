import { StringTools } from "./StringTools";
import { Tools } from "./Tools";

export class Guard
{
  /**
   * Checks an argument to ensure it isn't blank or null
   */
  public static argumentNotBlankOrNull(argumentValue: string, argumentName: string)
  {
    if (StringTools.isBlankOrNull(argumentValue))
    {
      let exceptionMessage = `Argument ${argumentName} cannot be null or blank.`;
      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Checks an argument to ensure it isn't blank or null
   */
  public static valueNotBlankOrNull(value: any, exceptionMessage: string)
  {
    if (StringTools.isBlankOrNull(value))
    {

      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Checks an argument to ensure it isn't null
   */
  public static argumentNotNull(argumentValue: any, argumentName: string)
  {
    if (argumentValue == null)
    {
      let exceptionMessage = `Argument ${argumentName} cannot be null.`;

      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Checks an argument to ensure it isn't null
   */
  public static valueNotNull(value: any, exceptionMessage: string)
  {
    if (value == null)
    {
      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Fails if the value is true
   */
  public static failIfTrue(value: boolean, exceptionMessage: string)
  {
    if (value)
    {
      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Fails if the value is false
   */
  public static failIfFalse(value: boolean, exceptionMessage: string)
  {
    if (!value)
    {
      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Fails if the value is NOT true
   */
  public static isTrue(value: boolean, exceptionMessage: string)
  {
    if (!value)
    {
      Tools.throwException(exceptionMessage);
    }
  }

  /**
   * Fails if the value is NOT false
   */
  public static isFalse(value: boolean, exceptionMessage: string)
  {
    if (value)
    {
      Tools.throwException(exceptionMessage);
    }
  }

}
