import { Component, ElementRef, Input, OnInit, Output, Renderer2, ViewChild, EventEmitter, OnChanges, SimpleChanges, ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-pieces-container',
  templateUrl: './pieces-container.component.html',
  styleUrls: ['./pieces-container.component.css']
})
export class PiecesContainerComponent implements OnInit, OnChanges
{
  @Input() public axesPointValueCustom:number|null = null;
  @Input() public numberPieces: number = 2;
  @Input() public axes = [1, 2, 4, 3];
  @Input() public axesDisabled = [];
  @Output() public piecesSelected: EventEmitter<string[]> = new EventEmitter<string[]>();

  @ViewChild('piecesContainer', { static: true }) public piecesContainer:  ElementRef;

  public pieces: any = [];

  public numberPicesItems: number[] = [];

  @Input('selectedPieces') selectedPieces: string[] = [];

  constructor(private renderer: Renderer2, private cdr: ChangeDetectorRef) { }

  ngOnInit(): void
  {
    this.renderer.setStyle(this.piecesContainer.nativeElement, 'grid-template-columns', `repeat(${this.numberPieces*2}, 1fr)`);
    this.numberPicesItems = [...Array(this.numberPieces)].map((_, i) => i + 1);
    for (let index = 1; index <= this.axes.length; index++)
    {
      let tempPieces = [ ];
      this.numberPicesItems.forEach( item =>
      {
        tempPieces.push(
          {
            id: `axis${index}-item${item}`,
            value: `${this.axes[index-1]}.${this.axesPointValueCustom!=null?this.axesPointValueCustom:item}`,
            checked: false
          });
      });
      this.pieces = index % 2 == 0 ? this.pieces.concat(tempPieces) : this.pieces.concat(tempPieces.reverse());
      
    }
    this.selectedPieces.forEach(e => {
      let found = this.pieces.find(p=> p.value == e);
      if(found) found.checked = true;
    });

    this.cdr.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedPieces']){
      this.pieces.forEach(e=> {
        e.checked=false;
      })
      if(this.pieces.length>0){
        changes['selectedPieces'].currentValue.forEach(e => {
          let found = this.pieces.find(p=> p.value == e);
          if(found) found.checked = true;
        });
      }
      this.cdr.detectChanges();
    }
  }
  
  public isDisabledAxis(value: string): boolean
  {
    return this.axesDisabled.contains(+value.split('.')[0]);
  }

  public onCheckboxChange(event: any): void
  {
    event.preventDefault();
    event.stopPropagation();

    let input = this.pieces.find(x => x.id == event.target.id);
    if(input != undefined){
      let isInputChecked = this.pieces.find(x => x.id == event.target.id).checked;
      this.pieces.find(x => x.id == event.target.id).checked = !isInputChecked;
      this.piecesSelected.emit(this.result);
    }
  }

  public get result(): string[]
  {
    return this.pieces.filter(x => x.checked == true).map((x) => x.value);
  }
}
