import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Observable } from "rxjs";
import { share } from 'rxjs/operators';
import { UrlQueryDef } from 'src/app/_appCommon/base/UrlQueryDef';

@Injectable()
export class SystemServicesDataService
{
  constructor(protected http: HttpClient) { }

  public getStartupInfo(): Observable<{}>
  {
    let queryDef = new UrlQueryDef;
    var subject = this.http.get("api/systemServices/getStartupInfo", queryDef.getRequestConfig(true)).pipe(share());

    return subject;
  }

  public getClientStartupInfo(): Observable<{}>
  {
    var subject = this.http.get("api/systemServices/getClientStartupInfo").pipe(share());

    return subject;
  }

  public getSystemStartupInfo(): Observable<{}>
  {
    var subject = this.http.get("api/systemServices/getSystemStartupInfo").pipe(share());

    return subject;
  }

  /** Checks if the user is in Server Cache */
  public checkUserInCache(): Observable<{}>
  {
    let queryDef = new UrlQueryDef;
    var subject = this.http.get("api/systemServices/checkUserInCache", queryDef.getRequestConfig(true)).pipe(share());

    return subject;
  }

}
