import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { isNullOrUndefined } from 'is-what';
import { ControllerBase } from '../../_appCommon/base/ControllerBase';
import { Component, Injector, Input, OnInit, ViewChild } from '@angular/core';
import { BroadcastManager } from 'src/app/_appCommon/base/BroadcastManager';
import { AppClientContext } from 'src/app/_appCommon/base/AppClientContext';
import { UserAccess } from 'src/app/_appCommon/base/AppInterfaceAndBaseClasses';
import { AppEventNames, ECOMMERCE_USER_TOKEN } from '../../_appCommon/base/Constants';
import { AuthenticationService } from '../../_dataservices/security/AuthenticationService';
import { SystemServicesDataService } from '../../_dataservices/security/SystemServicesDataService';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [AuthenticationService, SystemServicesDataService]
})
export class LoginComponent extends ControllerBase implements OnInit
{
  @Input() public byPassEmailPasswordReset: boolean = false;

  @ViewChild(NgForm) public loginForm: NgForm;
  @ViewChild(NgForm) public forgotPasswordForm: NgForm;

  public loading = false;
  public showModal = true;
  public isUserValid = false;
  public isPasswordHide = true;
  public attemptCounter: number = 0;
  public showForgotMyPassword = false;
  public inputEmailForgotPassword: string = "";
  public userAccessLogin: UserAccess = { email: "", password: "" };


  constructor(private router: Router,
              private injector: Injector,
              private authenticationService: AuthenticationService,
              private systemServicesDataService: SystemServicesDataService
  )
  {
    super();
  }

  ngOnInit(): void 
  { 
    this.getUserLoginFromCache()
  }

  public onHandleCloseModal(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnShowUserLogin, false);
  }

  public onHandleClickSignUp(): void
  {
    this.onHandleCloseModal();
    BroadcastManager.Instance.broadcast(AppEventNames.OnShowUserSignUp, true);
  }

  public onHandleDisableByPass(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingByPassEmailPasswordChange, false);
  }

  public onHandleEmailPasswordChange(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingEmailPasswordChange, true);
  }
  public onHandlePasswordChange(loginData:any): void
  {
    let event =
    {
      show: true,
      key: loginData.Password,
      loginId: loginData.Email
    };
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingPasswordChange, event);
  }
  public onHandleCRUDShow(loginId:any, finalPassword:any): void
  {
    let event =
    {
      show: true,
      loginId: loginId,
      password: finalPassword
    };
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingCRUDData, event);
  }

  public onHandleCheckUserValid(): void
  {
    this.loading = true;
    this.authenticationService.validateUser(this.userAccessLogin.email)
      .subscribe((response) =>
      {
        if(isNullOrUndefined(response))
        {
          this.setInfoAlert('Usuario no encontrado');
          this.loading = false;
        }
        else
        {
          /**
           * Begins: Onboarding changes!
           */
          let body =
          {
            loginId: this.userAccessLogin.email
          };
          this.authenticationService.checkPasswordResetRequired(body).subscribe((passwordReset: any) =>
          {
            if (passwordReset && !this.byPassEmailPasswordReset)
            {
              this.authenticationService.forgotPasswordOnboarding(this.userAccessLogin.email).subscribe((response: any) =>
              {
                this.onHandleCloseModal();
                this.onHandleEmailPasswordChange();
              }, (fail: any) =>
              {
                this.setDangerAlert(fail.error.message);
              });
            }
            else
            {
              this.loading = false;
              this.isUserValid = true;
            }
          }, (fail: any) =>
          {
            this.setDangerAlert(fail.error.message);
          });
          /**
           * Ends: Onboarding changes!
           */
        }
      }, (fail) =>
      {
        var outputs = this.CreateErrorMessage(fail.error.failures);
        this.setDangerAlert(fail.error.message + outputs);
        this.loading = false;
      });
  }

  public CreateErrorMessage(failures: any): String
  {
    var message = "";

    var array = Object.keys(failures);

    array.forEach(function (key, index)
    {
      message += ", " + key + ":";
      failures[key].forEach(element =>
      {
        message += ' ' + element;
      });
    });

    return message;
  }

  private resetPasswordCounterChecking()
  {
    ++this.attemptCounter;
    if (this.attemptCounter === 3)
    {
      // enviar a recuperación de contraseña
      this.showForgotMyPassword = true;
      this.attemptCounter = 0;
    }
  }

  public login(): void
  {
    if (this.userAccessLogin.email && this.userAccessLogin.password)
    {
      this.loading = true;
      const loginData = {
        Email: this.userAccessLogin.email,
        Password: this.userAccessLogin.password
      };
      let bodyPasswordReset =
      {
        loginId: this.userAccessLogin.email
      };
      this.authenticationService.loginNoLoginCount(loginData).subscribe((loginNoLoginCountResponse: any) =>
      {
        this.authenticationService.checkPasswordResetRequired(bodyPasswordReset).subscribe((passwordReset: any) =>
        {
          if (passwordReset)
          {
            this.onHandleCloseModal();
            this.onHandlePasswordChange(loginData);
          }
          else
          {
            let bodyIncompleteUser =
            {
              loginId: this.userAccessLogin.email
            };
            this.authenticationService.checkIncompleteUser(bodyIncompleteUser).subscribe((isIncompleteUser: any) =>
            {
              if (isIncompleteUser)
              {
                this.onHandleCloseModal();
                this.onHandleCRUDShow(loginData.Email, loginData.Password);
              }
              else
              {
                this.authenticationService.login(loginData).subscribe((response: any) =>
                {
                  if (this.getUserLoginFromCache())
                  {
                    this.loading = false;
                    this.setSuccessAlert('¡Bienvenido!');
                    this.onHandleCloseModal();
                    this.router.navigate(['/ecommerce']);
                  }
                }, exception =>
                {
                  this.loading = false;
                  this.setDangerAlert("Inicio de sesión: " + exception.error.message);
                  this.resetPasswordCounterChecking();
                });
              }
            }, (fail: any) =>
            {
              this.setDangerAlert(fail.error.message);
            });

          }
        }, (fail: any) =>
        {
          this.setDangerAlert(fail.error.message);
        });
      }, (fail: any) =>
      {
        this.loading = false;
        this.setDangerAlert(fail.error.message);
      });
    }
    else
    {
      this.setDangerAlert('Completa los datos del inicio de sesión');
      this.loading = false;
    }
  }

  public forgotPassword(): void
  {
    this.loading = true;
    this.authenticationService.forgotPassword(this.inputEmailForgotPassword)
      .subscribe((response: any) => {
        this.setInfoAlert("Se ha enviado un correo de recuperación de contraseña");
        this.loading = false;
        this.onHandleCloseModal();
      }, fail => {
        this.setWarningAlert("Ha ocurrido un error al recuperar la contraseña: " + fail.error.message);
        this.loading = false;
      });
  }

  private getUserLoginFromCache(): boolean
  {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    this.router.navigate(['/ecommerce']);
    return this.setSecurityToken(user);
  }

  private setSecurityToken(user: any): boolean
  {
    if (user && user.cacheToken)
    {
      // Remove Token
      AppClientContext.Instance.headerValues.delete(ECOMMERCE_USER_TOKEN);

      // Append Token
      AppClientContext.Instance.headerValues.set(ECOMMERCE_USER_TOKEN, user.cacheToken);
      return true;
    }
    return false;
  }
}

