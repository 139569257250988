
<header>
  <div class="header-container container d-flex align-items-center h-100">
		<div class="header-content d-flex flex-column">
			<p class="text-highlighted">Dale a tus pacientes la</p>
			<p class="text-big">libertad de sonre&iacute;r</p>
			<p class="text-middle">al m&aacute;ximo todos los d&iacute;as</p>
		</div>
	</div>
</header>
<main>
  <!-- INTRODUCTION VIDEO SECTION -->
  <section class="container-lg row row-cols-1 row-cols-md-2 gx-5 gy-4 px-0">
    <div class="col ps-md-0 order-1 order-md-0">
      <video controls controlsList="nodownload" [muted]="true" autoplay loop>
				<source
				src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/video/Video%20Hero.mp4"
				type="video/mp4"
				loading="lazy"
				title="Video Hero"
				>
				<track default kind="captions" />
				Sorry, your browser doesn't support embedded videos.
			</video>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-start gap-2 pe-md-0 order-0 order-md-1">
      <h1 class="title-page" appScrollAnimation="scaleContent">&Uacute;nete a la red de ortodoncistas <span>con gran crecimiento</span> en M&eacute;xico y Latam</h1>
      <p class="content-page">Ofrece a tus pacientes la soluci&oacute;n ideal para mejorar su sonrisa con la m&aacute;s alta tecnolog&iacute;a en alineadores transparentes</p>
      <button class="button primary-button d-none d-md-block">Conoce más <i class='fa fa-angle-right'></i></button>
    </div>
    <button class="button primary-button d-md-none order-2 mt-4 mx-auto">Conoce más <i class='fa fa-angle-right'></i></button>
  </section>

  <div class="bg-dark-section position-relative overflow-hidden">
    <app-background></app-background>
    <!-- STEPS TO OFFER ALIWELL SECTION -->
    <section class="steps-offer container-lg d-flex flex-column justify-content-center align-items-center gap-4">
      <h2 class="text-center fs-xxl">C&oacute;mo puedes ofrecer <span>Aliwell<sup>&#174;</sup></span> a tus pacientes</h2>
      <div class="steps-container position-relative">
        <div class="step d-flex flex-row flex-md-column justify-content-around justify-content-md-center  align-items-center gap-3" *ngFor="let item of StepsToOffer; index as i">
          <div class="step-svg" [innerHTML]="transformSVG(item.svg)"></div>
          <p class="step-text text-center"><span>{{i+1}}</span>. {{item.name}}</p>
        </div>
      </div>
    </section>

    <!-- DENTAL MONITORING SECTION -->
    <section class="dental-monitoring container-lg d-flex flex-column justify-content-center align-items-center gap-4">
      <h2 class="text-center fs-xxl">
        Preg&uacute;ntanos como puedes incluir <span>DentalMonitoring</span> en tus casos de Aliwell<sup>&#174;</sup>
      </h2>
      <div class="row row-cols-1 row-cols-md-2 d-flex justify-content-center align-items-center gy-4 m-4">
        <div class="col order-1" appScrollAnimation="slideTopToBottom">
          <p class="content-page pb-4">
            Ofrece a tus pacientes una experiencia de vanguardia y alta tecnología a través de las ventajas que da el monitoreo remoto de su tratamiento Aliwell® con DentalMonitoring®
            <br>
            <br>
            Pregúntanos como incluirlo en todos tus pacientes con Aliwell® a precio preferencial
          </p>
          <img class="w-50" src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/dentalmonitoring-logo.svg" alt="DentalMonitoring Logo">
        </div>
        <div class="col order-0">
          <img src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/d2.png" alt="DentalMonitoring Product" appScrollAnimation="slideRightToLeft">
        </div>
      </div>
    </section>
  </div>

  <!-- PROGRAMS SECTION -->
  <div class="bg-light-section">
    <section class="programs container-lg d-flex flex-column justify-content-center align-items-center gap-4">
      <h2 class="text-center fs-xxl">Programas <span>Aliwell<sup>&#174;</sup></span></h2>
      <div class="programs-container gap-5">
        <div class="program-card d-flex flex-column justify-content-center align-items-center" *ngFor="let item of Programs">
          <h4 class="program-title text-center m-0">aliwell<sup>&#174;</sup> <span>{{item.title}}</span></h4>
          <p class="program-content text-center fs-md" [innerHTML]="item.description"></p>
        </div>
      </div>
      <button class="button primary-button" (click)="onHandleClickSignUp()">Registrarme</button>
    </section>
  </div>

  <div class="bg-dark-section">
    <!-- ORTHODONTIST TESTIMONIALS SECTION -->
    <section class="container-lg d-flex flex-column justify-content-center align-items-center gap-4">
      <h2 class="title-page">Testimonios de <span>Ortodoncistas</span></h2>
      <div class="video-testimonials">
        <video controls controlsList="nodownload" [muted]="true" autoplay loop>
          <source
          src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/video/testimonios.mp4"
          type="video/mp4"
          loading="lazy"
          title="Video Hero"
          >
          <track default kind="captions" />
          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>
    </section>
  </div>
</main>
