import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.css'],
})

export class ImageComponent implements OnInit
{
  public imgUrl: string;

  constructor(private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void
  {
    var img = this.activatedRoute.snapshot.queryParams['path'];
    if (img.contains('aliwellstorageaccount.blob.core.windows.net'))
    {
      this.imgUrl = img;
    }
    else
    {
      this.imgUrl = '../../../assets/logo.png';
    }
  }
}
