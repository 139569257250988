import { Guard } from './Guard';

export class StringTools
{
  public static readonly emailBadChars: string = "!\"·$%&/()=?¿¡'¬€~#|º\\ª<>,;:-´ñÑç¨Ç{}[]`+^*áéíóúàèìòùýäëïöüÿâêîôûÁÉÍÓÚÝÀÈÌÒÙÄËÏÖÜÂÊÎÔÛ";
  public static readonly phoneBadChars: string = " abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZáéíóúàèìòùýäëïöüÿâêîôûÁÉÍÓÚÝÀÈÌÒÙÄËÏÖÜÂÊÎÔÛ@_!\"·$%&/=?¿¡'¬€~|º\\ª<>.,;:´ç¨Ç{}[]`^";
  public static readonly realNumberBadChars: string = " abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNÑOPQRSTUVWXYZáéíóúàèìòùýäëïöüÿâêîôûÁÉÍÓÚÝÀÈÌÒÙÄËÏÖÜÂÊÎÔÛ@_!\"·$%&/=?¿¡'¬€~|º\\ª<>,;:´ç¨Ç{}[]`^";
  public static readonly urlAllowedChars: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-._~:/?#[]@!$&'()*+,;=";

  public static isRealNumber(input: string): boolean
  {
    const floatingPointSeparatorCount: number = StringTools.stringCharCount(input, ".");
    if (floatingPointSeparatorCount >= 2)
    {
      return false;
    }
    if (StringTools.realNumberHasBadChars(input))
    {
      return false;
    }
    return true;
  }

  public static fixRealNumber(input: string): string
  {
    const pointCount: number = StringTools.stringCharCount(input, ".");
    if (pointCount >= 2)
    {
      input = StringTools.setCharCountTo1(input, ".");
    }
    input = StringTools.removeChars(input, StringTools.realNumberBadChars);
    return input;
  }

  public static charExistsInString(charvalue: string, inputString: string): boolean
  {
    for (let i = 0; i < inputString.length; i++)
    {
      if (inputString[i] === charvalue)
      {
        return true;
      }
    }
    return false;
  }

  public static removeChars(input: string, badChars: string): string
  {
    let strOutput: string = "";
    for (let i = 0; i < input.length; i++)
    {
      let badIndex: boolean = false;
      for (let k = 0; k < badChars.length; k++)
      {
        if (input[i] === badChars[k])
        {
          badIndex = true;
          break;
        }
      }
      if (!badIndex)
      {
        strOutput += input[i];
      }
    }
    return strOutput;
  }

  public static removeCharsIfNotFound(input: string, allowedChars: string): string
  {
    let strOutput: string = "";
    for (let i = 0; i < input.length; i++)
    {
      if (StringTools.charExistsInString(input[i], allowedChars))
      {
        strOutput += input[i];
      }
    }
    return strOutput;
  }

  public static removeCharsExceptNumbers(input: string): string
  {
    let strOutput: string = "";
    for (let i = 0; i < input.length; i++)
    {
      if (StringTools.isDigit(input[i]))
      {
        strOutput += input[i];
      }
    }
    return strOutput;
  }

  public static reduce(input: string, count: number): string
  {
    if (input.length < count)
    {
      return input;
    }
    let strOutput: string = "";
    for (let i = 0; i < count; i++)
    {
      strOutput += input[i];
    }
    return strOutput;
  }

  public static setCharCountTo1(input: string, charvalue: string): string
  {
    let strOutput: string = "";
    let once: boolean = false;
    for (let i = 0; i < input.length; i++)
    {
      if (input[i] === charvalue)
      {
        if (!once)
        {
          strOutput += input[i];
          once = true;
        }
      }
      else
      {
        strOutput += input[i];
      }
    }
    return strOutput;
  }

  public static realNumberHasBadChars(realNumber: string): boolean
  {
    for (let i = 0; i < realNumber.length; i++)
    {
      if (StringTools.charExistsInString(realNumber[i], StringTools.realNumberBadChars))
      {
        return true;
      }
    }
    return false;
  }

  public static emailHasBadChars(email: string): boolean
  {
    for (let i = 0; i < email.length; i++)
    {
      if (StringTools.charExistsInString(email[i], StringTools.emailBadChars))
      {
        return true;
      }
    }
    return false;
  }

  public static isEmail(email: string): any
  {
    if (email.length === 0)
    {
      return {"ok": false, "error":"empty"};
    }
    if (StringTools.stringCharCount(email, "@") === 0)
    {
      return {"ok": false, "error":"atismissing"};
    }
    if (email[email.length-1] === "@")
    {
      return {"ok": false, "error":"incomplete"};
    }
    return {"ok": true, "error": null};
  }

  public static creditCardHasBadChars(creditCardNumber: string): boolean
  {
    return !StringTools.hasOnlyNumbers(creditCardNumber);
  }

  // is it a credit card value number?
  public static isCreditCard(value: string): any
  {
    if (value.length === 0)
    {
      return {"ok": false, "error": "empty"};
    }
    if (value.length < 16)
    {
      return {"ok": false, "error": "incomplete"};
    }
    if (value.length > 16)
    {
      return {"ok": false, "error": "toomuchchars"};
    }
    return {"ok": true, "error": null};
  }

  public static twoDigitsMonthHasBadChars(month: string): boolean
  {
    return !StringTools.hasOnlyNumbers(month);
  }

  public static isTwoDigitsMonth(value: string): any
  {
    if (value.length === 0)
    {
      return {"ok": false, "error": "empty"};
    }
    if (value.length < 2)
    {
      return {"ok": false, "error": "incomplete"};
    }
    if (value.length > 2)
    {
      return {"ok": false, "error": "toomuchchars"};
    }
    const month: number = parseInt(value);
    if (month < 1 || month > 12)
    {
      return {"ok": false, "error": "invalidmonthvalue"};
    }
    return {"ok": true, "error": null};
  }

  public static twoDigitsYearHasBadChars(year: string): boolean
  {
    return !StringTools.hasOnlyNumbers(year);
  }

  public static isTwoDigitsYear(value: string): any
  {
    if (value.length === 0)
    {
      return {"ok": false, "error": "empty"};
    }
    if (value.length < 2)
    {
      return {"ok": false, "error": "incomplete"};
    }
    if (value.length > 2)
    {
      return {"ok": false, "error": "toomuchchars"};
    }
    return {"ok": true, "error": null};
  }

  public static cvvHasBadChars(cvv: string): boolean
  {
    return !StringTools.hasOnlyNumbers(cvv);
  }

  public static isCvv(value: string): any
  {
    if (value.length === 0)
    {
      return {"ok": false, "error": "empty"};
    }
    if (value.length < 3)
    {
      return {"ok": false, "error": "incomplete"};
    }
    if (value.length > 5)
    {
      return {"ok": false, "error": "toomuchchars"};
    }
    return {"ok": true, "error": null};
  }

  public static phoneHasBadChars(phone: string): boolean
  {
    for (let i = 0; i < phone.length; i++)
    {
      if (StringTools.charExistsInString(phone[i], StringTools.phoneBadChars))
      {
        return true;
      }
    }
    return false;
  }

  public static urlHasBadChars(url: string): boolean
  {
    for (let i = 0; i < url.length; i++)
    {
      if (!StringTools.charExistsInString(url[i], StringTools.urlAllowedChars))
      {
        return true;
      }
    }
    return false;
  }

  // is digit?
  public static isDigit(value: string): boolean
  {
    for (let i = 0; i <= 9; i++)
    {
      if (i.toString() === value)
      {
        return true;
      }
    }
    return false;
  }

  // does it have only numbers?
  public static hasOnlyNumbers(content: string): boolean
  {
    for (let i = 0; i < content.length; i++)
    {
      if (!StringTools.isDigit(content[i]))
      {
        return false;
      }
    }
    return true;
  }

  public static isUrl(value: string): boolean
  {
    for (let i = 0; i < value.length; i++)
    {
      if (!StringTools.charExistsInString(value[i], StringTools.urlAllowedChars))
      {
        return false;
      }
    }
    return true;
  }

  // gets the count of a char in a string
  public static stringCharCount(content: string, value: string): number
  {
    let counter: number = 0;
    for (let i = 0; i < content.length; i++)
    {
      if (content[i] === value)
      {
        counter += 1;
      }
    }
    return counter;
  }

  public static isUndefinedOrNull(o: any): boolean
  {
    /* if (angular.isUndefined(o) || o === null)
         {
             return true;
         }
         else
         {
             return false;
         }*/

    return true;
  }

  public static notBlankOrNull(s: string): boolean
  {
    return !StringTools.isBlankOrNull(s);
  }

  public static isBlankOrNull(s: string): boolean
  {
    let isString = typeof s === "string";

    if (s == null || (isString && s.trim().length === 0)) { return true; }
    else { return false; }
  }

  /**
     * Return the original value - which might be a class or property name and splits it into separate words
     */
  public static getHeadingFromFieldName(fieldName: string)
  {
    if (StringTools.isBlankOrNull(fieldName)) { return ""; }

    let output = fieldName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([a-z])/g, ' $1$2')
      .replace(/([a-z])([0-9])/g, '$1 $2')
      .replace(/([A-Z])([0-9])/g, '$1 $2')
      .replace(/\s/g, "")
      .trim();

    return output;
  }

  /**
     * Return the original value - which might be a class or property name and splits it into separate words,
     *  separated by "-" and all lower case to be used as an Angular component name.
     *  Example: Converts MyComponentName to my-component-name
     */
  public static getComponentNameFromFieldName(fieldName: string)
  {
    if (StringTools.isBlankOrNull(fieldName)) { return ""; }

    let output = StringTools.getHeadingFromFieldName(fieldName)
      .replace(/\s/g, "-")
      .toLowerCase();

    return output;
  }

  public static toCamelCase(name: string): string
  {
    return name[0].toLowerCase() + name.slice(1);
  }

  public static initialToUpper(name: string): string
  {
    return name[0].toUpperCase() + name.slice(1);
  }

  public static initialToLower(name: string): string
  {
    return name[0].toLowerCase() + name.slice(1);
  }

  public static createRandomString(length: number)
  {
    Guard.failIfTrue(length > 20, "length cannot be > 20");
    return Math.random().toString(36).slice(-length);
  }

  public static padNumber(numberToPad: number, minDigits: number): string
  {
    return numberToPad.toLocaleString('en-US',
      {
        style: 'decimal',
        minimumIntegerDigits: minDigits,
        useGrouping: false
      });
  }

  public static replaceAll(str: string, search: string, replace: string): string
  {
    return str.split(search).join(replace);
  }

  public static splice(str: string, index: number, deleteCount: number, stringToInsert: string): string
  {
    let charArray = str.split('');
    charArray.splice(index, deleteCount, stringToInsert);
    return charArray.join('');
  }

  public static formatNumber(input: string, numberOfDecimalPlaces: number): string
  {
    return input;
  }
}
