import { StringTools } from '../util/StringTools';


export class CRUDEvents
{
  public get Add() { return "{0}:Add".format(this.entityConfig.entityName); }
  public get Saved() { return "{0}:Saved".format(this.entityConfig.entityName); }
  public get GetById() { return "{0}:GetById".format(this.entityConfig.entityName); }
  public get Cancelled() { return "{0}:Cancelled".format(this.entityConfig.entityName); }
  public get Deleted() { return "{0}:Deleted".format(this.entityConfig.entityName); }
  public get Selected() { return "{0}:Selected".format(this.entityConfig.entityName); }

  constructor(private entityConfig: EntityConfig) { }
}

export class EntityConfig
{
  public entityName: string;
  public entityPluralName: string;
  private _entityDisplayName: string;
  private _entityPluralDisplayName: string;

  public apiUrl: string;

  public canRead: boolean;
  public canEdit: boolean;
  public readRoles: Array<string>;
  public editRoles: Array<string>;

  // Properties for Domain Specific Entities
  public domainName: string;
  public domainContextPropertyName: string;
  public domainContextId: number;

  public supportsSoftDeletes: boolean;
  public isDomainSpecificEntity: boolean;
  public isVersionableEntity: boolean;
  public isDraftableEntity: boolean;

  public get entityDisplayName(): string
  {
    return StringTools.getHeadingFromFieldName(this.entityName);
  }

  public set entityDisplayName(entityDisplayName: string)
  {
    this._entityDisplayName = entityDisplayName;
  }

  public get entityPluralDisplayName(): string
  {
    return StringTools.getHeadingFromFieldName(this.entityPluralName);
  }

  public set entityPluralDisplayName(entityPluralDisplayName: string)
  {
    this._entityPluralDisplayName = entityPluralDisplayName;
  }
}
