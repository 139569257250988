import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-custom-input-number',
  templateUrl: './custom-input-number.component.html',
  styleUrls: ['./custom-input-number.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CustomInputNumberComponent implements OnInit
{
  @Input() public maxValue: number = 9999;
  @Input() public minValue: number = 0;
  @Input() public disabled: boolean = false;
  @Output() public valueChanged = new EventEmitter<number>();

  @Input() public counter: number = this.minValue;

  constructor() { }

  ngOnInit(): void { }

  public increment(): void
  {
    if (this.counter === this.maxValue)
      return;

    this.counter += 1;
    this.emitValueChanged();
  }

  public decrement(): void
  {
    if (this.counter === this.minValue)
      return;

    this.counter -= 1;
    this.emitValueChanged();
  }

  public reset(): void
  {
    this.counter = this.minValue;
    this.emitValueChanged();
  }

  public emitValueChanged(): void
  {
    this.valueChanged.emit(this.counter);
  }

}
