import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safe',
  pure: false
})

export class SafePipe implements PipeTransform
{
  constructor(private domSanitizer: DomSanitizer) { }

  transform(value: string, type: string): any
  {
    if (value != null && value != undefined && value != "")
    {
      switch (type)
      {
        case 'html': return this.domSanitizer.bypassSecurityTrustHtml(value);
        case 'style': return this.domSanitizer.bypassSecurityTrustStyle(value);
        case 'script': return this.domSanitizer.bypassSecurityTrustScript(value);
        case 'url': return this.domSanitizer.bypassSecurityTrustUrl(value);
        case 'resourceUrl': return this.domSanitizer.bypassSecurityTrustResourceUrl(value);
        default: return "";
      }
    }
    return "";
  }
}
