import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeModule } from './home/home.module';
import { MainComponent } from './layouts/main/main.component';
import { AppMaterialModule } from './layouts/material/app.material.module';
import { NavBarResponsiveComponent } from './layouts/navbar/navbar.component';
import { UserAccessModule } from './user-access/user-access.module';
import { ShareModule } from './_appCommon/share.module';
import { IndexComponent } from './layouts/pages/index/index.component';
import { FaqComponent } from './layouts/pages/faq/faq.component';
import { FoundOrthodontistComponent } from './layouts/pages/found-orthodontist/found-orthodontist.component';
import { ImOrthodontistComponent } from './layouts/pages/im-orthodontist/im-orthodontist.component';
import { AppShareModule } from 'src/shared/app.module';
import { ResetPasswordComponent } from './layouts/pages/reset-password/reset-password.component';
import { AuthenticationInterceptor } from './_appCommon/interceptors/authentication.interceptor';
import { MAT_DATE_LOCALE } from "@angular/material/core";
import { LoadingInterceptor } from 'src/shared/interceptors/loading.interceptor';
import { LoadingGeneralModule } from 'src/shared/components/loading-general/loading-general.module';

@NgModule({
  declarations: [
    AppComponent,
    NavBarResponsiveComponent,
    MainComponent,
    IndexComponent,
    FoundOrthodontistComponent,
    ImOrthodontistComponent,
    FaqComponent,
    ResetPasswordComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    UserAccessModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ShareModule, //don't remove sharemdule from here,
    AppShareModule,
    HomeModule,
    BrowserAnimationsModule,
    AppMaterialModule,
    LoadingGeneralModule
  ],
  providers: [
  { provide: HTTP_INTERCEPTORS, useClass: LoadingInterceptor, multi: true },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: AuthenticationInterceptor,
    multi: true
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: 'es-MX' // format date as "DD/MM/YYYY"
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
