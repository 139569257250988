<app-dialog [enableCloseDialogButton]="false" [show]="showModal" [preventCloseFromBackDrop]="true" width="40%">
  <form [formGroup]="changePasswordForm" (keydown.enter)="$event.preventDefault()" >
    <div class="d-flex mx-auto flex-column align-items-center" style="width:70%">
      <div class="mt-4 mb-4 w-100 w-sm-50">
        <img src="../../../assets/logo.png" alt="Logo Aliwell">
      </div>
      <div class="mb-3">
        <p class="fw-bold text-center mb-4" style="font-size:24px;">Establece una nueva contraseña</p>
      </div>
      <div class="w-100">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Contraseña</mat-label>
          <mat-icon style="color: #a2a5ae" matPrefix>lock</mat-icon>
          <input formControlName="newPassword" name="newPassword" id="newPassword" matInput placeholder="Contraseña" [type]="hide ? 'password':'text'" required (input)="validatePassword($event.target.value)" />
          <mat-error *ngIf="changePasswordForm.get('newPassword').hasError('required')">Completa este campo.</mat-error>
          <mat-error>{{newPasswordError}}</mat-error>
          <mat-icon style="color: #0D565E;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
      <div class="w-100 mb-5">
        <mat-form-field class="w-100" appearance="fill">
          <mat-label>Confimar contraseña</mat-label>
          <mat-icon style="color: #a2a5ae" matPrefix>lock</mat-icon>
          <input formControlName="confirmNewPassword" name="confirmNewPassword" id="confirmNewPassword" matInput placeholder="Confirmar contraseña" [type]="hide ? 'password':'text'" required />
          <mat-error *ngIf="changePasswordForm.get('confirmNewPassword').hasError('required')">Completa este campo.</mat-error>
          <mat-error *ngIf="changePasswordForm.get('confirmNewPassword').value == changePasswordForm.get('confirmNewPassword').value">La contrase&ntilde;a debe coincidir.</mat-error>
          <mat-icon style="color: #0D565E;" matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        </mat-form-field>
      </div>
      <div style="width:100%;" class="mb-5 mt-2">
        <button (click)="changePassword()" style="width: 100%; white-space: normal;" mat-button class="button primary-button">Restablecer contraseña</button>
      </div>
    </div>
  </form>
</app-dialog>
