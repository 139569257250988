import { Component } from "@angular/core";

@Component({
  selector: 'app-background',
  template: `
  <div class="w-100 h-100">
    <svg class="w-75 position-absolute" style="top: -3rem; right: 0" viewBox="0 0 1393 201" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(-539.000000, 0.500000)" stroke="#979797">
          <path d="M539.5 0 609.974359 133.974359 1894.19703 134.034006 1930.97368 199.964286"></path>
        </g>
      </g>
    </svg>
    <svg class="w-25 position-absolute" style="top: 15% !important; right: 0;" viewBox="0 0 406 39" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(-1531.000000, -277.500000)" stroke="#979797">
          <path transform="translate(1734.250000, 297.089499) scale(-1, 1) translate(-1734.250000, -297.089499) " d="M1531.5 278.178999 1637.00765 278.178999 1672.95976 316 1937 316"></path>
        </g>
      </g>
    </svg>
    <svg class="w-100 position-absolute" style="top: 22%; left: 0;" viewBox="0 0 1932 428" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(0.000000, -316.500000)" stroke="#979797">
          <path transform="translate(966.000000, 530.500000) scale(-1, 1) translate(-966.000000, -530.500000) " d="M1932 317 1887.5 317 1841.5 360 1691.5 360.000325 1376.5 674 283.5 674 212.5 744 0 744"></path>
        </g>
      </g>
    </svg>
    <svg class="w-100 position-absolute" style="top: 25%; left: 0;" viewBox="0 0 1932 430" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(0.000000, -376.500000)" stroke="#17BAA3">
          <path transform="translate(965.750000, 591.526429) scale(-1, 1) translate(-965.750000, -591.526429) " d="M1931.5 377.989203 1887 378 1841 421 1691 421 1376 735 283 735 212 805 0 805.063656"></path>
        </g>
      </g>
    </svg>
    <svg class="w-25 position-absolute top-50 left-0" viewBox="0 0 690 21" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(-7.000000, -840.500000)" stroke="#979797">
          <path transform="translate(352.000000, 851.000000) scale(1, -1) translate(-352.000000, -851.000000) " d="M7.5 861 465.5 861 487.5 841 696.5 841"></path>
        </g>
      </g>
    </svg>
    <svg class="w-100 position-absolute" style="top: 65%; left: 0; transform: scale(-1);" viewBox="0 0 1932 428" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(0.000000, -316.500000)" stroke="#979797">
          <path transform="translate(966.000000, 530.500000) scale(-1, 1) translate(-966.000000, -530.500000) " d="M1932 317 1887.5 317 1841.5 360 1691.5 360.000325 1376.5 674 283.5 674 212.5 744 0 744"></path>
        </g>
      </g>
    </svg>
    <svg class="w-100 position-absolute" style="top: 68%; left: 0; transform: scale(-1);" viewBox="0 0 1932 430" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
      <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" opacity="0.717023983" stroke-linecap="square">
        <g transform="translate(0.000000, -376.500000)" stroke="#17BAA3">
          <path transform="translate(965.750000, 591.526429) scale(-1, 1) translate(-965.750000, -591.526429) " d="M1931.5 377.989203 1887 378 1841 421 1691 421 1376 735 283 735 212 805 0 805.063656"></path>
        </g>
      </g>
    </svg>
  </div>`,
  styles: [`
    svg {
      opacity: 60%;
      transition: 500ms ease-in-out;
      z-index: 0;
    }

    svg:hover {
      transform: translateY(1.25rem);
    }
  `]
})
export class BackgroundComponent { }
