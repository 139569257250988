import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { EntityConfig } from '../_appCommon/base/EntityConfig';
import { EntityDataServiceBase } from '../_appCommon/base/EntityDataServiceBase';
import { FileApplicationType, FileExtension, FileWeirdExtension, ImageExtension, ImageType } from '../_appCommon/base/AppInterfaceAndBaseClasses';

@Injectable()
export class UserDataRequestDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, 'userDataRequest');
  }

  protected loadEntityConfig(): void
  {
    this.entityConfig = new EntityConfig();

    this.entityConfig.entityName = 'userDataRequest';
    this.entityConfig.apiUrl = 'api/userDataRequest';
  }

  public saveUserRequestData(personalFormData: any): Observable<object>
  {
    // create multipart form for file
    const headers = new HttpHeaders().append('Content-Disposition', 'mulipart/form-data');

    const formData = this.getRequestFormData(personalFormData);

    personalFormData.controls.images.value.map(x =>
    {
      let name = this.setImageName(x.file.type, x.file.name);
      formData.append('images', x.file, name);
    });

    let observable = this.http.post(this.entityConfig.apiUrl, formData, { headers: headers }).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  private getRequestFormData(personalFormData: any): FormData
  {
    const form = new FormData();

    const formData = personalFormData.value;

    Object.keys(formData).forEach((key) =>
    {
      form.append(key, formData[key]);
    });

    return form;
  }

  private nameGenerator(fileName: string): string
  {
    //Create a unique name!
    let date = new Date();
    //FileNameDayMontYearHourMinuteSecondMilliseconds
    let fileNameTemp = date.toISOString();
    fileNameTemp = fileNameTemp.replaceAll("-", "");
    fileNameTemp = fileNameTemp.replaceAll("T", "");
    fileNameTemp = fileNameTemp.replaceAll(":", "");
    fileNameTemp = fileNameTemp.replaceAll(".", "");
    fileNameTemp = fileNameTemp.replaceAll("Z", "");
    //Delete file extension
    let index = fileName.lastIndexOf(".");
    let fileNameModified = fileName.slice(0, index);
    //Erase any possible "_"
    fileNameModified = fileNameModified.replaceAll("_", "");
    return fileNameTemp + "_" + fileNameModified;
  }

  private setImageName(type: any, fileOriginalName: any): string
  {

    let name = this.nameGenerator(fileOriginalName);

    switch (type)
    {
      case ImageType.JPG:
        name += ImageExtension.JPG;
        break;
      case ImageType.JPEG:
        name += ImageExtension.JPEG;
        break;
      case ImageType.PNG:
        name += ImageExtension.PNG;
        break;
      case ImageType.GIF:
        name += ImageExtension.GIF;
        break;
      case ImageType.TIF:
        name += ImageExtension.TIF;
        break;
      case ImageType.BMP:
        name += ImageExtension.BMP;
        break;
      case FileApplicationType.PDF:
        name += FileExtension.PDF;
        break;
      case FileApplicationType.STL:
        name += FileWeirdExtension;
        break;
      case FileApplicationType.TXT:
        name += FileExtension.TXT;
        break;
      case FileApplicationType.ZIP:
        name += FileExtension.ZIP;
        break;
      case FileApplicationType.ZIP7:
        name += FileExtension.ZIP7;
        break;
      case FileApplicationType.RAR:
        name += FileExtension.RAR;
        break;
      case FileApplicationType.WORD:
        name += FileExtension.WORD;
        break;
      case FileApplicationType.POWERPOINT:
        name += FileExtension.POWERPOINT;
        break;
      case FileApplicationType.EXCEL:
        name += FileExtension.EXCEL;
        break;
      case '':
        name += FileWeirdExtension.STL;
        break;
    }
    return name;
  }
}

