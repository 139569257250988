<header>
  <div>
		<video controls controlsList="nodownload" [muted]="true" autoplay>
			<source
				src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/video/Aliwell_Web.mp4"
				type="video/mp4"
				title="Video Hero"
			>
			<track default kind="captions" />
			Sorry, your browser doesn't support embedded videos.
		</video>
	</div>
</header>

<main class="d-flex flex-column">
  <!-- LIGHT SECTION -->
  <div class="bg-light-section bg-vector-section position-relative overflow-hidden">
    <!-- INTRODUCTION SECTION -->
    <app-background></app-background>
    <section class="container-lg row row-cols-1 row-cols-md-2 gy-5 px-lg-0">
      <div class="col d-flex flex-column justify-content-center gap-4 ps-md-0">
        <h1 class="title-page">¿Qu&eacute; es <span>Aliwell<sup>&#174;</sup></span>?</h1>
        <p class="content-page">Empresa mexicana creada por ortodoncistas, otorgando alta calidad para el tratamiento de problemas de mal oclusión utilizando alineadores transparentes de alta tecnolog&iacute;a</p>
      </div>
      <div class="col pe-md-0" appScrollAnimation="slideRightToLeft">
        <img src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/d2.png" alt="">
      </div>
    </section>

    <!-- TECH VIDEO SECTION -->
    <section class="container-lg row row-cols-1 row-cols-md-2 gy-5 px-lg-0 d-flex align-items-center">
      <div class="col-lg-5 order-2 order-md-1 text-center ps-md-0">
        <div class="w-100">
          <video class="tech-video" controls controlsList="nodownload" [muted]="true" autoplay loop>
            <source
            src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-images/videos/Digital%20a%20real_2.mp4"
            type="video/mp4"
            loading="lazy"
            title="Video Hero"
            >
            <track default kind="captions" />
            Sorry, your browser doesn't support embedded videos.
          </video>
        </div>
      </div>
      <div class="col-lg-7 order-1 order-md-2 d-flex flex-column justify-content-center gap-4 pe-md-0" style="height: fit-content;" appScrollAnimation="slideTopToBottom">
        <h2 class="title-page">Contamos con la más <span>alta tecnología</span> para tus pacientes</h2>
        <p class="content-page">Podrás visualizar la sonrisa final desde antes de iniciar el tratamiento.</p>
        <button class="button primary-button text-start d-none d-md-block" (click)="onHandleButtonNavigate('soy-ortodoncista')" style="width: fit-content;">Conoce más <i class="fa fa-angle-right"></i></button>
      </div>
      <button class="button primary-button text-start d-md-none order-2 mx-auto" (click)="onHandleButtonNavigate('soy-ortodoncista')" style="width: fit-content;">Conoce más <i class="fa fa-angle-right"></i></button>
    </section>
  </div>

  <!-- DARK SECTION -->
  <div class="bg-dark-section">
    <!-- BEFORE AND AFTER TESTIMONIALS SECTION -->
    <section class="container-lg d-flex flex-column gap-4">
      <h2 class="title-page text-center">Descubre testimonios de pacientes de <span>antes y después</span></h2>
      <div class="swiper-container">
        <app-before-after-slider></app-before-after-slider>
      </div>
    </section>
  </div>

  <div class="bg-light-section bg-vector-section">
    <!-- STEPS SECTION -->
    <section class="container-lg row row-cols-1 px-lg-0">
      <div class="col-md-5 d-flex flex-column align-items-center align-items-md-start justify-content-center gap-3  ps-md-0 mb-4 mb-md-0"  appScrollAnimation="scaleContent">
        <h2 class="title-page">P&iacute;delo en tan solo 3 <span>simples pasos</span></h2>
        <button class="button primary-button" (click)="onHandleButtonNavigate('encuentra-ortodoncista')">Haz tu cita</button>
      </div>
      <div class="steps-container position-relative col-md-7 d-flex flex-column gap-4 pe-md-0">
        <div class="step-card d-flex justify-content-start align-items-center gap-3 gap-md-4" *ngFor="let item of steps; index as i;">
          <div class="rounded-circle overflow-hidden">
            <img src="{{item.imgUrl}}" alt="{{item.title}}">
          </div>
          <div class="step-content">
            <h4>{{i+1}}. {{item.title}}</h4>
            <p class="fw-light">{{item.content}}</p>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
