import { Component, Input, OnInit } from "@angular/core";
import { CONTACTDATA, SITEMAPURLS } from "src/shared/constants";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})

export class FooterComponent implements OnInit
{
  @Input() hasSitemap:boolean = false;

  public sitemapUrls = SITEMAPURLS;
  public contactData = CONTACTDATA;
  todayDate : Date;

  constructor() { 
    this.todayDate = new Date();
  }

  ngOnInit(): void { }

}
