import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs";
import { share } from 'rxjs/operators';
import { UrlQueryDef } from 'src/app/_appCommon/base/UrlQueryDef';
import { EntityConfig } from '../../_appCommon/base/EntityConfig';
import { EntityDataServiceBase } from '../../_appCommon/base/EntityDataServiceBase';

@Injectable()
export class CustomerAccessDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, "customerAccess");
  }

  protected loadEntityConfig(): void
  {
    // Create Entity Config
    this.entityConfig = new EntityConfig();

    this.entityConfig.canEdit = true;
    this.entityConfig.canRead = true;
    this.entityConfig.entityName = "customerAccess";
    this.entityConfig.entityDisplayName = "customerAccess";
    this.entityConfig.apiUrl = "api/customerAccess";
  }

  public createAccount(createAccountDto: any): Observable<{}>
  {
    let queryDef = new UrlQueryDef();

    var observable = this.http.post(this.createUrl("createAccount"), createAccountDto, queryDef.getRequestConfig(true)).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

}
