<div class="wrapper-container">
  <div class="wrapper-slider" (mousemove)="onHandleMouseMove($event)" (mouseup)="onHandleMouseUp()" #sliderWrapper>
    <img src={{imgBefore.url}} alt="{{imgBefore.alt}}">
    <img src={{imgAfter.url}} alt="{{imgAfter.alt}}" [ngStyle]="{'clip-path': getClipPath()}">

    <div class="divider-container" (mousedown)="onHandleMouseDown()" (touchmove)="onHandleTouchMove($event)" [ngStyle]="{'right': getPosition()}">
      <div class="divider">
        <div class="divider-line"></div>
        <div class="divider-button">
          <i class="fa fa-angle-left"></i>
          <i class="fa fa-angle-right"></i>
        </div>
      </div>
    </div>
  </div>
</div>
