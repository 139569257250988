import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';

import { FileHandle } from 'src/app/_appCommon/base/AppInterfaceAndBaseClasses';
import { STATES } from '../../../_appCommon/base/Constants';
import { UserDataRequestDataService } from '../../../_dataservices/UserDataRequestDataService';

@Component({
  selector: 'app-found-orthodontist',
  templateUrl: './found-orthodontist.component.html',
  styleUrls: ['./found-orthodontist.component.css'],
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS,
    useValue: {displayDefaultIndicatorType: false},
  }, UserDataRequestDataService]
})

export class FoundOrthodontistComponent implements OnInit
{
  public personalDataForm: FormGroup;
  public photosForm: FormGroup;

  @ViewChild('stepper', {read:MatStepper}) private stepper: MatStepper;
  @ViewChild('filesContainer', { static: true }) filesContainer: ElementRef;

  public States = STATES;
  public selectedFiles: FileHandle[] = [];
  public editable: boolean = true;

  ngOnInit()
  {
    this.initializeForms();
  }

  constructor(
    private formBuilder: FormBuilder,
    private renderer: Renderer2,
    private userDataRequestDataService: UserDataRequestDataService)
  {

  }

  private initializeForms(): void
  {
    this.personalDataForm = this.formBuilder.group({
      fullName: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      zipcode: ['', Validators.required],
      state: ['',  Validators.required],
      comments: ['', ],
      images: [null, ],
    });
  }

  public getErrorMessage(controlName: any)
  {
    if (this.personalDataForm)
    {
      var control = this.personalDataForm.get(controlName);

      if (control?.hasError('required'))
      {
        return 'Completa este campo';
      }
      if (control?.hasError('email'))
      {
        return 'Ingresa un email válido';
      }
      if (control?.hasError('pattern'))
      {
        return 'Ingresa un texto válido';
      }
      if (control?.hasError('invalid'))
      {
        return 'Dato inválido';
      }
      if (!control?.hasError('validateEqual'))
      {
        if (controlName == 'email')
          return 'Los correos deben coincidir';
        if (controlName == 'phone')
          return 'Los teléfonos deben coincidir';
      }
      if (control?.hasError('minlength'))
      {
        return 'Longitud mínima de ' + control?.errors.minlength.requiredLength + ' caracteres';
      }
      if (control?.hasError('maxlength'))
      {
        return 'Longitud máxima de ' + control?.errors.maxlength.requiredLength + ' caracteres';
      }
      if (control?.hasError('existUser'))
      {
        return 'Este correo ya se encuentra registrado';
      }
      if (control?.hasError('exist'))
      {
        return 'Este nombre ya se encuentra registrado';
      }
      if (control?.hasError('invalidCredentials'))
      {
        return 'Credenciales inválidas';
      }
      if (controlName == 'invitationCode')
      {
        if (control?.hasError('pattern'))
        {
          return 'El código debe estar compuesto por números y letras';
        }
        if (control?.hasError('notExist'))
        {
          return 'El código de invitación es inválido';
        }
      }
    }

    return '';
  }

  public onSendRequest(): void
  {
    this.personalDataForm.patchValue({
      images: this.selectedFiles
    });

    this.userDataRequestDataService.saveUserRequestData(this.personalDataForm)
      .subscribe(response =>
      {
        this.editable = false;
        this.stepper.next();
      });
  }

  public onSelectedFiles(files)
  {
    this.selectedFiles = [...this.selectedFiles, ...files];
  }

  public onDeleteFile(id: number)
  {
    this.selectedFiles.splice(id, 1);
    this.renderer.removeChild(this.filesContainer, document.querySelector(`#imgUpload-{id}`));
  }

  public scrollToIdRef(element): void
  {
    element.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"});
  }

  public onHandleNewRequest():void
  {
    this.personalDataForm.reset();

    this.selectedFiles.clear();

    this.initializeForms();
    window.location.reload();
  }
}
