import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { EntityConfig } from '../_appCommon/base/EntityConfig';
import { EntityDataServiceBase } from '../_appCommon/base/EntityDataServiceBase';
import { UrlQueryDef } from '../_appCommon/base/UrlQueryDef';

@Injectable()
export class IncompleteUsersDataService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, 'IncompleteUsers');
  }

  protected loadEntityConfig(): void
  {
    this.entityConfig = new EntityConfig();

    this.entityConfig.entityDisplayName = 'IncompleteUsers';
    this.entityConfig.apiUrl = 'api/IncompleteUsers';
  }

  public deleteIncompleteUser(body)
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.put(this.entityConfig.apiUrl + "/deleteIncompleteUser", body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }
}

