import { Component, Injector, OnInit } from "@angular/core";
import { BroadcastManager } from "src/app/_appCommon/base/BroadcastManager";
import { AppEventNames } from "src/app/_appCommon/base/Constants";
import { AppClientContext } from "../../_appCommon/base/AppClientContext";
import { ControllerBase } from "../../_appCommon/base/ControllerBase";
import { SystemServicesDataService } from "../../_dataservices/security/SystemServicesDataService";
import { ECOMMERCE_USER_TOKEN } from '../../_appCommon/base/Constants';
import { ActivatedRoute } from "@angular/router";



@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  providers: [SystemServicesDataService]
})

export class MainComponent extends ControllerBase implements OnInit
{
  public isUserAccessShow: boolean = false;
  public showLogin: boolean = true;
  public isUserLoggedIn: boolean = false;
  public isShowPasswordEmailReset: boolean = false;
  public isShowPasswordReset: boolean = false;
  public passwordKey: string = "";
  public loginId: string = "";
  public currentPassword: string = "";
  public isShowCRUD: boolean = false;
  public byPassEmailPasswordReset: boolean = false;

  constructor(
    private injector: Injector,
    private systemServicesDataService: SystemServicesDataService,
    protected route: ActivatedRoute)
  {
    super();
  }

  ngOnInit()
  {
    if (this.getUserLoginFromCache())
      this.checkUserLogin();

    BroadcastManager.Instance.on(AppEventNames.OnShowUserLogin)
      .subscribe(([e]) =>
      {
        this.showLogin = true;
        if (e[0])
          this.isUserAccessShow = true;
        else
          this.isUserAccessShow = false;
      });

    BroadcastManager.Instance.on(AppEventNames.OnShowUserSignUp)
      .subscribe(([e]) =>
      {
        this.showLogin = false;
        if (e[0])
          this.isUserAccessShow = true;
        else
          this.isUserAccessShow = false;
      });

    BroadcastManager.Instance.on(AppEventNames.OnboardingEmailPasswordChange)
      .subscribe(([e]) =>
      {
        if (e[0])
        {
          this.isShowPasswordEmailReset = true;
        }
        else
        {
          this.isShowPasswordEmailReset = false;
        }
      });

    BroadcastManager.Instance.on(AppEventNames.OnboardingByPassEmailPasswordChange)
      .subscribe(([e]) =>
      {
        if (e[0])
        {
          this.byPassEmailPasswordReset = true;
        }
        else
        {
          this.byPassEmailPasswordReset = false;
        }
      });

    BroadcastManager.Instance.on(AppEventNames.OnboardingPasswordChange)
      .subscribe(([e]) =>
      {
        if (e[0].show)
        {
          this.passwordKey = e[0].key;
          this.loginId = e[0].loginId;
          this.isShowPasswordReset = true;
        }
        else
        {
          this.isShowPasswordReset = false;
        }
      });

    BroadcastManager.Instance.on(AppEventNames.OnboardingCRUDData)
      .subscribe(([e]) =>
      {
        if (e[0].show)
        {
          this.isShowCRUD = true;
          this.loginId = e[0].loginId;
          this.currentPassword = e[0].password;
        }
        else
        {
          this.isShowCRUD = false;
        }
      });
    this.getPurchasedCode();
  }

  private getPurchasedCode()
  {
    let queryParams = this.route.snapshot.queryParams;
    let result = queryParams['PB'];
    if (result == null)
    {
      this.byPassEmailPasswordReset = false;
    }
    else
    {
      this.byPassEmailPasswordReset = result == 'true' ? true : false;
    }

  }
  private checkUserLogin(): void
  {
    if (this.getUserLoginFromCache())
    {
      // Call API - checkUserInCache - confirm that they are in SERVER cache
      this.systemServicesDataService.checkUserInCache()
        .subscribe((response: boolean) =>
        {
          if (response)
          {
            //"User was found in cache, attempt to log in...";
            this.getStartupInfo();
            this.isUserLoggedIn = true;
          }
          else
          {
            this.forceLogout();
          }
        }, error =>
        {
          this.forceLogout();
        });
    }
  }

  private getStartupInfo()
  {
    this.systemServicesDataService.getStartupInfo()
      .subscribe((startupInfo) =>
      {
        this.onApplicationStartup(startupInfo);
      }, error =>
      {
        if (error.status == 401)
        {
          this.forceLogout();
          this.setDangerAlert('Usuario no autorizado');
        }
      });
  }

  private onApplicationStartup(startupInfo: any)
  {
    AppClientContext.Instance.initialize(startupInfo);

    // Save a reference to the angular injector to the client context
    AppClientContext.Instance.setInjector(this.injector);

    BroadcastManager.Instance.broadcast(AppEventNames.OnCheckUserLoggedIn, true);
  }

  public forceLogout(): void
  {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    // Remove Token
    AppClientContext.Instance.headerValues.delete(ECOMMERCE_USER_TOKEN);

    // Clear current user
    AppClientContext.Instance.user = null;
  }

  private getUserLoginFromCache(): boolean
  {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    return this.setSecurityToken(user);
  }

  private setSecurityToken(user: any): boolean
  {
    if (user && user.cacheToken)
    {
      // Remove Token
      AppClientContext.Instance.headerValues.delete(ECOMMERCE_USER_TOKEN);

      // Append Token
      AppClientContext.Instance.headerValues.set(ECOMMERCE_USER_TOKEN, user.cacheToken);
      return true;
    }
    return false;
  }
}
