import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";

import { AppClientContext } from '../../_appCommon/base/AppClientContext';
import { AppEventNames } from '../../_appCommon/base/Constants';
import { AuthenticationService } from '../../_dataservices/security/AuthenticationService';
import { BroadcastManager } from "src/app/_appCommon/base/BroadcastManager";
import { SITEMAPURLS } from "src/shared/constants";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.css"],
  providers: [AuthenticationService]
})

export class NavBarResponsiveComponent implements OnInit
{
  public isShowUserDropDown = false;
  public isUserLoggedIn = false;
  public sitemapUrls = SITEMAPURLS;
  public userName = "";

  ngOnInit(): void
  {
    BroadcastManager.Instance.on(AppEventNames.OnCheckUserLoggedIn)
      .subscribe(([e]) =>
      {
        if (e[0])
          this.initializeUser();
      });
  }

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authenticationService: AuthenticationService
  ) { }

  private initializeUser():void
  {
    const user = AppClientContext.Instance.user;
    if (user)
    {
      this.isUserLoggedIn = true;
      this.userName = user.firstName;
    }
  }

  public onHandleClickLogin(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnShowUserLogin, true);
  }

  public onHandleClickLogout(): void
  {
    this.authenticationService.logout();

    location.reload();
  }

}
