import { NgForm } from '@angular/forms';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AppEventNames, STATES } from 'src/app/_appCommon/base/Constants';
import { StringTools } from 'src/app/_appCommon/util/StringTools';
import { ControllerBase } from '../../_appCommon/base/ControllerBase';
import { BroadcastManager } from 'src/app/_appCommon/base/BroadcastManager';
import { CustomerAccessDataService } from '../../_dataservices/security/CustomerAccessDataService';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css'],
  providers: [CustomerAccessDataService]
})

export class RegisterComponent extends ControllerBase implements OnInit
{
  @ViewChild(NgForm) public residentForm: NgForm;
  @ViewChild(NgForm) public specialistForm: NgForm;

  public nowDate;
  public interval: any;
  public play: boolean;
  public action: string;
  public loading = false;
  public showModal = true;
  public time: number = 0;
  public clearField: string;
  public isSignUpComplete = false;
  public showSpecialistForm = true;
  public userSignUpResident: any = {};
  public userSignUpSpecialist: any = {};
  public States = STATES;
  public showError: boolean = false;
  constructor(private customerAccessDataService: CustomerAccessDataService)
  {
    super();
  }

  ngOnInit(): void
  {
    this.initStartDate();
  }

  public startTimer()
  {
    this.play = true;
    this.interval = setInterval(() =>
    {
      this.time++;
      if (this.clearField === "userSignUpResidentEmail")
      {
        if (this.action.includes("set@CountTo0"))
        {
          this.userSignUpResident.email = StringTools.removeChars(this.userSignUpResident.email, "@");
        }
        if (this.action.includes("clearBadChars"))
        {
          this.userSignUpResident.email = StringTools.removeChars(this.userSignUpResident.email, StringTools.emailBadChars);
        }
        if (this.action.includes("set@CountTo1"))
        {
          this.userSignUpResident.email = StringTools.setCharCountTo1(this.userSignUpResident.email, "@");
        }
      }
      else if (this.clearField === "userSignUpSpecialistEmail")
      {
        if (this.action.includes("set@CountTo0"))
        {
          this.userSignUpSpecialist.email = StringTools.removeChars(this.userSignUpResident.email, "@");
        }
        if (this.action.includes("clearBadChars"))
        {
          this.userSignUpSpecialist.email = StringTools.removeChars(this.userSignUpSpecialist.email, StringTools.emailBadChars);
        }
        if (this.action.includes("set@CountTo1"))
        {
          this.userSignUpSpecialist.email = StringTools.setCharCountTo1(this.userSignUpSpecialist.email, "@");
        }
      }
      else if (this.clearField === "userSignUpResidentPhone")
      {
        if (this.action.includes("clearBadChars"))
        {
          this.userSignUpResident.phone = StringTools.removeChars(this.userSignUpResident.phone, StringTools.phoneBadChars);
        }
      }
      else if (this.clearField === "userSignUpSpecialistPhone")
      {
        if (this.action.includes("clearBadChars"))
        {
          this.userSignUpSpecialist.phone = StringTools.removeChars(this.userSignUpSpecialist.phone, StringTools.phoneBadChars);
        }
      }
      this.pauseTimer();
    }, 1000);
  }

  public pauseTimer()
  {
    this.play = false;
    clearInterval(this.interval);
  }

  public userSignUpResidentEmailChanged()
  {
    this.clearField = "userSignUpResidentEmail";
    let doWork: boolean = false;
    this.action = "";
    if (this.userSignUpResident.email[0] === "@")
    {
      this.action += " set@CountTo0";
      doWork = true;
    }
    if (StringTools.emailHasBadChars(this.userSignUpResident.email))
    {
      this.action += " clearBadChars";
      doWork = true;
    }
    if (StringTools.stringCharCount(this.userSignUpResident.email, "@") > 1)
    {
      this.action += " set@CountTo1";
      doWork = true;
    }
    if (doWork)
    {
      if (!this.play)
      {
        this.startTimer();
      }
    }
  }

  public userSignUpSpecialistEmailChanged()
  {
    this.clearField = "userSignUpSpecialistEmail";
    let doWork: boolean = false;
    this.action = "";
    if (this.userSignUpSpecialist.email[0] === "@")
    {
      this.action += " set@CountTo0";
      doWork = true;
    }
    if (StringTools.emailHasBadChars(this.userSignUpSpecialist.email))
    {
      this.action += " clearBadChars";
      doWork = true;
    }
    if (StringTools.stringCharCount(this.userSignUpSpecialist.email, "@") > 1)
    {
      this.action += " set@CountTo1";
      doWork = true;
    }
    if (doWork)
    {
      if (!this.play)
      {
        this.startTimer();
      }
    }
  }

  public userSignUpSpecialistPhoneChanged()
  {
    this.clearField = "userSignUpSpecialistPhone";
    let doWork: boolean = false;
    this.action = "";
    if (StringTools.phoneHasBadChars(this.userSignUpSpecialist.phone))
    {
      this.action += " clearBadChars";
      doWork = true;
    }
    if (doWork)
    {
      if (!this.play)
      {
        this.startTimer();
      }
    }
  }

  public userSignUpResidentPhoneChanged()
  {
    this.clearField = "userSignUpResidentPhone";
    let doWork: boolean = false;
    this.action = "";
    if (StringTools.phoneHasBadChars(this.userSignUpResident.phone))
    {
      this.action += " clearBadChars";
      doWork = true;
    }
    if (doWork)
    {
      if (!this.play)
      {
        this.startTimer();
      }
    }
  }

  public signUp(): void
  {
    let signUpData = {};

    if((this.specialistForm.valid&&this.userSignUpSpecialist.hasOrthodonticsSpecialty) || this.residentForm.valid)
    {
      if (!this.showSpecialistForm)
        this.userSignUpResident.isResident = true;

      signUpData = this.showSpecialistForm ? this.userSignUpSpecialist : this.userSignUpResident;
      signUpData['loginId'] = this.showSpecialistForm ? this.userSignUpSpecialist.email : signUpData['loginId'] = this.userSignUpResident.email;

      this.loading =  true;
    }
    else
    {
      this.showError=true;
      this.residentForm.form.markAllAsTouched();
      this.specialistForm.form.markAllAsTouched();
      return;
    }

    this.customerAccessDataService.createAccount(signUpData)
      .subscribe((response: any) =>
      {
        this.setSuccessAlert('¡Registro completado!');
        this.isSignUpComplete = true;
        this.loading = false;
      }, error =>
      {
        this.setWarningAlert("Se produjo un error al intentar crear tu cuenta. Detalle: " + error.error.message);
        this.loading = false;
      });
  }

  public reset(): void
  {
    this.residentForm?.reset();
    this.specialistForm?.reset();
    this.onHandleCloseModal();
  }

  public onHandleCloseModal(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnShowUserSignUp, false);
  }

  public setGraduationDate(event: any)
  {
    let start = new Date(event.value.toString());
    this.userSignUpResident.startDate = new Date(event.value.toString());
    this.userSignUpResident.startDate.setHours(0, 0, 0, 0);
  }

  public initStartDate()
  {
    const date = this.userSignUpResident.startDate ? new Date(this.userSignUpResident.startDate) : new Date();
    date.setDate(date.getDate());
    date.setHours(0, 0, 0, 0);
    this.nowDate = date;
    this.userSignUpResident.startDate = date;
  }

  public getCheckBoxError(): boolean {

    if (this.userSignUpSpecialist.hasOrthodonticsSpecialty) {
      this.showError = false;
      return false;
    }
    else 
    {
      this.showError = true;
      return true;
    }
  }
}

