import { Component } from '@angular/core';
import { BroadcastManager } from '../../../_appCommon/base/BroadcastManager';
import { AppEventNames } from '../../../_appCommon/base/Constants';
import { ControllerBase } from '../../../_appCommon/base/ControllerBase';

@Component({
  selector: 'app-onboarding-email-password-reset',
  templateUrl: './emailPasswordReset.component.html',
  styleUrls: ['./emailPasswordReset.component.css']
})

export class EmailPasswordResetComponent extends ControllerBase
{
  public showModal = true;

  constructor()
  {
    super();
  }

  ngOnInit(): void
  {

  }
  public onHandleCloseModal(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingEmailPasswordChange, false);
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingByPassEmailPasswordChange, false);
  }
  public doLogin(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingEmailPasswordChange, false);
    BroadcastManager.Instance.broadcast(AppEventNames.OnShowUserLogin, true);
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingByPassEmailPasswordChange, true);
  }
}

