export enum AlertType { Success, Info, Warning, Danger }
import * as Toastr from 'toastr';

/**
* Class for notification area alerts
*/
export class Alert
{
  public alert: Toastr;

  constructor(public alertType: AlertType, message: string, title: string = null)
  {
    this.alert = Toastr;

    this.alert.options = {
      "closeButton": true,
      "debug": false,
      "positionClass": "toast-bottom-right",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": 300,
      "hideDuration": 100,
      "timeOut": 3000,
      "extendedTimeOut": 1000,
    };

    var alertTypeText = this.AlertTypeText;

    if (title)
    {
      this.alert[alertTypeText](message, title);
    }
    else
    {
      this.alert[alertTypeText](message);
    }

  }

  public get alertClass()
  {
    var alertTypeText = "";

    switch (this.alertType)
    {
      case AlertType.Success: alertTypeText = "success"; break;
      case AlertType.Info: alertTypeText = "info"; break;
      case AlertType.Warning: alertTypeText = "warning"; break;
      case AlertType.Danger: alertTypeText = "danger"; break;
    }

    return "alert alert-" + alertTypeText + " alert-dismissable";
  }

  public get AlertTypeText(): string
  {
    var alertTypeText = "";

    switch (this.alertType)
    {
      case AlertType.Success: alertTypeText = "success"; break;
      case AlertType.Info: alertTypeText = "info"; break;
      case AlertType.Warning: alertTypeText = "warning"; break;
      case AlertType.Danger: alertTypeText = "error"; break;
    }

    return alertTypeText;
  }
}
