import { animate, state, style, transition, trigger } from '@angular/animations';

export const slideDown = trigger('slideDown', [
  state('open', style({
    padding: '40px 20px',
    height: 'auto',
    visibility: 'visible',
  })),
  state('closed', style({
    height: '0',
    opacity: 0,
    visibility: 'hidden',
  })),
  transition('open <=> closed', [
    animate('0.6s cubic-bezier(.4,0,.2,1)')
  ]),
]);

export const slideRightToLeft = trigger('slideRightToLeft', [
  state('final', style({
    transform: 'translateX(0%)',
    opacity: 1,
  })),
  state('initial', style({
    transform: 'translateX(100%)',
    opacity: 0
  })),
  transition('initial <=> final', [
    animate('1s ease')
  ]),
]);

export const slideLeftToRight = trigger('slideLeftToRight', [
  state('final', style({
    transform: 'translateX(0%)',
    opacity: 1,
  })),
  state('initial', style({
    transform: 'translateX(-100%)',
    opacity: 0
  })),
  transition('initial <=> final', [
    animate('1s ease')
  ]),
]);

export const slideTopToBottom = trigger('slideTopToBottom', [
  state('final', style({
    transform: 'translateY(0%)',
    opacity: 1,
  })),
  state('initial', style({
    transform: 'translateY(-100%)',
    opacity: 0
  })),
  transition('initial <=> final', [
    animate('1s ease')
  ]),
]);

export const scaleContent = trigger('scaleContent', [
  state('final', style({
    transform: 'scale(1)',
  })),
  state('initial', style({
    transform: 'scale(0.7)',
  })),
  transition('initial <=> final', [
    animate('1s ease')
  ]),
]);

