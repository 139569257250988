import { Component, OnInit } from '@angular/core';
import { STEPS } from '../../../_appCommon/base/Constants';
import { slideRightToLeft, slideTopToBottom, slideLeftToRight, scaleContent } from '../../../_appCommon/animations/animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css'],
  animations: [slideRightToLeft, slideLeftToRight, slideTopToBottom, scaleContent]
})

export class IndexComponent implements OnInit
{
  public steps = STEPS;

  ngOnInit() { }

  constructor(private router: Router) { }

  onHandleButtonNavigate(url: string): void
  {
    this.router.navigate([`/${url}`]);
  }
}
