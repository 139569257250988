import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { SwiperModule } from "swiper/angular";
import { AppRoutingModule } from "../app-routing.module";
import { AppComponent } from "../app.component";
import { AccordionComponent } from './components/accordion/accordion.component';
import { PanelComponent } from "./components/accordion/panel/panel.component";
import { BeforeAfterSliderComponent } from "./components/slider/before-after-slider.component";
import { SafePipe } from "./pipes/safe.pipe ";
import { TranslatePipe } from "./pipes/translate.pipe";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScrollAnimationDirective } from "../_directives/scroll-animation.directive";
import { BackgroundComponent } from "./components/background/background.component";
import { FaqBackgroundComponent } from "./components/background/faq-background.component";
import { FileSelectorComponent } from "./components/file-selector/file-selector.component";
import { PhoneNumberDirective } from "../_directives/phone-number-validator.directive";
import { WhatsappFloatIconComponent } from './components/whatsapp-float-icon/whatsapp-float-icon.component';
import { SliderCompareComponent } from "./components/slider-compare/slider-compare.component";
import { DragAndDropFilesDirective } from "../_directives/drag-and-drop-files.directive";
import { AppShareModule } from '../../shared/app.module';
import { CalendarValidationDirective } from "../_directives/calendar-validation.directive";
import { OnlyNumbersDirective } from "../_directives/only-numbers.directive";

@NgModule({
  declarations: [
    TranslatePipe,
    SafePipe,
    BeforeAfterSliderComponent,
    AccordionComponent,
    PanelComponent,
    FileSelectorComponent,
    BackgroundComponent,
    FaqBackgroundComponent,
    WhatsappFloatIconComponent,
    SliderCompareComponent,
    ScrollAnimationDirective,
    PhoneNumberDirective,
    DragAndDropFilesDirective,
    CalendarValidationDirective,
    OnlyNumbersDirective
  ],
  exports: [
    TranslatePipe,
    SafePipe,
    BeforeAfterSliderComponent,
    AccordionComponent,
    PanelComponent,
    FileSelectorComponent,
    BackgroundComponent,
    FaqBackgroundComponent,
    WhatsappFloatIconComponent,
    SliderCompareComponent,
    ScrollAnimationDirective,
    PhoneNumberDirective,
    DragAndDropFilesDirective,
    CalendarValidationDirective,
    OnlyNumbersDirective
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SwiperModule,
    AppShareModule,
    BrowserAnimationsModule
  ],
  bootstrap: [AppComponent],
  providers: []
})

export class ShareModule { }
