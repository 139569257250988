import { Observable } from 'rxjs';
import { share } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EntityConfig } from '../_appCommon/base/EntityConfig';
import { UrlQueryDef } from 'src/app/_appCommon/base/UrlQueryDef';
import { EntityDataServiceBase } from '../_appCommon/base/EntityDataServiceBase';

@Injectable()
export class ClinicDataService extends EntityDataServiceBase
{
  private getClinicByCustomerId_WebApiMethod: string = "getClinicByCustomerId";
  private createClinic_WebApiMethod: string = "createClinic";
  private updateClinic_WebApiMethod: string = "updateClinic";

  constructor(protected http: HttpClient)
  {
    super(http, 'clinic');
  }

  protected loadEntityConfig(): void
  {
    this.entityConfig = new EntityConfig();
    this.entityConfig.entityDisplayName = 'clinic';
    this.entityConfig.apiUrl = 'api/Clinic';
  }

  public getClinicByCustomerId(customerId: number): Observable<any>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.get(this.createUrl(this.getClinicByCustomerId_WebApiMethod + "/" + customerId), queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public createClinic(body: any): Observable<any>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.post(this.createUrl(this.createClinic_WebApiMethod), body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public updateClinic(body: any): Observable<any>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.put(this.createUrl(this.updateClinic_WebApiMethod), body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }
}

