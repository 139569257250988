<app-dialog [enableCloseDialogButton]="false" [show]="showModal" [preventCloseFromBackDrop]="true" width="40%">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div class="col-12 mt-3 mb-3">
          <button (click)="onHandleCloseModal()" mat-button class="text-muted" style="font-size:12px"><mat-icon>arrow_back_ios</mat-icon>Regresar</button>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex mx-auto flex-column align-items-center" style="width:70%">
    <div class="mb-2 w-100 w-sm-50" >
      <img src="../../../assets/logo.png" alt="Logo Aliwell">
    </div>
    <div class="mb-2">
      <mat-icon style="color: #20c997; font-size: 110px; height: 110px; width: 110px; ">check</mat-icon>
    </div>
    <div class="mb-5">
      <p class="text-center fw-bold" style="line-height:20px; font-size:24px;">Hemos enviado a tu correo una contraseña temporal.</p>
    </div>
    <div class="mb-5">
      <p class="text-center">Estamos migrando de plataforma, será necesario que restablezcas tu contraseña al ingresar.</p>
    </div>
    <div style="width:100%;" class="mb-5">
      <button (click)="doLogin()" style="width: 100%; white-space: normal;"  mat-button class="button primary-button">Iniciar Sesión</button>
    </div>
  </div>
</app-dialog>
