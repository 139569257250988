import { Directive, HostListener } from "@angular/core";
import { Router } from "@angular/router";

@Directive({
  selector: "[innerLinks]"
})
export class InnerLinksDirective
{
  constructor(private router: Router) {}

  @HostListener("click", ["$event"])
  handle(event: MouseEvent)
  {
    const target = event.target as HTMLElement;
    if (target.nodeName === "A")
    {
      event.preventDefault();
      event.stopPropagation();
      const href = target.getAttribute("href");
      this.router.navigateByUrl(href);
    }
  }
}
