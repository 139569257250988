
import { HttpClient } from '@angular/common/http';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { AppClientContext } from './AppClientContext';

export class ApiServiceBase
{
  protected apiUrl: string;

  constructor(
    protected http: HttpClient
  )
  { }

  // ***********************************************************
  // Support Methods
  // ***********************************************************

  /**
  * Creates the relative url for the service call.  Creates a url like: /api/myResource/myMethod/1/2/3
  *
  * @param method - examples: get, getById
  * @param identifiers (optional) - examples: 1, Mary.  Can supply multiple
  */

  protected createUrl(method: string, ...identifiers: any[]): string
  {
    var returnValue = this.apiUrl;

    if (method)
    {
      returnValue = `${this.apiUrl}/` + method;
    }

    identifiers.forEach(identifier =>
    {
      //MT - Added null check
      if (identifier != null)
      {
        returnValue += "/" + identifier;
      }
    });

    return returnValue;
  }

  /**
  * Takes an Observable - and handles the success and fail conditions - including logging the results.
  */
  protected _handleObservableResponse(subject: Observable<{}>)
  {
    // Get random value - chop off the leading 0.
    let random = Math.random().toString().substring(2);

    let dataServiceTokenName = (<any>this.constructor).name + "_" + random;

    AppClientContext.Instance.setDataServiceBusy(dataServiceTokenName);

    subject
      .subscribe(
        function (response)
        {
          try
          {

          }
          finally
          {
            AppClientContext.Instance.setDataServiceDone(dataServiceTokenName);
          }
        },
        function (errorData: any)
        {
          try
          {
            if (errorData && errorData.status)
            {

            }

            // Not Found
            if (errorData && errorData.status == 404)
            {

              return;
            }

            // Unauthorized
            if (errorData && errorData.status == 401)
            {
              if (errorData.statusText = "Unauthorized")
              {

              }
              else
              {
                window.location.href = "/";
              }

              return;
            }

            if (errorData && errorData.status == 500)
            {
              // Not in cache
              if (errorData.error && errorData.error.message == "User not in Cache... possibly timed out.")
              {
                window.location.href = "/";
              }

              return;
            }

            // Outage
            if (errorData && errorData.status == 503)
            {
              return;
            }
          }
          finally
          {
            AppClientContext.Instance.setDataServiceDone(dataServiceTokenName);
          }
        });
  }

  // handle errors
  protected handleError(response: Response, request: Request): Observable<Response>
  {
    if (response.status === 401)
    {

    }

    // handle server error
    if (response.status === 500)
    {

    }

    return observableThrowError(response);
  }
}
