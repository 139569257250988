import { HttpHeaders, HttpParams } from "@angular/common/http";
import { Section } from "src/app/_appCommon/base/AppInterfaceAndBaseClasses";

export class UrlQueryDef
{

  // ********************************************************************
  //#region Properties / Variables
  // ********************************************************************

  public includes: string;
  public pagingActive: boolean = true;

  public domainSpecificContextId?: number;

  /**
      * {Object.<string|Object>}
      * Map of strings or objects which will be turned to ?key1=value1&key2=value2 after the url. If the value is not a string, it will be JSONified.
      */
  public params: any;

  /**
      * If true - and if the Entity supports soft deletes - then deleted rows will be included in the results
      */
  public includeDeletedRows: boolean = false;

  /**
      * Expands related entities inline.
      *
      * Examples:
      *  Enrollments
      *  Enrollments/Course
      */
  public expand: string;

  /**
      * Selects which properties to include in the response.
      *
      * NOTE: To select properties from child entities, they must be included in the expand list.
      *
      * Examples:
      *  Id,LastName - Gets the Id and LastName properties of the entity
      *  Id,LastName,Enrollments - Gets the Id, LastName properties of the entity - and also the Enrollments child object collection
      *  Id,LastName,Enrollments/CourseID - Gets the Id, LastName properties of the entity - and also the CourseID property of the Enrollments child object collection
      */
  public select: string;

  /**
      * Filters the results, based on a Boolean condition.
      *
      * (Note: the operators ARE case sensitive - and must be all lower case)
      *
      * Supported Operators: eq, ne, gt, ge, lt, le, and, or, not
      * Grouping Operator: ()
      *  Examples:
      *      Id gt 5
      *      Id gt 5 and LastName gt 'r'
      *
      * Special Operators:
      * startswith:  startswith(LastName, 'mario')
      * endswith:    endswith(LastName, 'arado')
      * substringof: (contains): substringof('zz', LastName)
      * and:         startswith(LastName, 'a') and Id lt 3
      *
      * Date Filters (examples - likely more options):
      *  year:   year(EnrollmentDate) lt 2010
      *  month:  month(EnrollmentDate) gt 11
      *
      * Most likely there are other filtering operations available.
      */

  public filter: string;

  /**
      * Sorts the results.
      *
      * Examples:
      *  LastName
      *  EnrollmentDate desc,LastName
      */
  public orderby: string;

  /**
      * Returns only the first N results
      */
  public top: number;

  /**
      * Skips the first N results
      */
  public skip: number;

  public keyword: any;
  /**
   * Page where we are
   */
  public pageNo: number;
  /**
   * Number of records to display per page
   */
  public pageSize: number;
  /**
   * Filter word
   */
  public searchWord: string;
  public sortBy: any;
  public descending: any;


  // #endregion

  // ********************************************************************
  //#region Methods
  // ********************************************************************

  /** Method to add HttpParams into our request. Accepts a <name> <value> pair */
  public addParam(name: string, value: any)
  {
    if (this.params == null) { this.params = {}; }

    this.params[name] = value;
  }

  public getRequestConfig(noParams = false): any
  {
    let headers = new HttpHeaders();

    headers = headers.append('Content-Type','application/json');
    headers = headers.append('SECTION',Section.Ecommerce.toString());

    const requestConfig = {
      params: noParams ? null : this._getParamsMap(),
      headers: headers
    };

    return requestConfig;
  }

  // #endregion

  // ********************************************************************
  //#region Support Methods
  // ********************************************************************

  private _getParamsMap(): HttpParams
  {
    let paramsMap = new HttpParams();

    if (this.params != null)
    {
      // Add properties from params to params map
      for (var paramName in this.params)
      {
        paramsMap = paramsMap.append(paramName, this.params[paramName]);
      }
    }

    if (this.includes != null)
    {
      paramsMap = paramsMap.append("includes", this.includes);
    }

    if (this.includeDeletedRows != null)
    {
      paramsMap = paramsMap.append("includeDeletedRows", this.includeDeletedRows.toString());
    }

    if (this.domainSpecificContextId)
    {
      paramsMap = paramsMap.append("domainSpecificContextIds", this.domainSpecificContextId.toString());
    }

    // WebApi - ODATA parameters
    if (this.expand != null) { paramsMap = paramsMap.append("$expand", this.expand); }
    if (this.select != null) { paramsMap = paramsMap.append("$select", this.select); }
    if (this.filter != null) { paramsMap = paramsMap.append("$filter", this.filter); }
    if (this.orderby != null) { paramsMap = paramsMap.append("$orderby", this.orderby); }
    if (this.top != null) { paramsMap = paramsMap.append("$top", this.top.toString()); }
    if (this.skip != null) { paramsMap = paramsMap.append("$skip", this.skip.toString()); }
    if (this.keyword != null) { paramsMap = paramsMap.append("keyword", this.keyword.toString()); }
    if (this.searchWord != null) { paramsMap = paramsMap.append("searchWord", this.searchWord.toString()); }
    if (this.pageNo != null) { paramsMap = paramsMap.append("pageNo", this.pageNo.toString()); }
    if (this.pageSize != null) { paramsMap = paramsMap.append("pageSize", this.pageSize.toString()); }
    if (this.sortBy != null) { paramsMap = paramsMap.append("sortBy", this.sortBy.toString()); }
    if (this.descending != null) { paramsMap = paramsMap.append("descending", this.descending); }

    return paramsMap;
  }

  //#endregion
}
