import { Directive, ElementRef, HostListener } from '@angular/core';
import { StringTools } from '../_appCommon/util/StringTools';

@Directive()
export class baseInputValidatorDirective
{
  protected _regex: RegExp;
  protected _el: ElementRef;

  // Allow key codes for special events. Reflect :
  // Backspace, tab, end, home
  private specialKeys: Array<string> = ['Backspace', 'Tab', 'End', 'Home'];

  constructor() { }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent)
  {
    // Allow Backspace, tab, end, and home keys
    if (this.specialKeys.indexOf(event.key) !== -1)
    {
      return true;
    }

    // Do not use event.keycode this is deprecated.
    // See: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode

    let modifiedString = this.getModifiedString(event.key);


    if (modifiedString && !String(modifiedString).match(this._regex))
    {
      return false;
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent)
  {
    let textToBePasted = event.clipboardData.getData('text');

    if (!String(textToBePasted).match(this._regex))
    {
      event.preventDefault();
    }


    let modifiedString = this.getModifiedString(textToBePasted);

    if (modifiedString && !String(modifiedString).match(this._regex))
    {
      event.preventDefault();
    }

  }

  private getModifiedString(stringInsertedByEvent: string): string
  {
    let currentString: string = this._el.nativeElement.value;
    let cursorPosition = this._el.nativeElement.selectionStart;

    let numberOfCharactersToDelete = 0;
    let startOfSelection = cursorPosition;
    let endOfSelection = this._el.nativeElement.selectionEnd;

    //Checks if cursor is selecting a part of the string and counts the characters
    if (startOfSelection != endOfSelection)
    {
      numberOfCharactersToDelete = (endOfSelection - startOfSelection);
    }

    let modifiedString = StringTools.splice(currentString, cursorPosition, numberOfCharactersToDelete, stringInsertedByEvent);

    return modifiedString;
  }
}
