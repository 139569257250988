import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Section, UserAccessResetPassword } from 'src/app/_appCommon/base/AppInterfaceAndBaseClasses';
import { AuthenticationService } from '../../../_dataservices/security/AuthenticationService';
import { ActivatedRoute, Router } from '@angular/router';
import { ControllerBase } from '../../../_appCommon/base/ControllerBase';
import { SystemServicesDataService } from '../../../_dataservices/security/SystemServicesDataService';
import { PasswordErrorMessage, PasswordValidationType } from '../../../_appCommon/base/AppInterfaceAndBaseClasses';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
  providers: [AuthenticationService, SystemServicesDataService]
})

export class ResetPasswordComponent extends ControllerBase implements OnInit
{

  @ViewChild(NgForm) public resetPasswordForm: NgForm;

  public userAccessResetPassword: UserAccessResetPassword = { newPassword: "", confirmPassword: ""};

  public isNewPasswordHide = true;
  public isConfirmPasswordHide = true;

  public loading = false;

  public showChangePasswordMessage = false;

  public newPasswordError = "";
  private passwordErrorType: Map<PasswordValidationType, string>;

  private loginId: string;
  private loginIdUrl: string;
  private validationKey: string;

  private sectionToRedirect:number;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService,
  )
  {
    super();
  }

  ngOnInit(): void
  {
    this.loginIdUrl = this.activatedRoute.snapshot.queryParams['loginId'];
    this.validationKey = this.activatedRoute.snapshot.queryParams['validationKey'];

    if (this.loginIdUrl)
      this.loginId = this.loginIdUrl;

    this.passwordErrorType = PasswordErrorMessage;
  }


  public changePassword():void
  {
    if (this.userAccessResetPassword.newPassword == this.userAccessResetPassword.confirmPassword)
    {
      this.loading = true;
      const resetPasswordData =
      {
        LoginId: this.loginId,
        OldPassword: this.validationKey,
        NewPassword: this.userAccessResetPassword.confirmPassword,
      };

      this.authenticationService.changePassword(resetPasswordData)
        .subscribe((response: any) =>
        {
          this.sectionToRedirect = response.userSection;
          this.passwordChangeSuccess();
        },
        fail =>
        {
          if (fail.error.message)
          {
            this.setDangerAlert(fail.error.message);
            this.loading = false;
          }

        });
    }
    else
    {
      this.resetPasswordForm.form.controls["confirmPassword"].setErrors({
        validateEqual: false
      });
    }
  }

  public validatePassword(valueInput:string)
  {
    this.newPasswordError = "";
    this.passwordErrorType.forEach(
      (value, key) =>
      {
        if (!new RegExp(key).test(valueInput))
        {
          this.newPasswordError = value;
        }
      }
    );
    if(this.newPasswordError != "") this.resetPasswordForm.form.controls['newPassword'].setErrors({'incorrect':true});
  }

  private validateConfirmPassword()
  {
    if(this.newPasswordError == "" && this.userAccessResetPassword.newPassword === this.userAccessResetPassword.confirmPassword)
    {
      this.resetPasswordForm.form.controls['newPassword'].setErrors(null);
      this.resetPasswordForm.form.controls['confirmPassword'].setErrors(null);
    }
  }

  public passwordChangeSuccess()
  {
    this.loading = false;
    this.showChangePasswordMessage = true;
  }

  public reset()
  {
    //We redirect to home and then reload the app.
    switch(this.sectionToRedirect){
      case Section.Ecommerce:
        window.location.href = "/";
        break;
      case Section.Backoffice:
        window.location.href = "/dashboard";
        break;
    }
  }
}

