import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { scaleContent, slideLeftToRight, slideRightToLeft, slideTopToBottom } from 'src/app/_appCommon/animations/animations';
import { BroadcastManager } from 'src/app/_appCommon/base/BroadcastManager';
import { STEPSTOOFFER, PROGRAMS, AppEventNames } from '../../../_appCommon/base/Constants';

@Component({
  selector: 'app-im-orthodontist',
  templateUrl: './im-orthodontist.component.html',
  styleUrls: ['./im-orthodontist.component.css'],
  animations: [slideRightToLeft, slideLeftToRight, slideTopToBottom, scaleContent]
})

export class ImOrthodontistComponent implements OnInit
{

  public StepsToOffer = STEPSTOOFFER;
  public Programs = PROGRAMS;

  ngOnInit() { }

  constructor(private sanitizer: DomSanitizer) { }

  public transformSVG(html)
  {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }

  public onHandleClickSignUp(): void
  {
    BroadcastManager.Instance.broadcast(AppEventNames.OnShowUserSignUp, true);
  }

}
