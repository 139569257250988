import { Component, OnInit } from '@angular/core';
import { FAQPATIENT, FAQSPECIALIST } from '../../../_appCommon/base/Constants';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})

export class FaqComponent implements OnInit
{

  public FaqPatient = FAQPATIENT;
  public FaqSpecialist = FAQSPECIALIST;
  public isSpecialist = false;

  ngOnInit() { }

}
