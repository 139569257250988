import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AppClientContext } from '../base/AppClientContext';
import { Section } from '../base/AppInterfaceAndBaseClasses';
import { BACKOFFICE_USER_TOKEN, ECOMMERCE_USER_TOKEN, TOKEN_HEADER_KEY } from '../base/Constants';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor
{
  constructor() { }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
  {
    // Get headers from the ClientContext.
    let authReq = req;

    let section = req.headers.get('section');

    let userToken = +section == Section.Backoffice ? BACKOFFICE_USER_TOKEN : ECOMMERCE_USER_TOKEN;

    const token = AppClientContext.Instance.headerValues.get(userToken);

    if (token != null)
    {
      authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token)});
    }

    return next.handle(authReq).pipe(catchError(error =>
    {
      return throwError(error);
    }));
  }


  private getHttpHeaders = (headers: Array<any>, originalHeaders: any): HttpHeaders =>
  {
    let res = new HttpHeaders();

    for (const item of headers)
    {
      res = res.append(item.key, item.value);
    }

    for (let [key, value] of originalHeaders.headers)
    {
      res = res.append(key, value[0]);
    }

    try
    {
      const contentType = originalHeaders.get('Content-Type');

      if (contentType) { res = res.append('Content-Type', contentType); }
    }
    catch (e)
    {
      res = res.append('Content-Type', 'application/json');
    }

    return res;
  };

}
