import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './layouts/main/main.component';
import { ResetPasswordComponent } from './layouts/pages/reset-password/reset-password.component';
import { PrivacyNoticesComponent } from './layouts/pages/privacy-notices/privacy-notices.component';
import { AuthPermissionsGuardEcommerce } from 'Guards/auth-permissions-ecommerce.guard';
import { ImageComponent } from './layouts/pages/image/image.component';

const routes: Routes = [
  <any>
  {
    path: '',
    component: MainComponent,
    children: [
    ]
  },
  {
    path: 'avisos-privacidad',
    component: PrivacyNoticesComponent,
  },
  {
    path: 'resetPassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'img',
    component: ImageComponent,
  },
  {
    path: 'dashboard',
    loadChildren: () => import('../app-admin/app.module').then(m => m.AppAdminSharedModule)
  },
  {
    path: 'ecommerce', canActivate: [AuthPermissionsGuardEcommerce],
    loadChildren: () => import('../app-client/app.module').then(m => m.AppClientSharedModule)
  },
  {
    path: "**",
    redirectTo: '/'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy',
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
  providers: []
})

export class AppRoutingModule { }
