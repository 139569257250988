<ng-container *ngIf="!successMessage">
  <app-dialog [enableCloseDialogButton]="false" [show]="showModal" [preventCloseFromBackDrop]="true" width="90%">
    <div class="container w-100 w-md-50 pt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h3><strong>¡Bienvenido de vuelta!</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <h4>A continuación, te recomendamos actualizar tus datos para tener una mejor experiencia</h4>
        </div>
      </div>
    </div>
    <div class="container w-100">
      <mat-stepper [linear]="true" orientation="{{size700_1020 ? 'vertical' : 'horizontal'}}" labelPosition="top" #stepper>
        <mat-step [stepControl]="doctorForm">
          <ng-template matStepLabel>Datos del médico</ng-template>
          <div class="container pt-5">
            <mat-card class="card-general w-100 w-md-50 mx-auto">
              <mat-card-title>Datos del médico</mat-card-title>
              <mat-card-content>
                <form [formGroup]="doctorForm" (keydown.enter)="$event.preventDefault()">
                  <div class="container">
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-8">
                        <mat-label>Nombre(s)*</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                          <input formControlName="firstName" name="firstName" id="firstName" [(ngModel)]="customerObject.name" matInput required>
                          <mat-error *ngIf="doctorForm.get('firstName').hasError('required')">Completa este campo.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-8">
                        <mat-label>Apellido Paterno*</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                          <input formControlName="lastName" name="lastName" id="lastName" [(ngModel)]="customerObject.lastName" required matInput>
                          <mat-error *ngIf="doctorForm.get('lastName').hasError('required')">Completa este campo.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-8">
                        <mat-label>Apellido Materno*</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                          <input formControlName="secondLastName" name="secondLastName" id="secondLastName" [(ngModel)]="customerObject.secondLastName" required matInput>
                          <mat-error *ngIf="doctorForm.get('secondLastName').hasError('required')">Completa este campo.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-8">
                        <mat-label>Email*</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                          <input formControlName="email" name="email" id="email" [(ngModel)]="customerObject.email" required email matInput>
                          <mat-error *ngIf="doctorForm.get('email').hasError('required')">Completa este campo.</mat-error>
                          <mat-error *ngIf="doctorForm.get('email').hasError('email')">Introduce un email válido.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-8">
                        <mat-label>Teléfono*</mat-label>
                        <mat-form-field class="w-100" appearance="outline">
                          <input formControlName="phone" name="phone" id="phone" [(ngModel)]="customerObject.phone" maxlength="10" minlength="10" phoneNumberOnly required matInput>
                          <mat-error *ngIf="doctorForm.get('phone').hasError('required')">Completa este campo.</mat-error>
                          <mat-error *ngIf="doctorForm.get('phone').hasError('minlength')">Introduce un número de teléfono válido a 10 digitos.</mat-error>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12 col-sm-12 col-lg-8">
                        <mat-radio-group name="specialtyInOrthodontics" id="specialtyInOrthodontics" aria-label="Selecciona una opción" class="d-flex gap-2">
                          <mat-label>¿Tiene especialidad en ortodoncia?*</mat-label>
                          <mat-radio-button [checked]="specialtyInOrthodonticsYes" value="1" (change)="specialtyInOrthodonticsYesChanged()" >Si</mat-radio-button>
                          <mat-radio-button [checked]="specialtyInOrthodonticsNo" value="0" (change)="specialtyInOrthodonticsNoChanged()" >No</mat-radio-button>
                        </mat-radio-group>
                      </div>
                    </div>
                  </div>
                </form>
              </mat-card-content>
            </mat-card>
            <div class="container text-center mt-4">
              <button style="width: 45%; white-space: normal;" mat-button (click)="goForward()" class="button primary-button">Siguiente<mat-icon>navigate_next</mat-icon></button>
            </div>
          </div>
        </mat-step>
        <mat-step [stepControl]="clinicForm">
          <ng-template matStepLabel>Datos del Consultorio</ng-template>
          <form [formGroup]="clinicForm" (keydown.enter)="$event.preventDefault()">
            <div class="container pt-5">
              <div class="row gy-5">
                <div class="col-12 col-md-6">
                  <mat-card class="card-general">
                    <mat-card-title>Datos del consultorio</mat-card-title>
                    <mat-card-content>
                      <div class="container">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-8">
                            <mat-label>Nombre*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="clinicName" name="clinicName" id="clinicName" [(ngModel)]="clinicObject.name" required matInput>
                              <mat-error *ngIf="clinicForm.get('clinicName').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-8">
                            <mat-label>Calle*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="street" name="street" id="street" [(ngModel)]="clinicObject.street" required matInput>
                              <mat-error *ngIf="clinicForm.get('street').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-6">
                            <mat-label>Número exterior*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="clinicNumber" name="clinicNumber" id="clinicNumber" [(ngModel)]="clinicObject.number" required matInput>
                              <mat-error *ngIf="clinicForm.get('clinicNumber').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                          <div class="col-12 col-sm-12 col-lg-6">
                            <mat-label>Número interior</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="clinicSubNumber" name="clinicSubNumber" id="clinicSubNumber" [(ngModel)]="clinicObject.subnumber" matInput>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-8">
                            <mat-label>Ciudad*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="city" name="city" id="city" [(ngModel)]="clinicObject.city" required matInput>
                              <mat-error *ngIf="clinicForm.get('city').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-8">
                            <mat-label>Estado*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <mat-select formControlName="state" name="state" id="state" [(ngModel)]="clinicObject.state" required matInput>
                                <mat-option *ngFor="let item of States" [value]="item.name">{{item.name}}</mat-option>
                              </mat-select>
                              <mat-error *ngIf="clinicForm.get('state').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-8">
                            <mat-label>Código postal*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="clinicZipCode" name="clinicZipCode" id="clinicZipCode" [(ngModel)]="clinicObject.zipCode" maxlength="5" required matInput>
                              <mat-error *ngIf="clinicForm.get('clinicNumber').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
                <div class="col-12 col-md-6">
                  <mat-card class="card-general">
                    <mat-card-title>Contacto con consultorio</mat-card-title>
                    <mat-card-content>
                      <div class="container">
                        <div class="row">
                          <div class="col-12 col-sm-12 col-lg-8">
                            <mat-label>Nombre de contacto*</mat-label>
                            <mat-form-field class="w-100" appearance="outline">
                              <input formControlName="contactName" name="contactName" id="contactName" [(ngModel)]="clinicObject.contactName" required matInput>
                              <mat-error *ngIf="clinicForm.get('contactName').hasError('required')">Completa este campo.</mat-error>
                            </mat-form-field>
                          </div>
                          <div class="row">
                            <div class="col-12 col-sm-12 col-lg-8">
                              <mat-label>Teléfono*</mat-label>
                              <mat-form-field class="w-100" appearance="outline">
                                <input formControlName="clinicPhone" name="clinicPhone" id="clinicPhone" [(ngModel)]="clinicObject.phone" maxlength="10" minlength="10" phoneNumberOnly required matInput>
                                <mat-error *ngIf="clinicForm.get('clinicPhone').hasError('required')">Completa este campo.</mat-error>
                                <mat-error *ngIf="clinicForm.get('clinicPhone').hasError('minlength')">Introduce un número de teléfono válido a 10 digitos.</mat-error>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-sm-12 col-lg-8">
                              <mat-label>Sitio web</mat-label>
                              <mat-form-field class="w-100" appearance="outline">
                                <input formControlName="website" name="website" id="website" [(ngModel)]="clinicObject.website" matInput>
                              </mat-form-field>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col-12 col-sm-12 col-lg-8">
                              <mat-label>Redes sociales</mat-label>
                              <mat-form-field class="w-100" appearance="outline">
                                <input formControlName="socialMedia" name="socialMedia" id="socialMedia" [(ngModel)]="clinicObject.socialMedia" matInput>
                              </mat-form-field>
                            </div>
                          </div>
                        </div>
                      </div>
                    </mat-card-content>
                  </mat-card>
                </div>
              </div>
            </div>
          </form>
          <div class="container text-center mt-4">
            <button style="width: 45%; white-space: normal; " mat-button (click)="saveNow()" class="button primary-button">Guardar</button>
          </div>
        </mat-step>
      </mat-stepper>
    </div>
  </app-dialog>
</ng-container>
<ng-container *ngIf="successMessage">
  <app-dialog [enableCloseDialogButton]="true" [show]="showModal" [preventCloseFromBackDrop]="true" width="90%">
    <div class="container w-100 w-md-50 pt-5">
      <div class="row">
        <div class="col-12 text-center">
          <h3><strong>¡Felicidades!</strong></h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center">
          <h4>Tus datos y tu contraseña han sido actualizados correctamente.</h4>
        </div>
      </div>
      <div class="row">
        <div class="col-12 text-center mb-5">
          <img class="w-50" src="https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/aliwell-step-final-case.svg" />
        </div>
      </div>
    </div>
  </app-dialog>
</ng-container>

