<div class="h-100 position-relative">
  <div>
    <mat-icon (click)="downloadFile()" class="downlad-icon" *ngIf="fileSelected && downloadFilePreview" matTooltip="Descargar" matTooltipPosition="below" style="z-index: 15;">system_update_alt</mat-icon>
    <mat-icon (click)="deleteFile()" class="delete-icon" *ngIf="fileSelected" matTooltip="Eliminar" matTooltipPosition="below" style="z-index: 15;">close</mat-icon>
  </div>
  <mat-card
    appDragAndDrop2
    (filesDropped)="uploadFiles($event)"
    class="p-4 drop-area card-general"
    (click)="!fileSelected && !isReadOnly && openFileBrowser()"
    >
    <div [formGroup]="parentForm" [hidden]="isFileSelected || fileSelected" class="file-upload-container">
      <mat-icon style="transform: scale(1.4);">save_alt</mat-icon>
      <p>{{text}}</p>
      <input type="file" #fileInput [required]="isRequired" (change)="changeFileListener($event)" class="cdk-visually-hidden" [accept]="accept">
    </div>
    <div class="mx-auto h-100 d-flex justify-content-center align-items-center" *ngIf="isFileSelected && !fileSelected" style="width: fit-content;">
      <app-loading></app-loading>
    </div>
    <mat-card-container *ngIf="fileSelected">
      <img mat-card-image src="{{fileSelected.url}}" *ngIf="!isGenericFileType; else STLTemplate" alt="{{fileSelected.name}}" class="img-fluid w-auto h-100 mx-auto">
      <ng-template #STLTemplate>
        <div class="h-100 d-flex justify-content-center align-items-center">
          <mat-icon>task</mat-icon>
          <p>Archivo guardado</p>
        </div>
      </ng-template>
    </mat-card-container>

  </mat-card>

  <p class="text-center" [ngClass]="{'p-w-elipsis': textWElipsis}" [matTooltip]="textWElipsis ? fileTitle : ''" style="color: var(--dark-color);">{{fileTitle}}{{isRequired?'*':''}}</p>
</div>
