import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FileHandle } from '../../base/AppInterfaceAndBaseClasses';

@Component({
  selector: 'app-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.css']
})
export class FileSelectorComponent implements OnInit
{

  @ViewChild('fileInput', { static: true }) fileInput: ElementRef;
  @Input() text: string;

  @Output() onSelectFiles = new EventEmitter<FileHandle[]>();

  public textArea = "Arrastra y suelta las imagenes";

  private files: FileHandle[] = [];

  ngOnInit(): void
  {
  }

  public changeFileListener(event): void
  {
    this.uploadFiles(event.target.files);
  }

  public uploadFiles(filesList): void
  {
    this.files = [];
    for (let i = 0; i < filesList.length; i++)
    {
      this.files[i] = {
        file: filesList[i],
        url: ''
      };
      this.getFileUrl(filesList[i], i);
    }

    this.fileInput.nativeElement.value = '';
    this.onSelectFiles.emit(this.files);
  }

  public openFileBrowser(): void
  {
    this.fileInput.nativeElement.click();
  }

  private getFileUrl(file: File, index: number)
  {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (events:any) =>
    {
      this.files[index].url = events.target.result;
    };
  }
}
