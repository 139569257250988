<div
  class="drop-area d-flex flex-column gap-2 align-items-center justify-content-center flex-fill"
  appDragAndDrop
  (filesDropped)="uploadFiles($event)"
  >
  <i class="fa fa-upload fs-xxl opacity-50"></i>
  <p>{{textArea}}</p>
  <span>O</span>
  <input type="file" #fileInput (change)="changeFileListener($event)" class="cdk-visually-hidden" multiple>
  <button (click)="openFileBrowser()">
    {{text}}
  </button>
</div>
