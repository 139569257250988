<header>
  <div class="header-container container d-flex align-items-center h-100">
		<div class="header-content w-100">
			<h2>Inicia tu <span>tratamiento</span></h2>
		</div>
	</div>
</header>
<main>
  <section class="container-lg">
    <div class="d-flex flex-column justify-content-center align-items-center gap-4 mb-4">
      <h3 class="title-page text-center">Encuentra un <span>Ortodoncista</span></h3>
    </div>
    <!-- WHATSAPP METHOD -->
    <article class="d-flex flex-column justify-content-center align-items-center mx-auto text-center gap-3">
      <p class="content-page text-center w-50 mx-auto">Comunícate via WhatsApp y uno de nuestros agentes te ayudara inmediatamente. <a routerLink="." fragment="whatsapp">Haz click en el icono de WhatsApp</a></p>
      <div id="whatsapp" class="whatsapp-container d-flex align-items-center justify-content-center">
          <a href="https://api.whatsapp.com/send?phone=+5218186834840&text=Hola,%20me%20gustar%C3%ADa%20conocer%20mas%20informaci%C3%B3n%20acerca%20de%20sus%20servicios.">
              <i class="fa fa-whatsapp"></i>
          </a>
      </div>
    </article>

    <div class="separator">
      <div class="separator--content"></div>
    </div>

    <div class="d-flex flex-column justify-content-center align-items-center gap-4 mb-4">
      <p class="content-page text-center w-50">Ingresa tus datos y en menos de 24 horas te enviaremos las mejores opciones de ortodoncistas cerca de tí</p>
    </div>

    <!-- REQUEST FORM -->
    <div class="m-auto p-3 bg-white" style="width: 60%; box-shadow: 0 2px 6px #abc2d4; border-radius: 1rem;">
      <ng-container>
        <div class="d-flex justify-content-center">
          <!-- MULTI STEPPER -->
          <mat-horizontal-stepper #stepper linear class="w-100 h-100">
            <!-- PERSONAL INFORMATION STEP -->
            <mat-step label="Datos" [stepControl]="personalDataForm" [editable]="editable" class="h-100 w-100 m-0 p-0" state="data">
              <div class="text-center">
                <h2>Datos personales</h2>
                <p class="step-description">Ingresa toda la información requerida en el formulario</p>
              </div>
              <form [formGroup]="personalDataForm" class="d-flex flex-column justify-content-center align-items-center">
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-user mr-2">&nbsp;</span>
                  <input matInput placeholder="Nombre completo" name="fullName" id="fullName" required formControlName="fullName">
                  <mat-error>{{getErrorMessage('fullName')}}</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-envelope mr-2">&nbsp;</span>
                  <input matInput placeholder="Correo electrónico" name="email" id="email" required email formControlName="email">
                  <mat-error>{{getErrorMessage('email')}}</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                  <span matPrefix class="fa fa-phone mr-2">&nbsp;</span>
                  <input matInput placeholder="Teléfono" name="phone" id="phone" required phone formControlName="phone" phoneNumberOnly maxlength="10">
                  <mat-error>{{getErrorMessage('phone')}}</mat-error>
                </mat-form-field>
                <div class="w-100 d-flex flex-column flex-md-row gap-md-3">
                  <mat-form-field class="w-100">
                    <span matPrefix class="fa fa-location-arrow mr-2">&nbsp;</span>
                    <input matInput placeholder="Código postal" name="zipcode" id="zipcode" required zipcode formControlName="zipcode" phoneNumberOnly maxlength="5">
                    <mat-error>{{getErrorMessage('zipcode')}}</mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-100">
                    <span matPrefix class="fa fa-map-marker mr-2">&nbsp;</span>
                    <mat-label>Selecciona un estado</mat-label>
                    <mat-select matInput name="state" id="state" required state formControlName="state">
                      <mat-option *ngFor="let state of States" value="{{state.value}}">{{state.name}}</mat-option>
                    </mat-select>
                    <mat-error>{{getErrorMessage('state')}}</mat-error>
                  </mat-form-field>
                </div>
                <mat-form-field class="w-100">
                  <textarea matInput placeholder="Comentarios" name="comments" id="comments" comments formControlName="comments" style="height: 120px;"></textarea>
                </mat-form-field>
                <div>
                  <button mat-button matStepperNext class="button primary-button">Siguiente</button>
                </div>
              </form>
            </mat-step>
            <!-- PHOTOS STEP -->
            <mat-step label="Fotos" [stepControl]="photosForm" [editable]="editable" state="photo">
              <div class="h-100 w-100 m-0 p-0 d-flex flex-column justify-content-center align-items-center gap-4">
                <div class="text-center">
                  <h2>Fotos del caso</h2>
                  <p class="step-description">Envianos fotos de tu caso para darte una atención más personalizada (Opcional). <a href="#">Ver ejemplo de fotos</a></p>
                </div>
                <form class="d-flex flex-column justify-content-center gap-4 w-100">
                  <app-file-selector
                    text="Selecciona tus archivos"
                    (onSelectFiles)="onSelectedFiles($event)"
                  ></app-file-selector>
                  <div class="row row-cols-2">
                    <div class="col d-flex align-items-center gap-2"
                      *ngFor="let file of selectedFiles, index as i;"
                      #filesContainer
                    >
                      <i class="fa fa-times-circle" style="cursor: pointer;" (click)="onDeleteFile(i)"></i>
                      <img id="imgUpload-{{i}}" class="flex-shrink-1" src="{{file.url}}" style="width: auto;" height="50px" alt="">
                      <p class="flex-grow-1">{{file.file.name}}</p>
                    </div>
                  </div>
                  <div class="align-self-center d-flex w-100 gap-3">
                    <button mat-button matStepperPrevious class="button secondary-button flex-fill"><i class="fa fa-angle-left"></i> Regresar</button>
                    <button mat-button (click)="onSendRequest()" class="button primary-button flex-fill">Enviar</button>
                  </div>
                </form>
              </div>

            </mat-step>
            <!-- Complete Photos -->
            <mat-step label="Completado" class="h-100 w-100 m-0 p-0" state="check">
              <div class="text-center">
                <h2>¡Gracias por confiarnos tu sonrisa!</h2>
                <p class="step-description">Pronto uno de nuestros especialistas Aliwell se pondr&aacute; en contacto contigo para iniciar tu tratamiento</p>
                <button class="button primary-button" (click)="onHandleNewRequest()">Crear nueva petici&oacute;n</button>
              </div>
            </mat-step>
            <!-- Icon Overrides -->
            <ng-template matStepperIcon="data">
              <i class="fa fa-edit"></i>
            </ng-template>
            <ng-template matStepperIcon="photo">
              <i class="fa fa-camera"></i>
            </ng-template>
            <ng-template matStepperIcon="check">
              <i class="fa fa-check"></i>
            </ng-template>
          </mat-horizontal-stepper>
        </div>
      </ng-container>
    </div>
  </section>
</main>
