<div
  class="panel-header d-flex justify-content-between align-items-center py-3 px-4 pinter"
  [ngClass]="{ isOpen: isOpen}"
  (click)="isOpen = !isOpen"
>
  <h4 class="fs-lg m-0">{{item.question}}</h4>
  <i class="fa fs-xl" [ngClass]="isOpen ? 'fa-angle-down' : 'fa-angle-up'"></i>
</div>
<div [@slideDown]="isOpen ? 'open' : 'closed'"  [ngClass]="{ isOpen: isOpen}" class="panel-description d-flex justify-content-start align-items-center">
  <p class="fs-md" [innerHTML]="item.answer | safe: 'html'"></p>
</div>
