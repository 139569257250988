import { Component, HostListener, Input, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ClinicDataService } from '../../../../app-client/_dataservices/ClinicDataService';
import { CustomerDataService } from '../../../../app-client/_dataservices/CustomerDataService';
import { AppClientContext } from '../../../_appCommon/base/AppClientContext';
import { AppEventNames, ECOMMERCE_USER_TOKEN, STATES } from '../../../_appCommon/base/Constants';
import { ControllerBase } from '../../../_appCommon/base/ControllerBase';
import { AuthenticationService } from '../../../_dataservices/security/AuthenticationService';
import { Router } from '@angular/router';
import { BroadcastManager } from '../../../_appCommon/base/BroadcastManager';
import { IncompleteUsersDataService } from '../../../../app-client/_dataservices/IncompleteUsersDataService';
import { CustomerStatus } from 'src/app-admin/_appCommon/base/AppInterfaceAndBaseClasses';
import { LoadingService } from '../../../../shared/services/loading.service';


@Component({
  selector: 'app-onboarding-crud',
  templateUrl: './onboardingCRUD.component.html',
  styleUrls: ['./onboardingCRUD.component.css'],
  providers: [CustomerDataService, AuthenticationService, ClinicDataService, IncompleteUsersDataService]
})

export class OnboardingCRUDComponent extends ControllerBase
{

  public specialtyInOrthodonticsNo: boolean = false;
  public specialtyInOrthodonticsYes: boolean = false;

  public showModal = true;
  public successMessage = false;

  public customerObject: any;
  public clinicObject: any;

  public currentEntity: any;

  public size700_1020 = false;

  public States = STATES;

  public doctorForm: FormGroup;
  public clinicForm: FormGroup;

  public userId: any;
  public customerId: any;
  public clinicId: any;

  @Input() public currentPassword: string = "";
  @Input() public loginId: string = "";

  @ViewChild('stepper') private myStepper: MatStepper;


  constructor(private _formBuilder: FormBuilder,
    private customerDataService: CustomerDataService,
    private authenticationService: AuthenticationService,
    private clinicDataService: ClinicDataService,
    private incompleteUsersDataService: IncompleteUsersDataService,
    private router: Router,
    private loadingService:LoadingService)
  {
    super();
  }

  ngOnInit(): void
  {
    super.ngOnInit();
    this.initializeForms();
    this.doLogin();

  }
  /**
   * Method used to initialize the customer object and data
   */
  private initializeCustomer(response: any)
  {
    this.customerObject =
    {
      id: this.customerId,
      name: response.name,
      lastName: response.lastName,
      secondLastName: response.secondLastName,
      email: response.email,
      phone: response.phone,
      hasOrthodonticsSpecialty: response.hasOrthodonticsSpecialty,
      customerTypeId: response.customerTypeId,
      customerLevelId: response.customerLevelId,
      rightsAgreementId: null,
      promiseLetterId: null,
      fiscalDocumentId: null,
      isActive: true,
      customerStatus : CustomerStatus.Activo,
    };
    if (response.hasOrthodonticsSpecialty)
    {
      this.specialtyInOrthodonticsYes = true;
      this.specialtyInOrthodonticsNo = false;
    }
    else
    {
      this.specialtyInOrthodonticsYes = false;
      this.specialtyInOrthodonticsNo = true;
    }
  }
  /**
   * Method used to initialize the clinic data
   */
  private initializeClinic()
  {
    this.clinicDataService.getClinicByCustomerId(this.customerId).subscribe((response: any) =>
    {
      response;
      this.clinicObject =
      {
        id: response.id,
        code: response.code,
        name: response.name,
        description: response.description,
        contactName: response.contactName,
        zipCode: response.zipCode,
        phone: response.phone,
        street: response.street,
        number: response.number,
        subnumber: response.subnumber,
        city: response.city,
        state: response.state, //this.currentEntity.state,
        website: response.website,
        socialMedia: response.socialMedia,
        comments: response.comments,
        isActive: response.isActive,
        customerId: response.customerId,
        isDefault: response.isDefault
      };
    }, (fail: any) =>
    {
      this.setDangerAlert(fail.error.message);
    });
  }
  /**
   * Method used to retrieve the user id
   */
  private getData(): void
  {
    this.authenticationService.getCustomerByLoginId(this.loginId).subscribe((response: any) =>
    {
      this.userId = response.userId;
      this.customerId = response.id;
      this.initializeCustomer(response);
      this.initializeClinic();
    }, (fail: any) =>
    {
      this.setDangerAlert(fail.error.message);
    });
  }
  /**
   * Method used to intialize the forms.
   * Doctor and clinic form
   */
  private initializeForms(): void
  {
    this.doctorForm = this._formBuilder.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      secondLastName: [null, Validators.required],
      email: [null, Validators.required],
      phone: [null, Validators.required],
      specialtyInOrthodontics: [null, ""]
    });
    this.clinicForm = this._formBuilder.group({
      clinicName: [null, Validators.required],
      street: [null, Validators.required],
      clinicNumber: [null, Validators.required],
      clinicSubNumber: [null, ""],
      city: [null, Validators.required],
      state: [null, Validators.required],
      clinicZipCode: [null, Validators.required],
      contactName: [null, Validators.required],
      clinicPhone: [null, Validators.required],
      website: [null, ""],
      socialMedia: [null, ""]
    });
  }

  /**
   * Method used to handle the first form validation
   */
  public goForward(): void
  {
    if (this.doctorForm.valid)
    {
      this.customerDataService.updateCustomer(this.customerObject).subscribe((response: any) =>
      {
        this.setSuccessAlert("Información del cliente actualizada correctamente");
        this.myStepper.next();
      }, (fail: any) =>
      {
        this.setDangerAlert(fail.error.message);
      });

    }
    else
    {
      this.doctorForm.markAllAsTouched();
    }
  }

  /**
   * Method used to validate both forms and save changes!
   */
  public saveNow(): void
  {
    if (this.doctorForm.valid && this.clinicForm.valid)
    {
      this.clinicDataService.updateClinic(this.clinicObject).subscribe((response: any) =>
      {
        this.setInfoAlert("Información de clínica actualizada correctamente");

        let body =
        {
          UserId: this.userId
        };
        this.incompleteUsersDataService.deleteIncompleteUser(body).subscribe((response: any) =>
        {
          this.successMessage = true;
          //Refresh Storage
          const loginData =
          {
            Email: this.loginId,
            Password: this.currentPassword
          };
          this.authenticationService.login(loginData).subscribe((loginResponse: any) =>
          {
            //Refresh Storage
            this.goToECommerce();
          }, (fail: any) =>
          {
            this.setDangerAlert("Hubo un problema al refrescar sus datos");
          });


        }, (fail: any) =>
        {
          this.setDangerAlert(fail.error.message);
        });


      }, (fail: any) =>
      {
        this.setDangerAlert(fail.error.message);
      });
      this.successMessage = true;
    }
    else
    {
      this.doctorForm.markAllAsTouched();
      this.clinicForm.markAllAsTouched();
    }
  }
  /**
   * Methods used to detect the changes in the radio groups
   */
  public specialtyInOrthodonticsYesChanged()
  {
    this.customerObject.hasOrthodonticsSpecialty = true;
  }

  public specialtyInOrthodonticsNoChanged()
  {
    this.customerObject.hasOrthodonticsSpecialty = false;
  }

  /**
   * Method used to login the current user!
   */
  private doLogin():void
  {
    const loginData =
    {
      Email: this.loginId,
      Password: this.currentPassword
    };
    this.authenticationService.login(loginData).subscribe((response: any) =>
    {
      if (this.getUserLoginFromCache())
      {
        this.setSuccessAlert('¡Bienvenido!');
        let bodyIncompleteUser =
        {
          loginId: loginData.Email
        };
        this.authenticationService.checkIncompleteUser(bodyIncompleteUser).subscribe((isIncompleteUser: any) =>
        {
          if (isIncompleteUser)
          {
            this.getData();
          }
          else
          {
            this.goToECommerce();
          }
        }, (fail: any) =>
        {
          this.setDangerAlert(fail.error.message);
        });

      }
    }, exception =>
    {
      this.setDangerAlert("Inicio de sesión: " + exception.error.message);
    });
  }
  private getUserLoginFromCache(): boolean
  {
    let user = JSON.parse(localStorage.getItem('currentUser'));
    return this.setSecurityToken(user);
  }
  private setSecurityToken(user: any): boolean
  {
    if (user && user.cacheToken)
    {
      // Remove Token
      AppClientContext.Instance.headerValues.delete(ECOMMERCE_USER_TOKEN);

      // Append Token
      AppClientContext.Instance.headerValues.set(ECOMMERCE_USER_TOKEN, user.cacheToken);
      return true;
    }
    return false;
  }

  /**
   * Method used to redirect the user to the E-Commerce after 5 seconds
   */
  private goToECommerce(): void
  {
    this.setInfoAlert("Redireccionando...");

    this.loadingService.hide();
      let event =
      {
        show: false,
        loginId: "",
        password: ""
      };
      BroadcastManager.Instance.broadcast(AppEventNames.OnboardingCRUDData, event);
      this.router.navigate(['/ecommerce']);
  }
  /*
   * Methods used to detect the screen resolution */
  @HostListener('window:resize', ['$event'])
  onResize(event)
  {
    this.checkResolution();
  }
  private checkResolution(): void
  {
    if (window.innerWidth < 700 && window.innerHeight < 1020)
    {
      this.size700_1020 = true;
    }
    else
    {
      this.size700_1020 = false;
    }
  }
}

