import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingGeneralComponent } from './loading-general.component';



@NgModule({
  declarations: [LoadingGeneralComponent],
  imports: [
    CommonModule
  ], exports:[LoadingGeneralComponent]
})
export class LoadingGeneralModule { }
