import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, share } from 'rxjs/operators';
import { AppClientContext } from '../../_appCommon/base/AppClientContext';
import { EntityDataServiceBase } from '../../_appCommon/base/EntityDataServiceBase';
import { EntityConfig } from '../../_appCommon/base/EntityConfig';
import { UrlQueryDef } from '../../_appCommon/base/UrlQueryDef';
import { ECOMMERCE_USER_TOKEN } from 'src/app/_appCommon/base/Constants';
import { Observable } from 'rxjs';

@Injectable()
export class AuthenticationService extends EntityDataServiceBase
{
  constructor(protected http: HttpClient)
  {
    super(http, "development");
  }

  protected loadEntityConfig(): void
  {
    // Create Entity Config
    this.entityConfig = new EntityConfig();

    this.entityConfig.canEdit = true;
    this.entityConfig.canRead = true;
    this.entityConfig.entityName = "userAccess";
    this.entityConfig.entityDisplayName = "userAccess";
    this.entityConfig.apiUrl = "api/userAccess";
  }

  public login(loginData: { Email: string, Password: string })
  {
    let queryDef = new UrlQueryDef();

    return this.http.post(this.createUrl('login'), loginData, queryDef.getRequestConfig(true))
      .pipe(map((response: any) =>
      {
        let user = response;

        if (user && user.cacheToken)
          localStorage.setItem('currentUser', JSON.stringify(user));
      }));
  }

  public logout()
  {
    this.http.post(this.createUrl('logout'), {Token: AppClientContext.Instance.headerValues.get(ECOMMERCE_USER_TOKEN)});

    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');

    // Remove Token
    AppClientContext.Instance.headerValues.delete(ECOMMERCE_USER_TOKEN);

    // Clear current user
    AppClientContext.Instance.user = null;
  }

  public forgotPassword(loginId: string)
  {
    const observable = this.http.post(this.createUrl(`forgotMyPassword/${loginId}`), {loginId: loginId, UserType:2}).pipe(share());

    return observable;
  }

  public forgotPasswordOnboarding(loginId: string)
  {
    const observable = this.http.post(this.createUrl(`forgotMyPasswordOnboarding/${loginId}`), { loginId: loginId }).pipe(share());

    return observable;
  }

  public changePassword(changePasswordDto: { LoginId: string, OldPassword: string, NewPassword: string })
  {
    let queryDef = new UrlQueryDef();

    const observable = this.http.post(this.createUrl('changePassword'), changePasswordDto).pipe(share());

    return observable;
  }

  public validateUser(loginId: string)
  {
    let queryDef = new UrlQueryDef();

    const observable = this.http.get(`api/CustomerAccess/getCustomerByLoginId/${loginId}`, queryDef.getRequestConfig(true)).pipe(share());

    return observable;
  }

  public checkPasswordResetRequired(body: any): Observable<object>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.post("api/CustomerAccess/getPasswordResetRequired/", body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public checkIncompleteUser(body: any): Observable<object>
  {
    let queryDef = new UrlQueryDef();
    let observable: Observable<any> = this.http.post("api/CustomerAccess/checkIncompleteUser/", body, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }

  public getCustomerByLoginId(loginId: string): Observable<object>
  {
    let queryDef = new UrlQueryDef();
    let observable = this.http.get(`api/CustomerAccess/getCustomerByLoginId/${loginId}`, queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }
  public loginNoLoginCount(loginData: { Email: string, Password: string }): Observable<object>
  {
    let queryDef = new UrlQueryDef();
    let observable = this.http.post(this.createUrl('loginNoLoginCount'), loginData,queryDef.getRequestConfig(true)).pipe(share());
    this._handleObservableResponse(observable);
    return observable;
  }
}

