import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-access',
  templateUrl: './user-access.component.html',
  styleUrls: ['./user-access.component.css']
})

export class UserAccessComponent implements OnInit
{
  @Input() public isShowLogin = true;
  @Input() public byPassEmailPasswordReset = false;
  public showLogin = false;

  constructor(private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void { }

}

