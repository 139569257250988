import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PasswordErrorMessage, PasswordValidationType } from '../../../_appCommon/base/AppInterfaceAndBaseClasses';
import { BroadcastManager } from '../../../_appCommon/base/BroadcastManager';
import { AppEventNames } from '../../../_appCommon/base/Constants';
import { ControllerBase } from '../../../_appCommon/base/ControllerBase';
import { AuthenticationService } from '../../../_dataservices/security/AuthenticationService';

@Component({
  selector: 'app-onboarding-password-reset',
  templateUrl: './passwordReset.component.html',
  styleUrls: ['./passwordReset.component.css'],
  providers: [AuthenticationService]
})

export class PasswordResetComponent extends ControllerBase
{
  @Input() public passwordKey: string = "";
  @Input() public loginId: string = "";

  public showModal = true;
  public hide = true;

  public changePasswordForm: FormGroup;

  public newPasswordError = "";
  private passwordErrorType: Map<PasswordValidationType, string>;

  public finalPassword: string = "";

  constructor(private _formBuilder: FormBuilder,
              private authenticationService: AuthenticationService)
  {
    super();
  }

  ngOnInit(): void
  {
    this.initializeForm();
    this.passwordErrorType = PasswordErrorMessage;
  }
  /**
   * Method used to initialize the only form
   */
  public initializeForm(): void
  {
    this.changePasswordForm = this._formBuilder.group({
      confirmNewPassword: [null, Validators.required],
      newPassword: [null, Validators.required]
    });
  }
  /**
   * Method used to process the new password
   *
   */
  public changePassword(): void
  {
    if (this.changePasswordForm.valid && this.validatePasswordEqual())
    {
      const resetPasswordData =
      {
        LoginId: this.loginId,
        OldPassword: this.passwordKey,
        NewPassword: this.changePasswordForm.get('newPassword').value,
      };
      this.authenticationService.changePassword(resetPasswordData).subscribe((response: any) =>
      {
        this.setSuccessAlert("Contraseña cambiada correctamente");
        this.finalPassword = resetPasswordData.NewPassword;
        this.onHandleEmailPasswordChange();
      }, (fail: any) =>
      {
        this.setDangerAlert(fail.error.message);
      });

    }
    else
    {
      this.setWarningAlert("Completa todos los cambios");
      this.changePasswordForm.markAllAsTouched();
    }

  }
  /**
   * Method used to validate if the passwords are equal
   */
  public validatePasswordEqual(): boolean
  {
    if (this.changePasswordForm.get('newPassword').value == this.changePasswordForm.get('confirmNewPassword').value)
    {
      return true;
    }
    else
    {
      this.changePasswordForm.controls["confirmNewPassword"].setErrors({
        validateEqual: false
      });
      return false;
    }
  }
  /**
   * Method used to validate the user password
   */
  public validatePassword(valueInput: string)
  {
    this.newPasswordError = "";
    this.passwordErrorType.forEach(
      (value, key) =>
      {
        if (!new RegExp(key).test(valueInput))
        {
          this.newPasswordError = value;
        }
      }
    );
    if (this.newPasswordError != "") this.changePasswordForm.controls['newPassword'].setErrors({ 'incorrect': true });
  }
  /**
   * Method used to show up the CRUD onboarding data
   */
  public onHandleEmailPasswordChange(): void
  {
    let event1 =
    {
      show: true,
      loginId: this.loginId,
      password: this.finalPassword
    };
    let event2 =
    {
      show: false,
      key: "",
      loginId: ""
    };
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingCRUDData, event1);
    BroadcastManager.Instance.broadcast(AppEventNames.OnboardingPasswordChange, event2);
  }
}

