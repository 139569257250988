import { SafeUrl } from "@angular/platform-browser";
import "./../util/ArrayExtensions";

// ********************************************************************************
// #region Classes
// ********************************************************************************

export class GlobalConstants
{
  public static altProductImagePath: string = "https://nf.aaoinfo.org/eweb/images/DEMO1/notavailable.jpg";
}

export interface Product {
  id: number;
  code: string;
  name: string;
  description: string;
  isDeleted: boolean;
  isActive: boolean;
  categoryId: number;
  categoryName: string;
  supplierId: number;
  supplierName: string;
  price: number;
  tax: number;
  priceString: string;
  alignersQuantity: number;
  hasDentalMonitoring: boolean;
  hasUnlimitedAligners: boolean;
  dentalMonitoringPrice: number;
  isCase: boolean;
  imagePath: string;
  images: any;
  specList: any;
  priceList: any;
  quantityStock: number;
  baseTax: number;
  basePriceProduct: number;
  basePriceExtraAligner: number;
  basePriceDentalMonitoring: number;
  basePriceComplexCase: number;
  productType: any;
  priceToShow: any;
  productPriceToShowWithDiscount:any
}

export interface ShoppingProduct {
  productId: number,
  productName: string,
  productImage: string,
  quantity: number,
  quantityExtraAligners: number,
  hasDentalMonitoring: boolean,
  priceDentalMonitoring: number,
  isComplexCase: boolean,
  subtotalProduct: number,
  subtotal: number,
  baseTax: number,
  basePriceProduct: number,
  basePriceExtraAligner: number,
  basePriceDentalMonitoring: number,
  caseId?: number
}

export interface ShoppingService {
  serviceName: string;
  serviceCount: number;
  serviceUnitPrice: number;
  serviceTotal: number;
  serviceImage: string;
  serviceNewSetup: number;
  serviceProductUpgrade: any;
}

export interface IEntity
{
  id: number;
}

export class LocalStorage implements Storage
{
  [name: string]: any;
  readonly length: number;
  clear(): void { }
  getItem(key: string): string | null { return undefined; }
  key(index: number): string | null { return undefined; }
  removeItem(key: string): void { }
  setItem(key: string, value: string): void { }
}

export class UserDto
{

}

export class LoginDto
{
  id: string = "";
  password: string = "";
  cartId: string = "";
  language: string = "";
}

export class RefreshTokenDto
{
  language: string = "";
  refreshToken: string = "";
}

export class ValidateSessionDto
{
  language: string = "";
  token: string = "";
}

// #endregion Classes

// ********************************************************************************
// #region Interfaces
// ********************************************************************************

export interface IEntity
{
  id: number;
}

export interface IBaseRequest
{
  token: string;
}

//Add base interfaces
export interface FileHandle {
  file: File,
  url: SafeUrl
}

export interface UserAccess {
  email: string,
  password: string
}

export interface UserAccessResetPassword {
  newPassword: string,
  confirmPassword: string
}

export interface UserSignUpSpecialist {
  name: string,
  firstLastName: string,
  secondLastName: string,
  hasSpecialty: string,
  professionalLicense: string,
  email: string,

  officeName: string,
  contactPerson: string,
  country: string,
  state: string,
  phone: number,
  street: string,
  streetNumber: number,
  city: string,
  zipcode: number,
  website: string,
  socialMedia: string,
}

export interface UserSignUpResident {
  name: string,
  firstLastName: string,
  secondLastName: string,
  hasSpecialty: string,
  graduationYear: number,
  graduationMonth: number,
  email: string,

  officeName: string,
  contactPerson: string,
  country: string,
  state: string,
  phone: number,
  street: string,
  streetNumber: number,
  city: string,
  zipcode: number,
  website: string,
  socialMedia: string,
}

// #endregion Interfaces


// Enums
export enum FileApplicationType
{
  PDF = 'application/pdf',
  STL = 'application/octet-stream',
  TXT = 'application/plain',
  ZIP7 = 'application/x-7z-compressed',
  ZIP = 'application/zip',
  RAR = 'application/x-rar-compressed',
  WORD = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  POWERPOINT = 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  EXCEL = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
}

export enum ImageType
{
  JPG = 'image/jpg',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
  TIF = 'image/tiff',
  BMP = 'image/bmp'
}

export enum VideoType
{
  MP4 = 'video/mp4',
  QUICKTIME = 'video/quicktime',
  MOV = 'video/mov',
  MPG = 'video/mpg',
  MPEG = 'video/mpeg',
  MPEG4 = 'video/mpeg4',
}


export enum FileExtension//Agregar nuevos formatos de archivo
{
  PDF = '.pdf',
  TXT = '.txt',
  ZIP = '.zip',
  ZIP7 = '.7z',
  RAR = '.rar',
  WORD = '.docx',//
  POWERPOINT = '.pptx',//
  EXCEL = '.xlsx',//
}

export enum FileWeirdExtension
{
  STL = '.stl',
}

export enum ImageExtension
{
  JPG = '.jpg',
  JPEG = '.jpg',
  PNG = '.png',
  GIF = '.gif',
  TIF = '.tif',
  BMP = '.bmp'
}

export enum Section
{
  Backoffice = 1,
  Ecommerce = 2
}

export enum PasswordValidationType
{
  AtLeastOneNumber = '[0-9]{1}',
  AtLeastOneCapital = '[A-Z]{1}',
  AtLeastOneSpecial = '[#$%&?!\\¡\\¿\\`\\@\\^\\*\\(\\)\\_\\+\\-\\=\\[\\]\\{\\}\\;\'\\:\\"\\|\\,\\.\\<\\>\\/\\~\\u005C]{1}',
  NoConsecutive = '^(?!.*(abc|ABC|123)+).*$',
  NoKeyboardSequences = '^(?!.*(qwerty|QWERTY|ASDFG|asdfg)+).*$',
  MinLength = '[A-Z0-9a-z#$%&?!\\¡\\¿\\`\\@\\^\\*\\(\\)\\_\\+\\-\\=\\[\\]\\{\\}\\;\'\\:\\"\\|\\,\\.\\<\\>\\/\\~\\u005C]{8,}$',
}

export enum PaymentType
{
  Unasigned =0,
  OpenPay = 1,
  PayNet = 2,
  SPEI = 3,
  InPerson = 5,
  PayLink = 6,
  Courtesy = 7,
  CardPay = 8
}

export enum PaymentPlan
{
  SinglePayment = 1,
  TwoMonths = 3,
  SixMonths = 6,
  NineMonths = 9,
  TwelveMonths = 12,
}

export const PaymentTypeLabel = new Map<PaymentType, string>([
  [PaymentType.Unasigned, "Forma de pago pendiente"],
  [PaymentType.OpenPay, "OpenPay"],
  [PaymentType.PayNet, "PayNet"],
  [PaymentType.SPEI, "SPEI"],
  [PaymentType.InPerson, "InPerson"],
  [PaymentType.PayLink, "PayLink"],
  [PaymentType.Courtesy, "Courtesy"],
  [PaymentType.CardPay, "Pago con terminal"]
]);

export const PaymentTypeDescription = new Map<PaymentType, string>([
  [PaymentType.Unasigned, "Forma de pago pendiente"],
  [PaymentType.OpenPay, "Pago con Tarjeta"],
  [PaymentType.PayNet, "Pago en Establecimientos"],
  [PaymentType.SPEI, "Pago por Transferencia"],
  [PaymentType.InPerson, "Pago presencial"],
  [PaymentType.PayLink, "Pago por Link de pago"],
  [PaymentType.Courtesy, "Cortesía"],
  [PaymentType.CardPay, "Pago con terminal"]
]);

export const PaymentPlanDescription = new Map<PaymentPlan, string>([
  [PaymentPlan.SinglePayment, "De contado"],
  [PaymentPlan.TwoMonths, "Financiamiento a 3 meses"],
  [PaymentPlan.SixMonths, "Financiamiento a 6 meses"],
  [PaymentPlan.NineMonths, "Financiamiento a 9 meses"],
  [PaymentPlan.TwelveMonths, "Financiamiento a 12 meses"],
]);


export enum ShowInSection
{
  None = 0,
  Backoffice = 1,
  Ecommerce = 2,
  Both=3
}

export const PaymentTypeArray: any[] = [
  {
    type: PaymentType.Unasigned,
    label: PaymentTypeLabel.get(PaymentType.Unasigned),
    description: PaymentTypeDescription.get(PaymentType.Unasigned),
    image: "https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/openpay.png",
    showIn: ShowInSection.None
  },
  {
    type: PaymentType.OpenPay,
    label: PaymentTypeLabel.get(PaymentType.OpenPay),
    description: PaymentTypeDescription.get(PaymentType.OpenPay),
    image: "https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/openpay.png",
    showIn: ShowInSection.Ecommerce
  },
  {
    type: PaymentType.PayNet,
    label: PaymentTypeLabel.get(PaymentType.PayNet),
    description: PaymentTypeDescription.get(PaymentType.PayNet),
    image: "https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/paynet.png",
    showIn: ShowInSection.Both
  },
  {
    type: PaymentType.SPEI,
    label: PaymentTypeLabel.get(PaymentType.SPEI),
    description: PaymentTypeDescription.get(PaymentType.SPEI),
    image: "https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/spei.png",
    showIn: ShowInSection.Both
  },
  {
    type: PaymentType.InPerson,
    label: PaymentTypeLabel.get(PaymentType.InPerson),
    description: PaymentTypeDescription.get(PaymentType.InPerson),
    image: "",
    showIn: ShowInSection.Backoffice
  },
  {
    type: PaymentType.PayLink,
    label: PaymentTypeLabel.get(PaymentType.PayLink),
    description: PaymentTypeDescription.get(PaymentType.PayLink),
    image: "https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/Link%20de%20Pago.svg",
    showIn: ShowInSection.Both
  },
  {
    type: PaymentType.Courtesy,
    label: PaymentTypeLabel.get(PaymentType.Courtesy),
    description: PaymentTypeDescription.get(PaymentType.Courtesy),
    image: "",
    showIn: ShowInSection.Backoffice
  },
  {
    type: PaymentType.CardPay,
    label: PaymentTypeLabel.get(PaymentType.CardPay),
    description: PaymentTypeDescription.get(PaymentType.CardPay),
    image: "https://aliwellstorageaccount.blob.core.windows.net/uploads/website-files/body/Pago%20en%20Sucursal.svg",
    showIn: ShowInSection.Ecommerce
  },
];

export const PasswordErrorMessage = new Map<PasswordValidationType, string>([
  [PasswordValidationType.AtLeastOneNumber, "La contraseña debe tener al menos 1 número"],
  [PasswordValidationType.AtLeastOneCapital, "La contraseña debe tener al menos 1 mayúscula"],
  [PasswordValidationType.AtLeastOneSpecial, 'La contraseña debe tener al menos 1 carácter especial'],
  [PasswordValidationType.NoConsecutive, 'No debe contener letras o numeros consecutivos. (abc, 123)'],
  [PasswordValidationType.NoKeyboardSequences, 'No debe contener secuencias del teclado(qwerty, asdfg)'],
  [PasswordValidationType.MinLength, 'La contraseña debe tener al menos 8 caracteres'],
]);

