// OUTSIDE of a Module, thus, global
interface Array<T>
{
  /**
   * Clears the contents of an array
   *  NOTE: This one I wrote (jtp)
   */
  clear(): void;

  /**
   * Returns true if this list contains no mappings.
   * @return {boolean} true if this list contains no mappings.
   */
  isEmpty(): boolean;

  add(value: T): void
  addRange(array: Array<T>): void

  /** Determines whether any element of the array satisfies a condition. */
  any(predicate?: (element: T) => boolean): boolean;

  /** Determines whether the array contains a specified element by using the default equality comparer. */
  contains(value: T): boolean;

  select<TResult>(selector: (element: T, index: number) => TResult): Array<TResult>;

  /** Filters a sequence of values based on a predicate. */
  where(predicate: (element: T, index: number) => boolean): Array<T>;

  /** Returns the first value if present, or null if not. */
  firstOrNull(): T;

  /** Shuffles the array in random order. */
  shuffle(): void;

  // ************************************************************
  // The following are from MDN - polyfills of future Array stuff
  // ************************************************************

  /**
   * The includes() method determines whether an array includes a certain element, returning true or false as appropriate.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/includes
   *
   * Syntax: var boolean = array.includes(searchElement[, fromIndex])
   *
   * Parameters:
   *  searchElement: The element to search for.
   *  fromIndex: Optional.The position in this array at which to begin searching for searchElement.A negative value searches from the end of the array.Defaults to 0.
   *
   * Return value: A Boolean.
   */
  includes(searchElement: any): boolean;

  /**
   * The find() method returns a value in the array, if an element in the array satisfies the provided testing function. Otherwise undefined is returned.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/find
   *
   * Syntax: arr.find(callback[, thisArg])
   *
   * Parameters:
   *  callback: Function to execute on each value in the array, taking three arguments:
   *      element: The current element being processed in the array.
   *      index: The index of the current element being processed in the array.
   *      array: The array find was called upon.
   *
   *  thisArg: Optional.Object to use as this when executing callback.
   */
  find(predicate: (element: T, index: number, array: Array<T>) => boolean): T;

  /**
   * The findIndex() method returns an index in the array, if an element in the array satisfies the provided testing function. Otherwise - 1 is returned.
   * See also the find() method, which returns the value of a found element in the array instead of its index.
   *
   * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/findIndex
   *
   * Syntax: arr.findIndex(callback[, thisArg])
   *
   * Parameters:
   *  callback: Function to execute on each value in the array, taking three arguments:
   *      element: The current element being processed in the array.
   *      index: The index of the current element being processed in the array.
   *      array: The array findIndex was called upon.
   *
   *  thisArg: Optional.Object to use as this when executing callback.
   */
  findIndex(predicate: (element: T, index: number, array: Array<T>) => boolean): number;
}

// ************************************************************
// These are our extensions to Array
// ************************************************************


if (!Array.prototype.clear)
{
  Array.prototype.clear = function ()
  {
    this.length = 0;
  };
}

if (!Array.prototype.isEmpty)
{
  Array.prototype.isEmpty = function ()
  {
    return this.length === 0;
  };
}

if (!Array.prototype.add)
{
  Array.prototype.add = function (value)
  {
    this.push(value);
  };
}

if (!Array.prototype.addRange)
{
  Array.prototype.addRange = function (array)
  {
    array.forEach(a => this.push(a));
  };
}

if (!Array.prototype.any)
{
  Array.prototype.any = function (predicate: any)
  {
    if (this.find(predicate))
    {
      return true;
    }
    else
    {
      return false;
    }
  };
}

if (!Array.prototype.contains)
{
  Array.prototype.contains = function (value)
  {
    let match: boolean = false;

    this.forEach((item: any) =>
    {
      if (item == value)
      {
        match = true;
      }
    });

    return match;
  };
}

if (!Array.prototype.select)
{
  Array.prototype.select = function (selector: any)
  {
    if (typeof selector !== 'function') { throw new TypeError('selector must be a function'); }

    let results: any[] = [];

    let thisArg = arguments[1];

    this.forEach((value: any, index: number) =>
    {
      results.push(
        selector.call(thisArg, value, index)
      );
    });

    return results;
  };
}

if (!Array.prototype.where)
{
  Array.prototype.where = function (predicate)
  {
    if (typeof predicate !== 'function') { throw new TypeError('predicate must be a function'); }

    let results: any[] = [];

    let thisArg = arguments[1];

    this.forEach((item: any) =>
    {
      if (predicate.call(thisArg, item))
      {
        results.push(item);
      }
    });

    return results;
  };
}

if (!Array.prototype.firstOrNull)
{
  Array.prototype.firstOrNull = function ()
  {
    if (this.length == 0) { return null; }
    else { return this[0]; }
  };
}

// ************************************************************
// The following are from MDN - polyfills of future Array stuff
// ************************************************************

if (!Array.prototype.includes)
{
  Array.prototype.includes = function (searchElement: any /*, fromIndex*/)
  {
    'use strict';
    let O = Object(this);
    let len = parseInt(O.length) || 0;
    if (len === 0)
    {
      return false;
    }
    let n = parseInt(arguments[1]) || 0;
    let k;
    if (n >= 0)
    {
      k = n;
    }
    else
    {
      k = len + n;
      if (k < 0) { k = 0; }
    }
    let currentElement;
    while (k < len)
    {
      currentElement = O[k];
      if (searchElement === currentElement ||
        (searchElement !== searchElement && currentElement !== currentElement))
      { // NaN !== NaN
        return true;
      }
      k++;
    }
    return false;
  };
}

if (!Array.prototype.find)
{
  Array.prototype.find = function (predicate: any)
  {
    if (this === null)
    {
      throw new TypeError('Array.prototype.find called on null or undefined');
    }
    if (typeof predicate !== 'function')
    {
      throw new TypeError('predicate must be a function');
    }
    let list = Object(this);
    let length = list.length >>> 0;
    let thisArg = arguments[1];
    let value;

    for (let i = 0; i < length; i++)
    {
      value = list[i];
      if (predicate.call(thisArg, value, i, list))
      {
        return value;
      }
    }
    return undefined;
  };
}

if (!Array.prototype.findIndex)
{
  Array.prototype.findIndex = function (predicate: any)
  {
    if (this === null)
    {
      throw new TypeError('Array.prototype.findIndex called on null or undefined');
    }
    if (typeof predicate !== 'function')
    {
      throw new TypeError('predicate must be a function');
    }
    let list = Object(this);
    let length = list.length >>> 0;
    let thisArg = arguments[1];
    let value;

    for (let i = 0; i < length; i++)
    {
      value = list[i];
      if (predicate.call(thisArg, value, i, list))
      {
        return i;
      }
    }
    return -1;
  };
}

if (!Array.prototype.shuffle)
{
  Array.prototype.shuffle = function ()
  {
    for (let i = this.length - 1; i > 0; i--)
    {
      const j = Math.floor(Math.random() * (i + 1));
      [this[i], this[j]] = [this[j], this[i]];
    }
  };
}
