import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { DefaultLanguage, StorageItems } from '../base/Constants';
import { LocalStorageService } from './local-storage.service';
export class TranslateService
{
  private lang: string = DefaultLanguage.ES;
  private data: any = undefined;
  private langChangeSource$: BehaviorSubject<string> = new BehaviorSubject<string>(DefaultLanguage.ES);

  constructor(protected http: HttpClient,
    protected localstorageService: LocalStorageService) { }

  public onTranslateChange(): Observable<string>
  {
    return this.langChangeSource$.asObservable();
  }

  public get languageCode()
  {
    return this.lang;
  }

  private initTranslation(): Promise<{}>
  {
    return new Promise<{}>((resolve, reject) =>
    {
      this.http.get(`../../../assets/translations/translations.json`)
        .subscribe(
          translation =>
          {
            this.data = Object.assign({}, translation || {});
            resolve(this.data);
          },
          error =>
          {
            this.data = undefined;
            resolve(this.data);
          }
        );
    });
  }

  public initService(): void
  {
    this.initTranslation();
  }

  public use(lang: string): void
  {
    let _stLang = this.lang;

    if (lang !== _stLang)
    {
      _stLang = (lang !== null && lang !== undefined) ? lang : _stLang = DefaultLanguage.ES;
      this.localstorageService.setItem(StorageItems.SelectedLang, _stLang);
      this.lang = _stLang;
      this.langChangeSource$.next(_stLang);
    }
  }

  public getElement(key: string): string
  {
    let message = key;

    if (this.lang != DefaultLanguage.ES && this.data != undefined)
    {
      let lkey = key.toLowerCase();
      message = this.data[lkey] || key;
    }

    return message;
  }
}
