import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-user-onboarding',
  templateUrl: './user-onboarding.component.html',
  styleUrls: ['./user-onboarding.component.css']
})

export class UserOnboardingComponent implements OnInit
{
  @Input() public isShowPasswordEmailReset: boolean = false;
  @Input() public isShowPasswordReset: boolean = false;
  @Input() public isShowCRUD:boolean = false;
  @Input() public passwordKey: string = "";
  @Input() public currentPassword: string = "";
  @Input() public loginId: string = "";


  constructor(private router: Router, private activeRoute: ActivatedRoute) { }

  ngOnInit(): void { }

}

