import { HttpClient, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { AppClientContext } from './AppClientContext';
import { Guard } from '../util/Guard';
import { Tools } from '../util/Tools';
import { ApiServiceBase } from './ApiServiceBase';
import { EntityConfig } from './EntityConfig';
import { IPage, PageResult } from './Pagination';
import { UrlQueryDef } from './UrlQueryDef';

export class EntityDataServiceBase extends ApiServiceBase
{
  public entityConfig: EntityConfig;

  public get_WebApiMethod: string;
  public getById_WebApiMethod: string;
  public insert_WebApiMethod: string;
  public update_WebApiMethod: string;
  public delete_WebApiMethod: string;
  public undelete_WebApiMethod: string;

  // Properties for Domain Specific Entities
  public canRead: boolean;
  public canEdit: boolean;

  public entityDisplayName: string;

  constructor(protected http: HttpClient,
    // MUST specify the entity name in the implementing class - this drives the lookup
    //  of the entity config - which drives the behavior of this service
    public entityName: string)
  {
    super(http);

    Guard.argumentNotBlankOrNull(entityName, "entityName");

    // Get the Entity Config (this allows implementing classes to override this)
    this.loadEntityConfig();

    this.apiUrl = this.entityConfig.apiUrl;
    this.canRead = this.entityConfig.canRead;
    this.canEdit = this.entityConfig.canEdit;

    this.entityDisplayName = this.entityConfig.entityDisplayName;
  }

  protected loadEntityConfig(): void
  {
    Guard.failIfTrue(!AppClientContext.Instance.entityConfigDict, `${this.entityName} entity config dict has not been loaded yet`);

    // Get the Entity Config
    if (!AppClientContext.Instance.entityConfigDict.has(this.entityName))
    {
      Tools.throwException("Missing EntityConfig in ClientContext.entityConfigDict for entity: {0}".format(this.entityName));
    }

    this.entityConfig = AppClientContext.Instance.entityConfigDict.get(this.entityName);
  }

  // Used to temporarily use a different ApiUrl for the entity
  protected useCustomApiUrl(customApiUrlString: string): void
  {
    this.apiUrl = customApiUrlString;
  }

  //Used to to restore the entity's ApiUrl
  protected restoreApiUrl(): void
  {
    this.apiUrl = this.entityConfig.apiUrl;
  }

  public get(queryDef: UrlQueryDef = null, pageSize: number = 100, pageNumber: number = 0): Observable<IPage<any>>
  {
    //Required for pagination
    queryDef = this._getQueryDef(queryDef);

    if (queryDef.pagingActive)
    {
      queryDef.top = pageSize;
      queryDef.skip = pageSize * pageNumber;
      queryDef.params = {
        pageSize,
        pageNumber,
        $count: 'true'
      };
    }
    else { queryDef.params = {}; }

    var subject = this.http.get<PageResult<any>>(this.createUrl(this.get_WebApiMethod), queryDef.getRequestConfig())
      .pipe(
        map((pageResult: any) => ({
          total: pageResult.totalCount,
          rows: pageResult.items,
          pageSize: queryDef.params.pageSize,
          pageNumber: queryDef.params.pageNumber
        } as IPage<any>)),
        share());

    this._handleObservableResponse(subject);

    return subject;
  }

  public getById(id: number, queryDef: UrlQueryDef = new UrlQueryDef()): Observable<{}>
  {
    let observable = this.http.get(this.createUrl(this.getById_WebApiMethod, id), queryDef.getRequestConfig()).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  public update(entity: any, config?: any): Observable<{}>
  {

    let observable = this.http.put(this.createUrl(this.update_WebApiMethod), entity, this.getStandardConfig(config)).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  public insert(entity: any, config?: any): Observable<{}>
  {

    let observable = this.http.post(this.createUrl(this.insert_WebApiMethod), entity, this.getStandardConfig(config)).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  public delete(id: number, config?: any): Observable<{}>
  {
    var observable = this.http.delete(this.createUrl(this.delete_WebApiMethod, id), this.getStandardConfig(config)).pipe(share());

    this._handleObservableResponse(observable);

    return observable;
  }

  // *********************************************************
  // #region Support Methods
  // *********************************************************

  public checkCanRead()
  {
    Guard.failIfFalse(this.canRead, "User is not authorized to Read the {0} entity.".format(this.entityConfig.entityName));
  }

  public checkCanEdit()
  {
    Guard.failIfFalse(this.canEdit, "User is not authorized to Edit the {0} entity.".format(this.entityConfig.entityName));
  }

  public _getQueryDef(queryDef: UrlQueryDef): UrlQueryDef
  {
    if (queryDef == null)
    {
      queryDef = new UrlQueryDef();
    }

    return queryDef;
  }

  protected getStandardConfig(config: any): UrlQueryDef
  {
    if (!config)
    {
      config = { headers: new HttpHeaders({ 'Content-Type': 'application/json' }) };
    }

    return config;
  }

  public getPaginationQueryDef(queryDef: UrlQueryDef = null, pageSize: number = 100, pageNumber: number = 0): UrlQueryDef
  {
    //Required for pagination
    queryDef = this._getQueryDef(queryDef);

    if (queryDef.pagingActive)
    {
      queryDef.top = pageSize;
      queryDef.skip = pageSize * pageNumber;
      queryDef.params = {
        ...queryDef.params,
        pageSize,
        pageNumber,
        $count: 'true'
      };
    }

    return queryDef;
  }

  public getPageValues(observableRequest: Observable<HttpEvent<PageResult<any>>>, queryDef: UrlQueryDef): Observable<IPage<any>>
  {
    return observableRequest
      .pipe(
        map((pageResult: any) => ({
          total: pageResult.totalCount,
          rows: pageResult.items,
          pageSize: queryDef.params.pageSize,
          pageNumber: queryDef.params.pageNumber
        } as IPage<any>)),
        share()
      );
  }

  // #endregion

}
