import { Guard } from './Guard';

export class StringTools
{
  public static isUndefinedOrNull(o: any): boolean
  {
    /* if (angular.isUndefined(o) || o === null)
     {
         return true;
     }
     else
     {
         return false;
     }*/

    return true;
  }

  public static notBlankOrNull(s: string): boolean
  {
    return !StringTools.isBlankOrNull(s);
  }

  public static isBlankOrNull(s: string): boolean
  {
    let isString = typeof s === "string";

    if (s == null || (isString && s.trim().length === 0)) { return true; }
    else { return false; }
  }

  /**
   * Return the original value - which might be a class or property name and splits it into separate words
   */
  public static getHeadingFromFieldName(fieldName: string)
  {
    if (StringTools.isBlankOrNull(fieldName)) { return ""; }

    let output = fieldName
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([a-z])/g, ' $1$2')
      .replace(/([a-z])([0-9])/g, '$1 $2')
      .replace(/([A-Z])([0-9])/g, '$1 $2')
      .replace(/\s/g, "")
      .trim();

    return output;
  }

  /**
   * Return the original value - which might be a class or property name and splits it into separate words,
   *  separated by "-" and all lower case to be used as an Angular component name.
   *  Example: Converts MyComponentName to my-component-name
   */
  public static getComponentNameFromFieldName(fieldName: string)
  {
    if (StringTools.isBlankOrNull(fieldName)) { return ""; }

    let output = StringTools.getHeadingFromFieldName(fieldName)
      .replace(/\s/g, "-")
      .toLowerCase();

    return output;
  }

  public static toCamelCase(name: string): string
  {
    return name[0].toLowerCase() + name.slice(1);
  }

  public static initialToUpper(name: string): string
  {
    return name[0].toUpperCase() + name.slice(1);
  }

  public static initialToLower(name: string): string
  {
    return name[0].toLowerCase() + name.slice(1);
  }

  public static createRandomString(length: number)
  {
    Guard.failIfTrue(length > 20, "length cannot be > 20");
    return Math.random().toString(36).slice(-length);
  }

  public static padNumber(numberToPad: number, minDigits: number): string
  {
    return numberToPad.toLocaleString('en-US',
      {
        style: 'decimal',
        minimumIntegerDigits: minDigits,
        useGrouping: false
      });
  }

  public static replaceAll(str: string, search: string, replace: string): string
  {
    return str.split(search).join(replace);
  }

  public static splice(str: string, index: number, deleteCount: number, stringToInsert: string): string
  {
    let charArray = str.split('');
    charArray.splice(index, deleteCount, stringToInsert);
    return charArray.join('');
  }

  public static formatNumber(input: string, numberOfDecimalPlaces: number): string
  {
    return input;
  }
}
