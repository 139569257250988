import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { LoadingService } from "src/shared/services/loading.service";


@Component({
  selector: 'app-loading-general',
  template: `
    <div class="overlay" [hidden]="!loading" >
      <div class="spinner loading-general my-auto mx-auto"></div>
    </div>`,
  styles: [`
      .loading-general{
      width: 20vw !important; 
      height: 20vw !important;
      border-width: 3vh !important;
      }
  `]
})
export class LoadingGeneralComponent {
  loading: boolean = false;

  constructor(private loadingService: LoadingService) {  }

  ngOnInit(): void {
    this.loadingService.isLoading$
    .subscribe(response => {
      this.loading = response;
    });
  }
}
