import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AppClientContext } from 'src/app-client/_appCommon/base/AppClientContext';
import { ECOMMERCE_USER_TOKEN } from 'src/app/_appCommon/base/Constants';

@Injectable({
  providedIn: 'root'
})
export class AuthPermissionsGuardEcommerce implements CanActivate {
  constructor(private router: Router) {

  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.customerIsLogged();
  }

private getUserLoginFromCache(): boolean
{
  let user = JSON.parse(localStorage.getItem('currentUser'));
  return this.setSecurityToken(user);
}

private setSecurityToken(user: any): boolean
{
  if (user && user.cacheToken)
  {
    // Remove Token
    AppClientContext.Instance.headerValues.delete(ECOMMERCE_USER_TOKEN);

    // Append Token
    AppClientContext.Instance.headerValues.set(ECOMMERCE_USER_TOKEN, user.cacheToken);

    return true;
  }
  return false;
}

 private customerIsLogged(): boolean
  {
    const isUserLogged = AppClientContext.Instance.user;
     if(isUserLogged || this.getUserLoginFromCache())
     {
         return true;
     }
     this.router.navigate(['']);
     return false;
  }
  
}
