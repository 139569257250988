import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-whatsapp',
  templateUrl: './whatsapp-float-icon.component.html',
  styleUrls: ['./whatsapp-float-icon.component.css']
})
export class WhatsappFloatIconComponent implements OnInit
{

  ngOnInit(): void {  }

}
