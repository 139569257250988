import { Directive, HostListener, Injectable, LOCALE_ID } from '@angular/core';
import { DatePipe, registerLocaleData } from '@angular/common';
import { DateAdapter, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
import localeEsMX from '@angular/common/locales/es-MX';
import { APP_DATE_FORMATS } from '../_appCommon/base/Constants';
registerLocaleData(localeEsMX, 'es-MX');

//Format Date
@Injectable()
export class AppDateAdapter extends NativeDateAdapter {
  override format(date: Date, displayFormat: Object): any {
      return new DatePipe('es-MX').transform(date, 'dd/MM/yyyy');
  }
}

@Directive({
  selector: '[calendarValidation]',
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: LOCALE_ID, useValue: 'es-MX' },
  ],
})
export class CalendarValidationDirective {
  //Validations
  private keyBoardCodes: string[] = ["ArrowUp", "ArrowDown", "ArrowRight", "ArrowLeft", "Enter"];

  //Block paste event
  @HostListener('paste', ['$event']) blockPaste(e: KeyboardEvent): void
  {
    e.stopPropagation();
    e.preventDefault();
  }

  //Block cut event
  @HostListener('cut', ['$event']) blockCut(e: KeyboardEvent): void
  {
    e.stopPropagation();
    e.preventDefault();
  }

  //Block keyboard
  @HostListener('keypress', ['$event'])
  onKeyPress(event:any){
    if(this.keyBoardCodes.find(code => code == event.code) == undefined) event.preventDefault();
  }
}
