import { Directive, ElementRef, HostListener, OnInit, HostBinding, Renderer2, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[pieceCheckbox]'
})
export class PieceCheckboxDirective implements OnInit, OnChanges
{
  @Input() active :boolean;

  public input;

  @HostListener('click', ['$event'])
  public onHandleDragOver(evt): void
  {
    evt.preventDefault();
    evt.stopPropagation();
    this.active = !this.active;
    this.input.click();
  }

  ngOnInit(): void
  {
    this.input = this.elementRef.nativeElement.children.item(0);
    this.input.checked = this.active;
  }

  constructor(private elementRef: ElementRef, private renderer: Renderer2) {  }

  ngOnChanges(changes: SimpleChanges){
    this.input = this.elementRef.nativeElement.children.item(0);
    this.input.checked = this.active;
  }
  
}
