<footer class="page-footer text-center">
  <div class="p-3 p-lg-4">
    <!-- CONTAINER -->
    <div class="footer-container row d-flex gap-4 gap-md-2">
      <!-- SOCIAL -->
      <div class="col-md d-flex flex-column justify-content-center align-items-center align-items-md-start gap-3">
        <img src="../../../assets/logo.png" alt="Logo Aliwell">
        <div class="d-flex gap-3">
          <div class="social-icon">
            <a href="https://www.facebook.com/aliwell.smiles" target="_blank">
              <i class="fa fa-facebook-official"></i>
            </a>
          </div>
          <div class="social-icon">
            <a href="https://www.instagram.com/aliwell.smiles" target="_blank">
              <i class="fa fa-instagram"></i>
            </a>
          </div>
          <div class="social-icon">
            <a href="https://www.youtube.com/@aliwell.smiles" target="_blank">
              <i class="fa fa-youtube-play"></i>
            </a>
          </div>
          <div class="social-icon">
            <a href="https://www.linkedin.com/company/aliwell" target="_blank">
              <i class="fa fa-linkedin-square"></i>
            </a>
          </div>
        </div>
      </div>
      <!-- SITEMAP -->
      <div *ngIf="hasSitemap" class="col-md-6 d-flex flex-column justify-content-center align-items-center align-items-md-start">
        <h2>Sitemap</h2>
        <div class="row row-cols-1 row-cols-md-2 w-100">
          <a class="sidenav-link col text-reset p-2 text-center text-md-start" [routerLink]="[item.url]" *ngFor="let item of sitemapUrls">{{item.name}}</a>
        </div>
      </div>
      <!-- CONTACT -->
      <div class="d-flex flex-column gap-3" [ngClass]="hasSitemap ? 'col-md' : 'col-md-4'">
        <div class="contact-item row" *ngFor="let contactItem of contactData">
          <i class="{{contactItem.iconClass}} fs-lg col-2"></i>
          <p class="col-10 text-start">{{contactItem.content}}</p>
        </div>
      </div>
    </div>
  </div>
  <!-- Copyright -->
  <div class="copyright d-flex flex-row justify-content-center align-items-center gap-6 text-center p-2 m-0 fw-light fs-md">
    <p class="text-end text-white">Aliwell<sup>&#174;</sup> {{todayDate | date:'YYYY' }}</p>
    <p class="text-start text-white">
      <a class="text-reset" [routerLink]="['avisos-privacidad']">Avisos de privacidad</a>
    </p>
  </div>
</footer>
