import { Directive, ElementRef } from '@angular/core';
import { CommonRegex } from '../_appCommon/base/Constants';
import { baseInputValidatorDirective } from './baseInputValidatorDirective';

@Directive({
  selector: '[phoneNumberOnly]'
})

export class PhoneNumberDirective extends baseInputValidatorDirective
{

  constructor(el: ElementRef)
  {
    super();
    this._el = el;

    // Only allows phone numbers
    this._regex = CommonRegex.PhoneNumber;
  }

}
