<div class="background-login">
  <div class="container-wrapper">
    <div class="px-5 py-4 h-100 w-100 d-flex flex-column">
      <div class="mx-auto my-4 fs-xl">
        <img src="../../../assets/logo.png" alt="Logo Aliwell" height="50px">
      </div>
      <div class="text-center mt-2">
        <h2 class="mt-2">{{!showForgotMyPassword ? 'Iniciar sesión' : '¡Ingresa el correo para ayudarte!'}}</h2>
      </div>
  
      <!-- LOGIN -->
      <div *ngIf="!showForgotMyPassword" class="flex-grow-1">
        <form #loginForm="ngForm" class="d-flex flex-column justify-content-around align-items-center h-100">
          <div class="w-100">
            <div *ngIf="!isUserValid; else checkPassword">
              <mat-form-field class="w-100" appearance="fill">
                <span style="color: #a2a5ae" matPrefix class="fa fa-envelope-o mr-2">&nbsp;</span>
                <input matInput placeholder="Correo electrónico" name="email" id="email" required [(ngModel)]="userAccessLogin.email" email>
                <mat-error *ngIf="loginForm.form.hasError('email', ['email'])">Ingresa un correo electrónico válido</mat-error>
                <mat-error *ngIf="loginForm.form.hasError('required', ['email'])">Completa este campo</mat-error>
              </mat-form-field>
              <div class="w-100 text-center mt-3">
                <button class="button primary-button w-75 mx-auto" *ngIf="!loading" (click)="loginForm.form.controls['email'].valid && onHandleCheckUserValid()" [disabled]="!userAccessLogin.email">Siguiente</button>
                <div class="mx-auto" *ngIf="loading" style="width: fit-content;">
                  <app-loading></app-loading>
                </div>
                <p class="mt-4">¿Aún no tienes cuenta? <a (click)="onHandleClickSignUp()" style="cursor: pointer;">Reg&iacute;strate</a></p>
              </div>
              <hr>
              <div class="mt-2 rounded mx-auto text-white p-2 my-2 text-white badge-wo-hover text-wrap text-center" style="background-color:#0D565E"> 
                  <div>Bienvenido a nuestra nueva plataforma.</div>
                <div>Si es tu primera vez iniciando sesión en el nuevo sistema, es necesario que restablezcas tu contraseña.</div>
              </div>
            </div>
            <ng-template #checkPassword>
              <mat-form-field class="w-100" appearance="fill">
                <span style="color: #a2a5ae" matPrefix class="fa fa-lock mr-2">&nbsp;</span>
                <input matInput  [type]="isPasswordHide ? 'password' : 'text'" placeholder="Contraseña" name="password" id="password" required [(ngModel)]="userAccessLogin.password">
                <button type="button" style="color: #0D565E;" mat-icon-button matSuffix (click)="isPasswordHide = !isPasswordHide" [attr.aria-label]="'Hide password'" >
                  <mat-icon>{{isPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
                </button>
                <mat-error *ngIf="loginForm.form.hasError('required', ['password'])">Ingresa tu contraseña</mat-error>
              </mat-form-field>
              <div class="text-end mt-3">
                <a (click)="showForgotMyPassword=true" style="cursor: pointer;">¿Olvidaste tu contraseña?</a>
              </div>
              <div class="w-100 text-center mt-3">
                <button (click)="loginForm.form.valid && login()" (keydown.enter)="loginForm.form.valid && login()" *ngIf="!loading" class="button primary-button w-75 mx-auto">Iniciar sesi&oacute;n</button>
                <div class="mx-auto" *ngIf="loading" style="width: fit-content;">
                  <app-loading></app-loading>
                </div>
              </div>
            </ng-template>
          </div>
        </form>
      </div>
  
      <!-- FORGOT MY PASSWORD -->
      <div *ngIf="showForgotMyPassword" class="mt-4">
        <form #forgotPasswordForm="ngForm">
          <div class="w-100">
            <mat-form-field class="w-100">
              <span style="color: #a2a5ae" matPrefix class="fa fa-envelope-o mr-2">&nbsp;</span>
              <input matInput placeholder="Correo electrónico" name="email" id="email" required email [(ngModel)]="inputEmailForgotPassword">
              <mat-error *ngIf="forgotPasswordForm.form.hasError('email', ['email'])">Ingresa un correo valido</mat-error>
              <mat-error *ngIf="forgotPasswordForm.form.hasError('required', ['email'])">Completa este campo</mat-error>
            </mat-form-field>
  
            <div class="text-end mt-3">
  
            </div>
            <div class="w-100 text-center">
              <button class="button primary-button w-75 mx-auto" *ngIf="!loading" (click)="forgotPasswordForm.valid && forgotPassword()">Enviar</button>
              <div class="mx-auto" *ngIf="loading" style="width: fit-content;">
                <app-loading></app-loading>
              </div>
              <p class="mt-4">
                ¿Ya tienes una cuenta?
                <a (click)="showForgotMyPassword=false" style="cursor: pointer;">
                  Inicia sesión
                </a>
              </p>
            </div>
          </div>
        </form>
      </div>
  
    </div>
  </div>

</div>
