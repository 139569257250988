import { Injectable } from '@angular/core';
import { Alert, AlertType } from './Alert';

@Injectable()
export abstract class ControllerBase
{
  public pageTitle: string = "";
  public alert: any;

  constructor()
  {
  }

  ngOnInit() { }

  public setSuccessAlert(message: string): void
  {
    this.alert = new Alert(AlertType.Success, message);
  }

  public setInfoAlert(message: string): void
  {
    this.alert = new Alert(AlertType.Info, message);
  }

  public setWarningAlert(message: string): void
  {
    this.alert = new Alert(AlertType.Warning, message);
  }

  public setDangerAlert(message: string): void
  {
    this.alert = new Alert(AlertType.Danger, message);
  }

  /**
   * If clearAlert is false - the alert is NOT cleared
   */
  public clearAlert(clearAlert: boolean = true): void
  {
    if (clearAlert)
    {
      this.alert = null;
    }
  }

  public createErrorMessage(failures: any): String
  {
    var message = "";

    var array = Object.keys(failures);

    array.forEach(function (key, index)
    {
      message += ", " + key + ":";
      failures[key].forEach(element =>
      {
        message += ' ' + element;
      });
    });

    return message;
  }
}
